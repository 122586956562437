import axios from 'axios'
import fileDownload from 'react-file-download'
import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { Admin } from '../../../domain/models/admin'
import { CustomerResponseModel } from '../../../domain/models/customer'
import { FilterModel } from '../../../domain/models/enum'
import { ServiceProviderProps, ServiceProviderResponseModel } from '../../../domain/models/service-provider'
import { TaskChart } from '../../../domain/models/task'
import Authentication from '../../../infra/cognito/authentication'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteAdmin implements Admin {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
    private readonly baseUrl: string | undefined,
  ) { }

  async loadCustomers(params: FilterModel): Promise<CustomerResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customers-list`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadServiceProvidersBranchs(): Promise<ServiceProviderProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/service-provider/branch-office`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadServiceProviders(params: FilterModel): Promise<ServiceProviderResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/service-providers-list`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async topFiveServiceProviders(params: FilterModel): Promise<ServiceProviderProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/top-five-service-providers`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async serviceProvidersCount(params: FilterModel):Promise<number> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/service-providers-count`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async customerCount(params: FilterModel):Promise<number> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customers-count`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async taskChartByServiceProvider(params: FilterModel):Promise<TaskChart> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/service-provider/task-chart`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async taskChartByCustomer(params: FilterModel):Promise<TaskChart> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customer/task-chart`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async exportCSVServiceProvider(params: FilterModel): Promise<void> {
    const token = await Authentication.getToken()
    const resp = await axios.get(`${this.baseUrl}${this.url}/service-providers-list/export`, {
      responseType: 'blob',
      params,
      headers: {
        Authorization: token,
        accept: '*/*',
      },

    })
    fileDownload(resp.data, `Prestadores de serviço - ${new Date().getTime()}.xlsx`)
  }

  async exportCSVCustomer(params: FilterModel): Promise<void> {
    const token = await Authentication.getToken()
    const resp = await axios.get(`${this.baseUrl}${this.url}/customer-list/export`, {
      responseType: 'blob',
      params,
      headers: {
        Authorization: token,
        accept: '*/*',
      },

    })
    fileDownload(resp.data, `Clientes - ${new Date().getTime()}.xlsx`)
  }
}
