import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../contexts/alert-box/alert_box'
import { ProductReponseModel } from '../../../../domain/models/product'
import { StockProps } from '../../../../domain/models/stock'
// import { makeRemoteTask } from '../../../../main/factories/usecases/task/remote-task-factory'
import { DialogModal } from '../../../components/dialog-modal'
import FormFactory from '../../../components/form-factory'
import Icons from '../../../components/icons-helper'
import { theme } from '../../../styles/theme'
import { makeFields } from './fields'
import {
  ActionBox,
  ActionButton,
  BackButton,
  ContainerData,
  ContainerForm,
  PhotoFullStyled,
  TitleContainer, TitleStyled, TopContainer, Wrapper,
} from './styles'

type PageProps = {
  data: StockProps.Model | undefined
  handleSubmit: (values: any, actions: any) => void
  listProducts: ProductReponseModel | undefined
}

const StockInternalPage = ({
  data, handleSubmit, listProducts,
}:PageProps):JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [show, setShow] = useState<string | null>()
  const { handleAlert } = useAlertBox()
  const history = useHistory()
  useEffect(() => {
    if (data?.id) {
      setEditMode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>
          {data?.product?.name || 'Novo item para operador'}
        </TitleStyled>
      </TitleContainer>
      <ContainerForm>
        <TopContainer>
          <BackButton to="/stock">
            <Icons
              name="arrow-left"
              width={12}
              height={12}
              color={theme.colors.black}
            />
            Voltar
          </BackButton>
          {data?.id && (
          <>
            <ActionBox>
              <ActionButton onClick={() => setEditMode((prev) => !prev)}>
                <Icons
                  name={!editMode ? 'close' : 'substract'}
                  width={12}
                  height={12}
                  color={theme.colors.primary}
                />
              </ActionButton>
              {/* <ActionButton onClick={() => setOpen(true)}>
                <Icons
                  name="trash"
                  width={12}
                  height={12}
                  color={theme.colors.red}
                />
              </ActionButton> */}
            </ActionBox>
          </>
          )}
        </TopContainer>

        <ContainerData>
          <FormFactory
            className={`internal-form mb-3 ${editMode && 'editMode'}`}
            groups={makeFields(data, editMode, listProducts)}
            button={{ submit: 'Salvar', submitting: 'Salvando...' }}
            onSubmit={async (values:any, actions:any) => {
              await handleSubmit(values, actions)
              setEditMode(false)
            }}
          />

        </ContainerData>
        {data?.id && (
        <DialogModal
          title="Excluir Tarefa"
          message="Tem certeza que deseja excluir essa Tarefa?"
          buttons={{
            confirm: 'Continuar',
            cancel: 'Cancelar',
          }}
          open={open}
          cancelCallback={() => setOpen(false)}
          confirmCallback={async () => {
            try {
              // await makeRemoteTask().delete(data?.id)
              setOpen(false)
              history.push('/tasks')
            } catch (error) {
              console.error(error)
              handleAlert({
                show: true,
                type: 'danger',
                message: 'Essa Tarefa não pode ser excluída!',
              })
            }
          }}
        />
        )}
        <Modal show={!!show} onHide={() => setShow(null)}>

          <Modal.Header closeButton />
          <Modal.Body>
            <PhotoFullStyled src={show || ''} />
          </Modal.Body>

        </Modal>
      </ContainerForm>
    </Wrapper>
  )
}
export default StockInternalPage
