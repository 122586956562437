import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  .form-filter .select-input {
    margin: 0;
  }
  .form-filter {
    width: 100%;
  }
`

type StatusProp = {
  color: string
}
export const TitleContainer = styled.div`
  display: flex;
  /* margin-top:30px; */
  margin-bottom: 15px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  padding-right: 15px;
  a{
    background-color: ${theme.colors.lightBlue};
    color: ${theme.colors.primary};
  }
`
export const CardBar = styled.div`
  box-shadow: 0px 0px 25px rgba(171, 171, 171, 0.15);
  margin-top: 10px;
  margin: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  /* padding: 15px; */
  padding-top: 5px;
  justify-content: space-between;
  .container-form{
    margin-left: 0px;
  }
`
export const ContainerButton = styled.div`

`
export const FilterStyled = styled.div`
  position: relative;
  width: 100%;
  min-width:250px;
  padding: 0px 15px 15px 15px;
  display: flex;
  flex-direction: column;
`
export const FieldCheckbox = styled.label`
  font-weight: 400;
  color:${theme.colors.black};
  font-size:.8rem;
  font-family: 'poppins';
  display: flex;
  align-items: center;
  margin-bottom: 5px;
`

export const CheckboxStyled = styled.input`
  font-weight: 400;
  color:${theme.colors.black};
  font-size:1.5rem;
  font-family: 'poppins';
  margin-right: 8px;
  accent-color: #01ac1c;
`
export const ButtonStyled = styled.button`
  margin: 5px 0;
  height: 1px;
  width: 100%;
  background-color: ${theme.colors.lightGray};
  padding: 20px;
  width: 100%;
  background-color: ${theme.colors.primary};
  color: ${theme.colors.white};
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
  margin-top: 30px;
`
export const SperatorStyled = styled.span`
  margin: 5px 0;
  height: 1px;
  width: 100%;
  padding: 0 5px;
  background-color: ${theme.colors.lightGray};
`
export const TitleStyledFilter = styled.h5`
  display: flex;
  font-weight: 400;
  color:${theme.colors.black};
  font-size:.8rem;
  font-family: 'poppins';
  margin-bottom: 10px;
  margin-top: 10px;
`
export const DateContainer = styled.div`
  display: flex;
`
export const DateStyled = styled.input`
  margin-right: 10px;
  border: 1px solid ${theme.colors.middleGray};
  color: ${theme.colors.black};
  max-width: 100px;
  border-radius: 6px;
  padding: 10px 5px;
  font-size:.8rem;
  font-family: 'poppins';
`
type StatusProps = {
  color: string
}

export const StatusProduct = styled.div`
  list-style: none;
  color: ${(el: StatusProps) => el.color};
  margin-left: 15px;
  position: relative;
  &:before {
    content:'';
    width: 5px;
    height: 5px;
    background-color: ${(el: StatusProps) => el.color};
    position: absolute;
    left: -10px;
    border-radius: 50%;
    top: 5px;
  }
`
export const LabelStyled = styled.label`
  display: flex;
  flex-direction: column;
  font-size:.7rem;
  font-family: 'poppins';
  margin-bottom: 5px;
  font-weight: 400;
  color: ${theme.colors.middleGray};
`
export const TitleStyled = styled.h1`
  color: ${theme.colors.black};
  font-size: 1.5rem;
  padding: 0 15px;
  font-weight: 500;
`
export const StatusStyled = styled.li`
  color: ${(p: StatusProp) => p.color};
`
export const RatingStyled = styled.div`
  width: 100%;
  display: flex;
  p{
    margin-left: 5px;
    margin-bottom:0;
  }
  align-items: center;
`

export const RowStyled = styled.div`
  width: 100%;
  display: flex;
`
export const RowTitleStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 15px;
`
export const SplitStyled = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  height: calc(50% - 10px);
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;

`
type ColProp = {
  width: number
}

export const ColStyled = styled.div`
  margin: 10px;
  padding: 0;
  width: ${(p: ColProp) => p.width}%;
`

export const Title = styled.h1`
  margin: 10px;
  padding: 0;
  font-family: 'palanquin';
  font-weight: 500;
  font-size: 2rem;
`
export const ButtonComponentStyled = styled(Link)`
  background-color: ${theme.colors.green};
  color: ${theme.colors.white};
  align-items: center;
  padding: 15px 45px;
  border-radius: 100px;
  font-size: .8rem;
  line-height: 1.6;
  &:hover{
    color: ${theme.colors.white};
    opacity: .7;
  }
  svg {
    margin-bottom: 2px;
    margin-right: 5px;
  }

`

export const CardContentStyled = styled.div`
  font-family: 'poppins';
  border-radius: 20px;
  width: 100%;
  padding: 10px;
  border: 1px solid #EFEFEF;
  display: flex;
  align-items: center;
`
export const TitleCardContainer = styled.div`
  padding: 10px 15px;
  width: 100%;
`
export const TitleCardStyled = styled.h5`
  font-family: 'palanquin';
  color: ${theme.colors.black};
  font-size: .9rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
  overflow: hidden;
`
export const TextCardStyled = styled.span`
  color: ${theme.colors.darkGray};
  font-weight: 300;
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  align-items: flex-start;
  font-size: 0.7rem;
  svg{
    margin-right: 10px;
  }
  width: 100%;
`
export const PhotoStyled = styled.img`
 width: 100%;
 max-width: 80px;
 object-fit: contain;
 height: 100%;
 border-radius: 15px;
 aspect-ratio: 3/4;
`

export const ProgressBarStyled = styled.progress`
  height: 30px;
  width: 85%;
  margin-right: 5px;
`
export const BoxCardStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  width: 75%;
`
export const BoxCardInfoStyled = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 15px;
  flex-direction: row;
  border-radius: 15px;
  width: 100%;
`
