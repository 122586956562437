import { ProductReponseModel } from '../../../../domain/models/product'
import { StockProps } from '../../../../domain/models/stock'

export const makeFields = (
  values: StockProps.Model | undefined,
  editMode: boolean,
  listProducts: ProductReponseModel | undefined,
): any[] => [
  {
    groupName: 'Dados da tarefa',
    groupLabel: 'Dados do item',
    items: [
      {
        name: 'product',
        type: 'select',
        label: 'Produto',
        disabled: editMode,
        defaultValue: values?.product?.id,
        placeholder: 'Selecione o Produto',
        validations: 'objectRequired',
        options: listProducts?.content || [],
        labelKey: 'name',
        valueKey: 'id',
        col: {
          md: 6,
          lg: 6,
        },
      },
      {
        name: 'quantityUnit',
        type: 'select',
        label: 'Unidade',
        disabled: editMode,
        defaultValue: values?.quantityUnit,
        placeholder: 'Unidade',
        validations: 'objectRequired',
        options: [{ label: 'Litros', value: 'LITER' }, { label: 'Unidades', value: 'UNIT' }, { label: 'Caixa', value: 'BOX' }],
        labelKey: 'label',
        valueKey: 'value',
        col: {
          md: 2,
          lg: 2,
        },
      },
      {
        name: 'quantity',
        type: 'number',
        label: 'Quantidade',
        disabled: editMode,
        defaultValue: values?.quantity,
        placeholder: 'Quantidade',
        mask: '(00) 0 0000-0000',
        col: {
          md: 2,
          lg: 2,
        },
      },

    ],
  },
]
