import React from 'react'
import {
  Card, Content, ImageStyled, Title,
} from './styles'
import image from '../../../assets/images/new-task.png'

const TasksAdminImage = ():JSX.Element => (
  <Card>
    <Content>
      <ImageStyled src={image} />
      <Title>
        Acompanhe os Prestadores de serviço e os Clientes com facilidade
      </Title>
    </Content>
  </Card>
)
export default TasksAdminImage
