import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const MenuBar = styled.div`
  padding: 0;
  align-items: center;
  display: flex;
`
export const Wrapper = styled.div`
  height: 80px;
  border-bottom: 1px solid #EFEFEF;
  padding: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`
export const ItemMenu = styled.button`
  padding: 15px 20px;
  text-decoration: none;
  position: relative;
  background-color: #fff;
  svg {
    margin-right: 10px;
  }
`
export const TitleMenu = styled.span`
  color: ${theme.colors.black};
  font-family: 'palanquin';
  font-weight: ${100};

`
export const ContainerMenu = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.colors.white};
`
export const ContextMenu = styled.div`
  display:flex;
  padding:0px 7px;
  justify-content: center;

`
export const SelectStyled = styled.div`
  display:flex;
  padding: 7px;
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  min-width: 180px;
`
export const NameStyled = styled.div`
  color: ${theme.colors.darkGray};
  font-size: .7rem;
  font-weight: 300;
`
export const SettingsButton = styled(Link)`
  background-color: ${theme.colors.lightGray};
  border-radius: 50%;
  margin-left: 15px;
  align-items: center;
  display: flex;
  padding: 10px;
  cursor: pointer;
`
export const LocalStyled = styled.div`

  text-align: start;
  justify-content: flex-start;
  color: ${theme.colors.primary};
  font-weight: 600;
  svg {
    margin-left: 10px;
    margin-bottom: 2px;
  }
`
export const ContextButton = styled.div`
  display:flex;
  background-color: ${theme.colors.lightBlue};
  align-items: center;
  padding-right: 15px;
  border-radius: 60px;
  padding: 0px 5px;
  position: relative;
`
export const SearchStyled = styled.input`
  border: none;
  font-size: .9rem;
  width: 80%;
  margin-left: 15px;
`
export const SearchBoxStyled = styled.div`
 border-bottom:1px solid #EFEFEF;
 padding: 15px 15px;
 width:100%;
`
export const ChangeMatrizBoxStyled = styled.div`
 border-top: 1px solid #EFEFEF;
 width: 100%;
 display: flex;
 background-color: #fff;
 padding: 15px;
`
export const MatrizButton = styled.button`
  text-decoration: none;
  color: ${theme.colors.primary};
  background-color: #fff;
`
export const Subtitle = styled.div`
  font-weight: 600;
  color: ${theme.colors.black};
  margin-bottom: 10px;
`
export const UlBranchs = styled.ul`
  display:flex;
  flex-direction: column;
  color: ${theme.colors.black};
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: .8rem;
  line-height: 24px;
  padding-left: 30px;
  margin: 0;
  max-height: 130px;
  overflow-y: auto;
  /* width */
::-webkit-scrollbar {
  width: 5px;
  border-radius: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
  border-radius: 5px;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 5px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
  border-radius: 5px;
}
`
type RadioProps ={
  selected:boolean
}

export const LiBranchs = styled.li`
  display:flex;
  padding: 5px 0px;
  position: relative;
  align-items: center;
  cursor: pointer;
  &:before{
    content:'';
    width: 7px;
    height: 7px;
    background-color: ${(p:RadioProps) => (p.selected ? '#195C8C' : '#fff')} ;
    border-radius: 50%;
    position: absolute;
    left: -20px;
  }
  &:after{
    content:'';
    width: 15px;
    height: 15px;
    border: 1px solid #195C8C;
    border-radius: 50%;
    position: absolute;
    left: -24px;
  }
`
export const Listbranches = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  padding: 15px;
`
type DropDownProps ={
  open: boolean
}

export const DropDownStyled = styled.div`
  display:flex;
  align-items: center;
  border-radius: 16px;
  box-shadow: 0px 7px 50px rgb(210 214 227 / 40%);
  padding:0px 0px;
  position: absolute;
  background-color: #fff;
  max-width: 312px;
  width: 100%;
  top: calc(100% + 15px);
  right: 0;
  flex-direction: column;
  opacity: ${(p:DropDownProps) => (p.open ? 1 : 0)};
  visibility: ${(p:DropDownProps) => (p.open ? 'visible' : 'hidden')};
  transition: ease all 400ms;
  border: 0.5px solid #EFEFEF;
  z-index: 9;

  &:before{
    content:'';
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 17px solid #ffffff;
    width: 0;
    height: 0;
    position: absolute;
    top: -17px;
    right: 25px;
    /* border-radius: 26px; */
    box-shadow: 0px 7px 50px 0px rgb(210 214 227 / 40%);
  }
`
