/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { FilterModel } from '../../../../domain/models/enum'
import { ServiceProviderResponseModel } from '../../../../domain/models/service-provider'
import DataGrid from '../../../components/datagrid'
import FilterBar from '../../../components/filter-bar'
import { ExportButtonComponentStyled } from '../../task/task-list/styles'
import {
  ButtonStyled,
  CheckboxStyled,
  ContainerButton,
  FieldCheckbox,
  FilterStyled,
  SperatorStyled,
  TitleContainer,
  TitleStyled, TitleStyledFilter, Wrapper,
} from '../list/styles'
import { listFields } from './fields'

type PageProps = {
  list: ServiceProviderResponseModel | undefined
  setParams: any
  params: any
  loading?:boolean
  exportList?: ()=> Promise<void>
}

type FilterComponentProps = {
  submit:(value:string)=>void
  params: any
}

const FilterComponent = ({ submit, params }:FilterComponentProps):JSX.Element => {
  const [values, setValues] = useState<any>({ ...params })

  const handleChange = (e:any):void => {
    setValues(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }

  const applyFilter = (): void => {
    submit(values)
  }

  return (
    <FilterStyled>
      <SperatorStyled />
      <TitleStyledFilter>
        Status
      </TitleStyledFilter>
      <FieldCheckbox>
        <CheckboxStyled
          name="enabled=false"
          value="false"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Inativo
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="true"
          name="enabled=true"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Ativo
      </FieldCheckbox>
      <ButtonStyled onClick={() => applyFilter()}>Aplicar</ButtonStyled>
    </FilterStyled>
  )
}
const ServiceProviderBranchsListPage = ({
  list, setParams, params, loading, exportList,
}:PageProps):JSX.Element => {
  const handlePage = (e:any):void => {
    setParams((prev: FilterModel) => ({
      ...prev,
      page: e,
    }))
  }

  const handleSearch = (callback:any):void => {
    const val = callback()

    setParams(
      (prev: any) => ({
        ...prev,
        filters: { ...prev?.filters, name: val.query },
      }
      ),
    )
  }

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>Filiais</TitleStyled>
      </TitleContainer>
      <FilterBar
        handleFilter={handleSearch}
        filterComponent={(
          <FilterComponent
            params={params}
            submit={(values: any) => setParams((prev: any) => ({
              ...prev,
              filters: { ...prev?.filters, ...values },
            }))}
          />
)}
      >
        <ContainerButton>
          <ExportButtonComponentStyled onClick={async () => {
            if (exportList) {
              await exportList()
            }
          }}
          >
            Exportar
          </ExportButtonComponentStyled>
        </ContainerButton>
      </FilterBar>
      <DataGrid
        loading={!!loading}
        fields={listFields}
        data={list?.content || []}
        onClickRow={(e) => console.log(e)}
        totalElements={list?.totalElements || 0}
        currentPage={list?.number || 0}
        totalPages={list?.totalPages || 0}
        changePage={handlePage}
      />

    </Wrapper>
  )
}
export default ServiceProviderBranchsListPage
