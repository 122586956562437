import React, { useEffect, useState } from 'react'
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import {
  BoxProgress,
  Card, Content, InfoProgress, LinkStyled, Title,
} from './styles'
import 'react-circular-progressbar/dist/styles.css'
import { makeRemoteTask } from '../../../../main/factories/usecases/task/remote-task-factory'
import { BranchGoalsReponseModel } from '../../../../domain/models/task'

type CardProps = {
  period: string
  isHeadOffice: boolean
  serviceProvider?: string | undefined
  customer?: string | undefined
}

const TaskCard = ({
  period, isHeadOffice, serviceProvider, customer,
}:CardProps):JSX.Element => {
  const [data, setData] = useState<BranchGoalsReponseModel>({ partial: 0, percentGoal: 0 })

  useEffect(() => {
    if (customer || serviceProvider) {
      (async () => {
        const response = await makeRemoteTask().loadBranchGoals({
          period,
          isHeadOffice,
          serviceProvider,
          customer,
        })
        setData(response)
      })()
    }
  }, [period, isHeadOffice, customer, serviceProvider])

  return (
    <Card>
      <Content>
        <Title>
          Tarefas realizadas x Metas
          <LinkStyled to="/tasks">
            Ver tudo
            <Icons name="arrow-right" width={13} height={13} color={theme.colors.primary} />
          </LinkStyled>
        </Title>
        <BoxProgress>
          <InfoProgress>
            {data.partial}
            <p>
              {data.percentGoal}
              % da meta
            </p>
          </InfoProgress>
          <CircularProgressbar
            value={data.percentGoal}
            strokeWidth={10}
            // maxdata={100}
            text={`${data.percentGoal}%`}
            styles={buildStyles({
              textSize: '16px',
              pathTransitionDuration: 0.5,
              // Colors
              pathColor: 'rgba(2, 195, 33, 0.66)',
              textColor: theme.colors.black,
            // trailColor: '#d6d6d6',
            // backgroundColor: '#d24a2b',
            })}
          />
        </BoxProgress>
      </Content>
    </Card>
  )
}
export default TaskCard
