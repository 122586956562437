import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/auth-context/auth-context'
import CustomerCount from '../../components/dashboards/customer-count'
import ServiceProvidersCount from '../../components/dashboards/service-providers-count'
import TaskByCustomer from '../../components/dashboards/task-by-customer'
import TaskByServiceProvider from '../../components/dashboards/task-by-service-provider'
import TasksAdminImage from '../../components/dashboards/tasks-admin-image'
import TopRatingsServiceProviders from '../../components/dashboards/top-ratings-service-providers'
import Icons from '../../components/icons-helper'
import PeriodFilter from '../../components/period-filter'
import { theme } from '../../styles/theme'
import {
  BackButton,
  ColStyled, ContainerModal, ContinueButton, RowStyled, RowTitleStyled, SplitStyled, Title, Wrapper,
} from './styles'

const AdminDashboardPage = ():JSX.Element => {
  // const [show, setShow] = useState<boolean>(true)
  const [period, setPeriod] = useState<string>('month')
  const {
    profile, setBranch, setIsheadeOffice,
  } = useAuth()
  const history = useHistory()

  const show = !!profile?.branch?.openModal

  return (
    <Wrapper>
      <RowTitleStyled>
        <Row className="w-100">
          <Col xs={3}>
            <Title>
              Dashboard
            </Title>
          </Col>
        </Row>

        <PeriodFilter callback={(callback:string) => setPeriod(callback)} />
      </RowTitleStyled>
      <RowStyled>
        <ColStyled width={54}>
          <TaskByServiceProvider period={period} />
        </ColStyled>
        <ColStyled width={18}>
          <SplitStyled>
            <ServiceProvidersCount
              period={period}
            />
          </SplitStyled>
          <SplitStyled>
            <CustomerCount
              period={period}
            />
          </SplitStyled>
        </ColStyled>
        <ColStyled width={25}>
          <TopRatingsServiceProviders
            period={period}
          />
        </ColStyled>

      </RowStyled>
      <RowStyled>
        <ColStyled width={55}>
          <TaskByCustomer period={period} />
        </ColStyled>
        <ColStyled width={45}>
          <TasksAdminImage />
        </ColStyled>
      </RowStyled>
      <Modal
        show={show}
        onHide={async () => {
          await setBranch({ ...profile?.branch, openModal: false })
        }}
        className="modal-branch"
      >
        <Modal.Body>
          <ContainerModal>
            <Icons
              name="caution"
              width={200}
              height={200}
              color={theme.colors.yellow}
            />
            <span>Você estará visualizando:</span>
            <h5>
              Filial
              {' '}
              {profile?.branch?.name}
            </h5>
            <ContinueButton onClick={async () => {
              await setBranch({ ...profile?.branch, openModal: false })
            }}
            >
              Prosseguir
            </ContinueButton>
            <BackButton onClick={async ():Promise<void> => {
              await setBranch(null)
              await setIsheadeOffice(true)
              history.goBack()
            }}
            >
              Voltar
            </BackButton>
          </ContainerModal>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}
export default AdminDashboardPage
