import React, { useState } from 'react'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import Authentication from '../../../../../infra/cognito/authentication'
import { t } from '../../../../../presentation/adapters/translate/translate'
import Signin from '../../../../../presentation/pages/auth/signin'

function MakeSignIn(): JSX.Element {
  const { handleAlert } = useAlertBox()
  const { reloadUser, setUserLocal } = useAuth()
  const [user, setUserTemp] = useState<any>()

  const handleSubmit = async (values: any, actions: any, setStep:any): Promise<void> => {
    try {
      if (user) {
        await Authentication.newPasswordRequired(user, values.password)
        const resLogin = await Authentication.login(
          user.challengeParam.userAttributes.email,
          values.password,
        )
        setUserLocal(resLogin)
        reloadUser()
      } else {
        const res: any = await Authentication.login(values.email, values.password)
        if (res.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setUserTemp(res)
          setStep(1)
        }
        reloadUser()
      }

      actions.setSubmitting(false)
    } catch (error: any) {
      console.error(error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error.code, error.message),
      })
      actions.setSubmitting(false)
    }
  }
  return <Signin handleSubmit={handleSubmit} />
}

export default MakeSignIn
