import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { EnumTypes, EnumTypesProps } from '../../../domain/models/enum'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteEnumsTypes implements EnumTypes {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
  ) { }

  async load(): Promise<EnumTypesProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
