/* eslint-disable max-len */
export const dictionary = {
  AlertSendCode: 'Enviamos um código de verificação no seu e-mail. Caso você não receba o e-mail em alguns minutos, verifique a sua caixa de spam ou repita o processo.',
  AlertSendCodeError: 'Ocorreu um erro ao enviar o código, por favor tente mais tarde',
  AlertUserNotVerify: 'É necessário confirmar este usuário antes de tentar recuperar a senha',
  PasswordUpdatedSuccess: 'Nova senha cadastrada com sucesso',

  CodeMismatchException: 'Código de verificação inválido, tente novamente',
  NotAuthorizedException: 'E-mail e/ou senha incorretos.',
  NotAuthorizedExceptionTempPasswordExpired: 'A senha temporária expirou e deve ser redefinida por um administrador',
  UsernameExistsException: 'Já existe uma conta com o email informado',
  UserNotConfirmedException: 'Usuário não confirmado',
  UserNotFoundException: 'E-mail e/ou senha incorretos',
  ExpiredCodeException: 'Código inválido/expirado',
  LimitExceededException: 'Limite de tentativas excedidas, tente novamente mais tarde',
  DefaultException: 'Inconsistência nos dados enviados, tente novamente mais tarde',
  UserSuccessfullyVerified: 'Usuário verificado com sucesso',
  NewPasswordRequired: 'Este é seu primeiro acesso, crie uma nova senha para acessar a plataforma',
  ResendSignUpSuccess: 'Código enviado com sucesso',
  ResendSignUpFail: 'Erro ao enviar código',

  ValidateInvalidEmail: 'Email inválido',
  ValidateRequiredField: 'Campo obrigatório',
  ValidateMaxPassword: 'Máximo de 24 caracteres',
  ValidateMinPassword: 'Mínimo de 6 caracteres',
  ValidateMinName: 'Mínimo de 2 caracteres',
  ValidateOneLetter: 'Pelo menos uma letra',
  ValidateOneLowerChar: 'Pelo menos uma letra minúscula',
  ValidateOneUpperChar: 'Pelo menos uma letra maiúscula',
  ValidateOneNumber: 'Pelo menos um número',
  ValidateOneSpecialChar: 'Pelo menos um caracter especial',
  ValidatePasswordNotEquals: 'As senhas não são iguais',
  ValidateOnlyNumbers: 'Somente números',
  ValidateInvalidCPF: 'CPF inválido',
  ValidateInvalidCardNumber: 'Cartão inválido',
  ValidateInvalidDateExp: 'Data de expiração inválida',

  ERROR_ACCESS_DENIED: 'Acesso negado',
  ERROR_INVALID_CREDENTIALS: 'Credenciais inválidas',
  ERROR_UNEXPECTED: 'Algo de errado aconteceu.',
}
