import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/auth-context/auth-context'
import { TaskModelResponse, TaskReponseModel } from '../../../../domain/models/task'
import { makeRemoteTask } from '../../../../main/factories/usecases/task/remote-task-factory'
import { getColorByStatus, parseStatus } from '../../../helpers/utils'
import { StatusStyled } from '../../../pages/task/task-list/styles'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import { LinkStyled } from '../tasks/styles'
import {
  BoxOperator,
  Card, Content, ListStyled, Name, Title,
} from './styles'

type ComponentProps = {
  period: string
  customer: string
}

const TaskListCard = ({
  period, customer,
}:ComponentProps):JSX.Element => {
  const [data, setData] = useState <TaskReponseModel>()
  const { user, profile } = useAuth()

  useEffect(() => {
    (async () => {
      const response = await makeRemoteTask().loadCustomerDashboard({
        period,
        customer: customer || profile?.branch?.uuid || user?.uuid,
      })
      setData(response)
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, user, profile, customer, period])

  return (
    <Card>
      <Content>
        <Title>
          <div>
            <Icons name="books" width={18} height={18} color={theme.colors.middleGray} />
            Tarefas
          </div>
          <LinkStyled to="/tasks">
            Ver tudo
            <Icons name="arrow-right" width={13} height={13} color={theme.colors.primary} />
          </LinkStyled>
        </Title>
        <ListStyled>
          {data?.content?.map((el: TaskModelResponse, i) => (
            <BoxOperator to={`/tasks/${el?.id}`} key={`${el?.customer?.name}-${i}` || `${el?.licensePlate}-${i}` || ''} index={i}>
              <Name>
                {el?.vehicleType?.name}
              </Name>
              <Name>
                {el?.licensePlate}
              </Name>
              <Name>
                {el?.serviceProvider?.name}
              </Name>
              <StatusStyled color={getColorByStatus(el.status || 'IN_PROGREES')}>
                {parseStatus(el.status || 'IN_PROGREES')}
              </StatusStyled>

            </BoxOperator>
          ))}
        </ListStyled>

      </Content>
    </Card>
  )
}
export default TaskListCard
