import axios from 'axios'
import fileDownload from 'react-file-download'
import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import {
  DocRequestProp,
  DocumentRequest, Documents, DocumentsProps, DocumentsResponseModel,
} from '../../../domain/models/documents'
import { EnumTypesProps, FilterModel } from '../../../domain/models/enum'
import Authentication from '../../../infra/cognito/authentication'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteDocuments implements Documents {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
    private readonly baseUrl: string | undefined,
  ) { }

  async load(params: FilterModel): Promise<DocumentsResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async createRequest(body: DocumentRequest): Promise<DocumentRequest> {
    const httpResponse = await this.httpPostClient.request({
      url: '/document-requests/',
      method: 'post',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadById(id: number): Promise<DocumentsProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `/document-requests/${id}`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async completeRequest(id: number): Promise<DocumentsProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `/document-requests/complete/${id}`,
      method: 'post',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async downloadZip(id: number): Promise<void> {
    const token = await Authentication.getToken()
    const resp = await axios.get(`${this.baseUrl}/document-requests/zip-export/${id}`, {
      responseType: 'blob',
      headers: {
        Authorization: token,
        accept: '*/*',
      },

    })
    fileDownload(resp.data, 'documentos_solicitados.zip')
  }

  async sumary(params: FilterModel): Promise<DocumentsResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: '/document-requests/summary',
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      case HttpStatusCode.conflict: throw httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async customerList(params: FilterModel): Promise<DocumentsResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: '/document-requests/customer/summary',
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      case HttpStatusCode.conflict: throw httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async updateDocument(id: number, body: DocRequestProp): Promise<DocRequestProp> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      case HttpStatusCode.conflict: throw httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async loadAll(): Promise<EnumTypesProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/all`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
