// eslint-disable-next-line import/no-cycle
import { Roles } from '../../contexts/auth-context/auth-context'
import LocalStorage from '../../infra/localStorage'
import { theme } from '../styles/theme'

export const parseStatus = (st: string): string => {
  switch (st) {
    case 'COMPLETED':
      return 'Concluída'
    case 'IN_PROGRESS':
      return 'Em andamento'
    case 'TODO':
      return 'A fazer'

    default:
      return ''
  }
}
export const getColorByStatusEnabled = (st: boolean): string => {
  if (st) return theme.colors.green

  return theme.colors.red
}
export const getColorByStatus = (st: string): string => {
  switch (st) {
    case 'COMPLETED':
      return theme.colors.green
    case 'IN_PROGRESS':
      return theme.colors.red
    case 'TODO':
      return theme.colors.primary

    default:
      return ''
  }
}

export const unitMeasurement = (opt: string | undefined): string => {
  const values: any = {
    LITER: 'litros',
    UNIT: 'unidades',
  }
  return values[opt || ''] || ''
}
export const convertToFloat = (str: string): number => Number(str.toString()?.replaceAll('.', '').replace(',', '.'))

export const getLabelByQtd = (qtd: number): string => {
  if (qtd > 2) return 'Disponível'
  if (qtd === 0) return 'Acabou'
  if (qtd === 2) return 'Acabando'

  return ''
}
export const getColorByQtd = (qtd: number): string => {
  if (qtd > 2) return theme.colors.green
  if (qtd === 0) return theme.colors.red
  if (qtd === 2) return theme.colors.yellow

  return ''
}

export const checkIsCustomer = (): boolean => {
  let local = LocalStorage.get('user')
  if (!local?.roles?.length) {
    return false
  }
  local = local?.roles[0]?.authority

  const typeUser = local
  if (typeUser === Roles.CUSTOMER_BRANCH_OFFICE
    || (typeUser === Roles.CUSTOMER_HEAD_OFFICE)) { return true }
  return false
}
export const isAdmin = (): boolean => {
  try {
    const typeUser = LocalStorage.get('user')?.roles[0]?.authority
    if (typeUser === Roles.ADMIN) { return true }
    return false
  } catch (error) {
    window.location.href = '/login'
    return false
  }
}
export const checkNotHeadOffice = (role: string):boolean => {
  if (role === Roles.CUSTOMER_BRANCH_OFFICE || role === Roles.BRANCH_OFFICE) { return true }
  return false
}
