import React from 'react'
import { Auth } from 'aws-amplify'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import Authentication from '../../../../../infra/cognito/authentication'
import ChangePasswordPage from '../../../../../presentation/pages/settings/change-password'
import { t } from '../../../../../presentation/adapters/translate/translate'

function MakeChangePassword(): JSX.Element {
  // const history = useHistory()
  // const { setIsheadeOffice } = useAuth()
  const { handleAlert } = useAlertBox()
  const handleSubmit = async (values: any, actions: any): Promise<void> => {
    try {
      const user: any = await Auth.currentAuthenticatedUser()
      await Authentication.changePassword(user, values.currentPassword, values.password)
      handleAlert({
        show: true,
        type: 'success',
        message: 'Senha redefinida com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error:any) {
      console.error(error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error.message),
      })
      actions.setSubmitting(false)
    }
  }

  return <ChangePasswordPage handleSubmit={handleSubmit} />
}

export default MakeChangePassword
