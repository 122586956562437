import { Auth, DataStore } from 'aws-amplify'
import { EnumTypes } from '../../domain/models/enum'
import { UserProps } from '../../domain/models/user'
import LocalStorage from '../localStorage'

class Authentication {
  static login(username: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      Auth.signIn(username, password)
        .then(async (res) => {
          await LocalStorage.set('connected', true)
          await LocalStorage.set('username', username)
          resolve(res)
        })
        .catch((error) => {
          console.error('ERROR', error)
          reject(error)
        })
    })
  }

  static async setIsheadeOffice(isHeadOffice: boolean): Promise<void> {
    await LocalStorage.set('isHeadOffice', isHeadOffice)
  }

  static async setBranch(branch: EnumTypes | undefined): Promise<void> {
    await LocalStorage.set('branch', branch || undefined)
  }

  static async getProfileInfo(): Promise<{
    isHeadOffice: boolean,
    branch: EnumTypes | undefined
  } | null> {
    const isHeadOffice = await LocalStorage.get('isHeadOffice')
    const branch = await LocalStorage.get('branch')
    return isHeadOffice || branch ? { isHeadOffice, branch } : null
  }

  static async getUserInfo(): Promise<
     UserProps | null> {
    const userData = await LocalStorage.get('user')
    return userData
  }

  static isAuth(): Promise<boolean> {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(async (res) => {
          if (res.isValid()) {
            // eslint-disable-next-line no-console
            console.log('LOGADO:', res.getIdToken().getJwtToken())
            resolve(true)
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  static logout(): Promise<void> {
    return new Promise((resolve, reject) => {
      Auth.signOut()
        .then(async (res) => {
          await LocalStorage.remove('connected')
          await LocalStorage.remove('username')
          await LocalStorage.remove('isHeadOffice')
          await LocalStorage.remove('branch')
          await LocalStorage.remove('admin')
          await DataStore.clear()
          resolve(res)
        })
        .catch(async (error) => {
          await LocalStorage.remove('connected')
          await LocalStorage.remove('username')
          await LocalStorage.remove('isHeadOffice')
          await LocalStorage.remove('branch')
          await LocalStorage.remove('admin')
          reject(error)
        })
    })
  }

  static forgotPassword(email: string): Promise<void> {
    return new Promise((resolve, reject) => {
      Auth.forgotPassword(email)
        .then(async (res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  static changePassword(token: string, oldPassword: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.changePassword(token, oldPassword, password)
        .then(async (resp) => {
          resolve(resp)
        })
        .catch((error) => {
          console.error(error)
          reject(error)
        })
    })
  }

  static recoveryPassword(email: string, code: string, password: string): Promise<any> {
    return new Promise((resolve, reject) => {
      Auth.forgotPasswordSubmit(email, code, password)
        .then(async (res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  static newPasswordRequired(user: string, password: string): Promise<void> {
    return new Promise((resolve, reject) => {
      Auth.completeNewPassword(user, password)
        .then(async (res) => {
          resolve(res)
        })
        .catch((error) => {
          reject(error)
        })
    })
  }

  static getToken(): Promise<string> {
    return new Promise((resolve, reject) => {
      Auth.currentSession()
        .then(async (res) => {
          if (res.isValid()) {
            resolve(res.getIdToken().getJwtToken())
          }
        })
        .catch((error) => {
          reject(error)
        })
    })
  }
}

export default Authentication
