import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/auth-context/auth-context'
import { ServiceProviderProps } from '../../../../domain/models/service-provider'
import { TaskChart } from '../../../../domain/models/task'
import { makeRemoteAdmin } from '../../../../main/factories/usecases/admin/remote-admin-factory'
import { makeRemoteServiceProvider } from '../../../../main/factories/usecases/service-provider/remote-service-provider-factory'
import { theme } from '../../../styles/theme'
import FormFactory from '../../form-factory'
import Icons from '../../icons-helper'
import {
  Card, Content, ContentTitle, LabelMeta, LabelTask, SubtitleContainer, Title,
} from './styles'

declare global {
  interface Window {
    Chart?: any;
  }
}

type CardProps = {
  period: string
}

const TaskByServiceProvider = ({ period }: CardProps):JSX.Element => {
  const [chartData, setChartData] = useState<TaskChart>()
  const [serviceProvider, setServiceProvider] = useState<ServiceProviderProps>()
  const [serviceProviderList, setServiceProviderList] = useState<ServiceProviderProps[]>([])
  const [loading, setloading] = useState<boolean>()
  const { user } = useAuth()
  useEffect(() => {
    (async () => {
      const list = await makeRemoteServiceProvider().all()
      setServiceProviderList(list)
    })()
  }, [])

  useEffect(() => {
    if (user || serviceProvider) {
      (async () => {
        setloading(true)
        const dataChart = await makeRemoteAdmin().taskChartByServiceProvider({
          period,
          serviceProviderUUID: serviceProvider?.uuid,
        })

        const data = {
          labels: dataChart?.branchOffices,
          datasets: [
            {
              barThickness: 20,
              label: 'Fully Rounded',
              data: dataChart?.branchGoals,
              borderRadius: Number.MAX_VALUE,
              borderSkipped: false,
              backgroundColor: '#A2D6FF',
            },
            {
              barThickness: 20,
              label: 'Small Radius',
              borderRadius: Number.MAX_VALUE,
              data: dataChart?.totalTaskCompleted,
              backgroundColor: '#0172CB',
              borderSkipped: false,
            },
          ],
        }

        const config = {
          type: 'bar',
          data,
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                },
              },
            },
          },
        }

        setChartData(dataChart)
        setloading(false)
        const ctx = document?.getElementById('myChart')
        const myChart = new window.Chart(ctx, config)
        // window.Chart().update()
        console.warn(myChart)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProvider, period])

  const makeFields = ():any => ([{
    groupName: 'filter',
    // groupLabel: 'Selecione um Operador para ver o seu estoque',
    items: [
      {
        name: 'operator',
        type: 'select',
        // label: 'Selecione um Operador para ver o seu estoque',
        defaultValue: 'Washme-me',
        placeholder: 'Washme-me',
        validations: 'objectRequired',
        options: [{ name: 'Washme-me', uuid: null }, ...serviceProviderList],
        labelKey: 'name',
        onChange: (val: any) => { setServiceProvider(val) },
        valueKey: 'uuid',
        col: {
          md: 12,
          lg: 12,
        },
      }],
  }])
  return (
    <Card>
      <Content>
        <ContentTitle>
          <Title>
            Tarefas realizadas por prestador de serviço
          </Title>
          <div className="d-flex w-50">
            <FormFactory
              className="internal-form form-filter mt-2"
              groups={makeFields()}
              button={{ submit: 'Salvar', submitting: 'Salvando...', hide: true }}
              onSubmit={() => null}
            />
          </div>
        </ContentTitle>
        <SubtitleContainer>
          <p>{chartData?.totalPeriodTasks}</p>
          <span>
            <Icons height={5} width={5} name="arrow-up" color={theme.colors.green} />
            +
            {Number(chartData?.percentageToPreviousPeriod).toFixed(2)}
            %
          </span>
        </SubtitleContainer>
        {loading ? <Spinner animation="grow" style={{ margin: '0 auto' }} variant="primary" /> : <canvas id="myChart" />}
        <div className="d-flex justify-content-center mt-2">
          <LabelMeta>
            Meta
          </LabelMeta>
          <LabelTask>
            Tarefas realizadas
          </LabelTask>
        </div>
      </Content>
    </Card>
  )
}
export default TaskByServiceProvider
