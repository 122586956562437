import React, { useState } from 'react'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import Authentication from '../../../../../infra/cognito/authentication'
import { t } from '../../../../../presentation/adapters/translate/translate'
import ForgotPassword from '../../../../../presentation/pages/auth/forgot-password'

function MakeForgotPassword(): JSX.Element {
  const { handleAlert } = useAlertBox()
  const { reloadUser } = useAuth()
  const [email, setEmail] = useState('')

  const handleSubmit = async (values: any, actions: any, setStep: any): Promise<any> => {
    try {
      if (!values?.password) {
        setEmail(values?.email)
        await Authentication.forgotPassword(values.email)
        actions.setSubmitting(false)
        setStep(1)
      } else {
        await Authentication.recoveryPassword(email, values?.code, values?.password)
        await Authentication.login(email, values?.password)
        reloadUser()
        actions.setSubmitting(false)
      }
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        title: 'Algo de errado aconteceu!',
        message: t(error.code, error.message),
      })
      actions.setSubmitting(false)
    }
  }
  return <ForgotPassword handleSubmit={handleSubmit} />
}

export default MakeForgotPassword
