import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const Card = styled.div`
  box-shadow:  0px 7px 50px rgba(210, 214, 227, 0.4);
  background-color: ${theme.colors.white};
  border-radius: 24px;
  width: 100%;
  font-family: ${theme.font.poppins};
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 20px;
`

export const Title = styled.h2`
  color: ${theme.colors.black};
  font-family: ${theme.font.palanquin};
  font-weight: 600;
  font-size: .9rem;
  margin-top: 12px;
  margin-bottom: 5px;
`
export const BoxProgress = styled.div`
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
  width: 100%;
  position: relative;
  justify-content: center;
  margin-top: 15px;
`
export const InfoProgress = styled.span`
  display: flex;
  align-items: center;
  position: absolute;
  background-color: #fff;
  width: 70%;
  height: 70%;
  border-radius: 50%;
  flex-direction: column;
  justify-content: center;
  color: ${theme.colors.black};
  font-family: ${theme.font.palanquin};
  font-size: 1.8rem;
  font-weight: 700;
  p{
    color: ${theme.colors.middleGray};
  font-family: ${theme.font.poppins};
  font-size: .8rem;
  font-weight: 300;
  }

`
export const ValueStyled = styled.p`
  display: flex;
  align-items: center;
  margin: 0;
  margin-left: 10px;
  font-size: 12px;
`
export const LinkStyled = styled(Link)`
  display: flex;
  align-items: center;
  font-family: ${theme.font.poppins};
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  margin-top: 5px;
  svg {
    margin-top: 2px;
  }
`
