/* eslint-disable no-nested-ternary */
import moment from 'moment'
import React, { useState } from 'react'
import { DocumentsResponseModel } from '../../../../domain/models/documents'
import { FilterModel } from '../../../../domain/models/enum'
import UploadDocumentInternal from '../../../../main/factories/pages/upload-documents/internal'
import DataGridCard from '../../../components/datagrid-card'
import FilterBar from '../../../components/filter-bar'
import Icons from '../../../components/icons-helper'
import { checkIsCustomer } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import {
  BoxCardStyled,
  ButtonStyled,
  CardContentStyled,
  CheckboxStyled,
  TextCardStyled,
  FieldCheckbox,
  FilterStyled,
  SperatorStyled,
  TitleCardContainer,
  TitleCardStyled,
  TitleContainer,
  TitleStyled,
  TitleStyledFilter,
  Wrapper,
  BoxCardInfoStyled, ProgressBarStyled,
  ContainerButton, ButtonComponentStyled,
} from './styles'

type PageProps = {
  list: DocumentsResponseModel | undefined
  setParams: any
  loading?:boolean
  refetch: ()=> void
}

type FilterComponentProps = {
  submit:(value:string)=>void
}

const FilterComponent = ({ submit }:FilterComponentProps):JSX.Element => {
  const [values, setValues] = useState<any>({})

  const handleChange = (e:any):void => {
    setValues(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }

  const applyFilter = (): void => {
    submit(values)
  }

  return (
    <FilterStyled>
      <SperatorStyled />
      <TitleStyledFilter>
        Status
      </TitleStyledFilter>
      <FieldCheckbox>
        <CheckboxStyled
          name="status=PENDING"
          value="PENDING"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Pendente
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="COMPLETE"
          name="status=COMPLETE"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Concluído
      </FieldCheckbox>

      <ButtonStyled onClick={() => applyFilter()}>Aplicar</ButtonStyled>
    </FilterStyled>
  )
}

const UploadDocumentsListPage = ({
  list, setParams, loading, refetch,
}:PageProps):JSX.Element => {
  const [open, setOpen] = useState<any>(false)
  const handlePage = (e:any):void => {
    setParams((prev: FilterModel) => ({
      ...prev,
      page: e,
    }))
  }

  const handleSearch = (callback:any):void => {
    const val = callback()

    setParams(
      (prev: any) => ({
        ...prev,
        filters: { ...prev?.filters, customer: val.query },
      }
      ),
    )
  }

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>{checkIsCustomer() ? 'Documentos' : 'Upload de documentos'}</TitleStyled>
      </TitleContainer>
      <FilterBar
        handleFilter={handleSearch}
        filterComponent={(
          <FilterComponent
            submit={(values: any) => setParams((prev: any) => ({
              ...prev,
              filters: { ...prev?.filters, ...values },
            }))}
          />
)}
      >
        {checkIsCustomer() && (
        <ContainerButton>
          <ButtonComponentStyled to="/request-documents">
            <Icons
              name="plus"
              width={15}
              height={15}
              color={theme.colors.white}
            />
            Solicitar Documento
          </ButtonComponentStyled>

        </ContainerButton>
        )}

      </FilterBar>

      <DataGridCard
        columns={3}
        loading={!!loading}
        data={list?.content || []}
        totalElements={list?.totalElements || 0}
        currentPage={list?.number || 0}
        totalPages={list?.totalPages || 0}
        changePage={handlePage}
        render={
          (item) => (
            <CardContentStyled onClick={() => setOpen(() => item)}>
              <BoxCardStyled>
                <TitleCardContainer>
                  <TitleCardStyled>
                    {checkIsCustomer()
                      ? item?.serviceProvider?.name : item?.customer?.name}
                  </TitleCardStyled>
                  <TextCardStyled>
                    {checkIsCustomer()
                      ? item?.serviceProvider?.user?.email : item?.customer?.user?.email}
                    {item?.customer?.email}
                  </TextCardStyled>
                </TitleCardContainer>
                <Icons
                  name="arrow-right"
                  width={20}
                  height={20}
                  color={theme.colors.darkGray}
                />
              </BoxCardStyled>
              <BoxCardInfoStyled>
                <TextCardStyled>
                  <Icons
                    name="folder-open"
                    width={20}
                    height={20}
                    color={theme.colors.darkGray}
                  />
                  {item?.count}
                  {' '}
                  documentos
                </TextCardStyled>
                <TextCardStyled>
                  <Icons
                    name="datepicker"
                    width={20}
                    height={20}
                    color={theme.colors.darkGray}
                  />
                  Data limite:
                  {' '}
                  {moment(item?.limitedDate).format('DD/MM/YYYY') }
                </TextCardStyled>
                {checkIsCustomer()
                  ? (
                    <TextCardStyled>
                      <Icons
                        name="pin"
                        width={20}
                        height={20}
                        color={theme.colors.darkGray}
                      />
                      <li
                        style={{
                          color: item?.sendProgress === 100
                            ? theme.colors.green : theme.colors.darkGray,
                        }}
                      >
                        {item?.sendProgress === 100 ? 'Recebido' : 'Em Aguardo'}

                      </li>
                    </TextCardStyled>
                  ) : (
                    <TextCardStyled style={{ marginBottom: 0 }}>
                      <ProgressBarStyled value={item?.sendProgress} max="100" />
                      {item?.sendProgress}
                      %
                    </TextCardStyled>

                  )}
              </BoxCardInfoStyled>
            </CardContentStyled>
          )
        }
      />
      <UploadDocumentInternal
        content={open}
        onCloseCallBack={() => setOpen(false)}
        refetchList={refetch}
      />
    </Wrapper>
  )
}
export default UploadDocumentsListPage
