import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Wrapper = styled.div`
  width: calc(100% - 40px);
  height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0 20px;

  .container{
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    max-width: 608px;
    height: 100vh;
    max-height: 608px;
  }
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;

  h3{
    font-family: ${theme.font.comfortaa};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.xlarge};
    color: ${theme.colors.primary};
  }
  
  h4{
    font-family: ${theme.font.comfortaa};
    font-weight: ${theme.font.bold};
    font-size: ${theme.font.sizes.medium};
    color: ${theme.colors.darkGray};
    margin-top: 1.4rem;
  }
`

export const Subtitle = styled.h2`
  color: ${theme.colors.primary};
  font-family: ${theme.font.comfortaa};
  font-weight: ${theme.font.normal};
  font-size: ${theme.font.sizes.xlarge};
  letter-spacing: 12px;
  margin-top: 12px;
  margin-bottom: 34px;
`
