import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { Roles, useAuth } from '../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../domain/models/enum'
import { TaskChart } from '../../../../domain/models/task'
import { makeRemoteTask } from '../../../../main/factories/usecases/task/remote-task-factory'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import {
  Card, Content, ContentTitle, LabelMeta, LabelTask, SubtitleContainer, Title,
} from './styles'

declare global {
  interface Window {
    Chart?: any;
  }
}

type CardProps = {
  period: string
  serviceProvider?: string | undefined
  customer?: string | undefined
}

const TaskByBranch = ({ period, serviceProvider, customer }: CardProps):JSX.Element => {
  const [chartData, setChartData] = useState<TaskChart>()
  const [loading, setloading] = useState<boolean>()
  const { user } = useAuth()

  const getRequestBytypeUser = (userType: string, customParams: FilterModel):any => {
    if (userType === Roles.BRANCH_OFFICE || userType === Roles.HEAD_OFFICE) {
      return makeRemoteTask().loadTaskChart(customParams)
    }
    if (userType === Roles.CUSTOMER_HEAD_OFFICE || userType === Roles.CUSTOMER_BRANCH_OFFICE) {
      return makeRemoteTask().customerTaskChart(customParams)
    }
    return makeRemoteTask
  }

  useEffect(() => {
    if (user || serviceProvider) {
      (async () => {
        setloading(true)

        const dataChart = await getRequestBytypeUser(user?.roles[0]?.authority, {
          period,
          serviceProvider,
          customer: customer || serviceProvider,
        })

        const data = {
          labels: dataChart?.branchOffices,
          datasets: [
            {
              barThickness: 10,
              label: 'Fully Rounded',
              data: dataChart?.branchGoals,
              borderRadius: Number.MAX_VALUE,
              borderSkipped: false,
              backgroundColor: '#A2D6FF',
            },
            {
              barThickness: 10,
              label: 'Small Radius',
              borderRadius: Number.MAX_VALUE,
              data: dataChart?.totalTaskCompleted,
              backgroundColor: '#0172CB',
              borderSkipped: false,
            },
          ],
        }

        const config = {
          type: 'bar',
          data,
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                },
              },
            },
          },
        }

        setChartData(dataChart)
        setloading(false)
        const ctx = document?.getElementById('myChart')
        const myChart = new window.Chart(ctx, config)
        console.warn(myChart)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProvider, period, customer])

  return (
    <Card>
      <Content>
        <ContentTitle>
          <Title>
            Tarefas realizadas por filial
          </Title>
          <div className="d-flex">
            <LabelMeta>
              Meta
            </LabelMeta>
            <LabelTask>
              Tarefas realizadas
            </LabelTask>
          </div>
        </ContentTitle>
        <SubtitleContainer>
          <p>{chartData?.totalPeriodTasks}</p>
          <span>
            <Icons height={5} width={5} name="arrow-up" color={theme.colors.green} />
            +
            {chartData?.percentageToPreviousPeriod}
            %
          </span>
        </SubtitleContainer>
        {loading ? <Spinner animation="grow" style={{ margin: '0 auto' }} variant="primary" /> : <canvas id="myChart" />}
      </Content>
    </Card>
  )
}
export default TaskByBranch
