/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
class LocalStorage {
  static set(key: string, value: any): boolean {
    try {
      window.localStorage.setItem(key, JSON.stringify(value))
      return true
    } catch (error) {
      return false
    }
  }

  static get(key: string): any {
    try {
      return JSON.parse(window.localStorage.getItem(key) || '')
    } catch (error) {
      return false
      console.error('Erro ao salvar no local Storage', error)
    }
  }

  static remove(key: string): boolean {
    try {
      window.localStorage.removeItem(key)
      return true
    } catch (error) {
      return false
    }
  }

  static clear(): boolean {
    try {
      for (const key in localStorage) {
        window.localStorage.removeItem(key)
      }
      return true
    } catch (error) {
      console.error('Erro ao limpar storage')
      return false
    }
  }
}

export default LocalStorage
