import axios from 'axios'
import fileDownload from 'react-file-download'
import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { CustomerProps } from '../../../domain/models/customer'
import { FilterModel } from '../../../domain/models/enum'
import { ServiceProvider, ServiceProviderProps, ServiceProviderResponseModel } from '../../../domain/models/service-provider'
import Authentication from '../../../infra/cognito/authentication'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteServiceProvider implements ServiceProvider {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
    private readonly baseUrl: string | undefined,
  ) { }

  async load(params: FilterModel): Promise<ServiceProviderResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async all(): Promise<ServiceProviderProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/all`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadById(id: number): Promise<ServiceProviderProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async branchs(params: FilterModel): Promise<ServiceProviderProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/branch-offices`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async customers(params: FilterModel): Promise<CustomerProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customer`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async create(body: ServiceProviderProps): Promise<ServiceProviderProps> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'post',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      case HttpStatusCode.serverError: throw httpResponse.body
      case HttpStatusCode.badRequest: throw httpResponse.body
      case HttpStatusCode.conflict: throw httpResponse.body
      default: throw httpResponse.body
    }
  }

  async update(id: number, body: ServiceProviderProps): Promise<ServiceProviderProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async delete(id: number): Promise<ServiceProviderProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'delete',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      case HttpStatusCode.conflict: throw httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async exportCSV(params: FilterModel): Promise<void> {
    const token = await Authentication.getToken()
    const resp = await axios.get(`${this.baseUrl}${this.url}/export`, {
      responseType: 'blob',
      params,
      headers: {
        Authorization: token,
        accept: '*/*',
      },

    })
    fileDownload(resp.data, `Prestadores de Serviço - ${new Date().getTime()}.xlsx`)
  }
}
