import React from 'react'
import { Link } from 'react-router-dom'
import {
  BoxButton,
  Card, Content, ImageStyled, Title,
} from './styles'
import image from '../../../assets/images/new-task.png'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'

const NewTaskCard = ():JSX.Element => (
  <Card>
    <Content>
      <ImageStyled src={image} />
      <Title>
        Crie novas tarefas com facilidade
      </Title>
      <Link to="/tasks/new">
        <BoxButton>
          <Icons name="plus" width={15} height={15} color={theme.colors.white} />
          Nova tarefa
        </BoxButton>
      </Link>
    </Content>
  </Card>
)
export default NewTaskCard
