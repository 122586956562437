/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { CustomerProps, CustomerResponseModel } from '../../../../domain/models/customer'
import { FilterModel } from '../../../../domain/models/enum'
import { ServiceProviderProps } from '../../../../domain/models/service-provider'
import DataGrid from '../../../components/datagrid'
import { DialogModal } from '../../../components/dialog-modal'
import FilterBar from '../../../components/filter-bar'
import Icons from '../../../components/icons-helper'
import { theme } from '../../../styles/theme'
import { ExportButtonComponentStyled } from '../../task/task-list/styles'
import {
  ButtonStyled,
  CheckboxStyled,
  ContainerButton,
  FieldCheckbox,
  FilterStyled,
  SperatorStyled,
  TitleContainer,
  TitleStyled, TitleStyledFilter, Wrapper,
} from '../list/styles'
import { fields, fieldsModal } from './fields'
import {
  CloseButton, ContainerGrid, ModalStyled, TitleModal,
} from './styles'

type PageProps = {
  list: CustomerResponseModel | undefined
  setParams: any
  params: any
  loading?:boolean
  exportList?: ()=> Promise<void>
  handleSubmit: (values: any, actions: any) => void
  serviceProviderList: ServiceProviderProps[]
}

type FilterComponentProps = {
  submit:(value:string)=>void
  params: any
}

const FilterComponent = ({ submit, params }:FilterComponentProps):JSX.Element => {
  const [values, setValues] = useState<any>({ ...params })

  const handleChange = (e:any):void => {
    setValues(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }

  const applyFilter = (): void => {
    submit(values)
  }

  return (
    <FilterStyled>
      <SperatorStyled />
      <TitleStyledFilter>
        Status
      </TitleStyledFilter>
      <FieldCheckbox>
        <CheckboxStyled
          name="enabled=false"
          value="false"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Inativo
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="true"
          name="enabled=true"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Ativo
      </FieldCheckbox>
      <ButtonStyled onClick={() => applyFilter()}>Aplicar</ButtonStyled>
    </FilterStyled>
  )
}
const CustomerBranchsListPage = ({
  list, setParams, params, loading, exportList, handleSubmit, serviceProviderList,
}:PageProps):JSX.Element => {
  const [show, setShow] = useState<CustomerProps | any>()
  const [open, setOpen] = useState<ServiceProviderProps | any>(false)
  const handlePage = (e:any):void => {
    setParams((prev: FilterModel) => ({
      ...prev,
      page: e,
    }))
  }

  const handleSearch = (callback:any):void => {
    const val = callback()

    setParams(
      (prev: any) => ({
        ...prev,
        filters: { ...prev?.filters, name: val.query },
      }
      ),
    )
  }

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>Filiais</TitleStyled>
      </TitleContainer>
      <FilterBar
        handleFilter={handleSearch}
        filterComponent={(
          <FilterComponent
            params={params}
            submit={(values: any) => setParams((prev: any) => ({
              ...prev,
              filters: { ...prev?.filters, ...values },
            }))}
          />
)}
      >
        <ContainerButton>
          <ExportButtonComponentStyled onClick={async () => {
            if (exportList) {
              await exportList()
            }
          }}
          >
            Exportar
          </ExportButtonComponentStyled>
        </ContainerButton>
      </FilterBar>
      <DataGrid
        loading={!!loading}
        fields={fields}
        data={list?.content || []}
        onClickRow={(e) => setShow(e)}
        totalElements={list?.totalElements || 0}
        currentPage={list?.number || 0}
        totalPages={list?.totalPages || 0}
        changePage={handlePage}
      />
      <ModalStyled show={!!show} onHide={() => setShow(null)}>
        <Modal.Body>
          <TitleModal>
            Selecione a filial para realizar o vínculo
            <CloseButton onClick={() => setShow(null)}>
              <Icons
                name="close"
                width={15}
                height={15}
                color={theme.colors.black}
              />
            </CloseButton>
          </TitleModal>
          <ContainerGrid>
            <DataGrid
              loading={!!loading}
              fields={fieldsModal(show?.serviceProvider?.uuid)}
              data={serviceProviderList || []}
              onClickRow={(e) => setOpen(e)}
              totalElements={list?.totalElements || 0}
              currentPage={list?.number || 0}
              totalPages={list?.totalPages || 0}
              changePage={handlePage}
            />
          </ContainerGrid>

          <DialogModal
            title={open?.name || ''}
            message="Tem certeza que deseja Vicular a esta filial de Prestador de Serviço?"
            buttons={{
              confirm: 'Continuar',
              cancel: 'Cancelar',
            }}
            open={!!open}
            cancelCallback={() => setOpen(false)}
            confirmCallback={async () => {
              handleSubmit(open, show)
              setOpen(false)
              setShow(false)
            }}
          />
        </Modal.Body>
      </ModalStyled>
    </Wrapper>
  )
}
export default CustomerBranchsListPage
