import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../contexts/alert-box/alert_box'
import { Roles, useAuth } from '../../../../contexts/auth-context/auth-context'
import { CustomerProps } from '../../../../domain/models/customer'
import { ServiceProviderProps } from '../../../../domain/models/service-provider'
import MakeServiceProviderBranchs from '../../../../main/factories/pages/service-provider/internal/branchs'
import { makeRemoteCustomer } from '../../../../main/factories/usecases/customer/remote-customer-factory'
import { makeRemoteServiceProvider } from '../../../../main/factories/usecases/service-provider/remote-service-provider-factory'
import { DialogModal } from '../../../components/dialog-modal'
import FormFactory from '../../../components/form-factory'
import Icons from '../../../components/icons-helper'
import { checkIsCustomer, isAdmin } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { makeFields } from './fields'
import {
  ActionBox,
  ActionButton,
  BackButton,
  ContainerData,
  ContainerForm,
  PhotoFullStyled,
  TitleContainer, TitleStyled, TopContainer, ViewMore, Wrapper,
} from './styles'
import { makeRemoteUser } from '../../../../main/factories/usecases/user/remote-user-factory'

type PageProps = {
  data: ServiceProviderProps | CustomerProps | undefined
  handleSubmit: (values: any, actions: any) => void
}

const ServiceProviderInternalPage = ({
  data, handleSubmit,
}:PageProps):JSX.Element => {
  const {
    setBranch, setIsheadeOffice, setAdminLocal, setUserLocal, user,
  } = useAuth()
  const [editMode, setEditMode] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [openResetConfirm, setOpenReset] = useState<boolean>(false)
  const [show, setShow] = useState<string | null>()
  const { handleAlert } = useAlertBox()
  const history = useHistory()
  useEffect(() => {
    if (data?.id) {
      setEditMode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])
  console.log(data, isAdmin())
  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>
          {data?.name || (isAdmin() ? 'Nova Matriz' : 'Nova Filial')}
        </TitleStyled>
      </TitleContainer>
      <ContainerForm>
        <TopContainer>
          <BackButton to="/service-provider">
            <Icons
              name="arrow-left"
              width={12}
              height={12}
              color={theme.colors.black}
            />
            Voltar
          </BackButton>
          {data?.id && (
          <>
            <ActionBox>
              {data?.id && (
              <ViewMore onClick={async ():Promise<void> => {
                if (isAdmin()) {
                  await setBranch({ ...data, openModal: true })
                  await setIsheadeOffice(true)
                  await setAdminLocal(user)
                  setUserLocal({
                    ...data,
                    roles: [{
                      authority: isAdmin()
                        ? Roles.HEAD_OFFICE : Roles.CUSTOMER_HEAD_OFFICE,
                    }],
                  })
                } else {
                  await setBranch({ ...data, openModal: true })
                  await setIsheadeOffice(false)
                }
                history.push('/')
              }}
              >
                Ver mais detalhes
                <Icons
                  name="arrow-diagonal"
                  width={12}
                  height={12}
                  color={theme.colors.green}
                />
              </ViewMore>
              )}
              <ActionButton onClick={() => setEditMode((prev) => !prev)}>
                <Icons
                  name={!editMode ? 'close' : 'substract'}
                  width={12}
                  height={12}
                  color={theme.colors.primary}
                />
              </ActionButton>
              <ActionButton onClick={() => setOpen(true)}>
                <Icons
                  name="trash"
                  width={12}
                  height={12}
                  color={theme.colors.red}
                />
              </ActionButton>
            </ActionBox>
          </>
          )}
        </TopContainer>

        <ContainerData>
          <FormFactory
            className={`internal-form mb-3 ${editMode && 'editMode'}`}
            groups={makeFields(data, editMode, setOpenReset)}
            button={{ submit: 'Salvar', submitting: 'Salvando...' }}
            onSubmit={async (values:any, actions:any) => {
              await handleSubmit(values, actions)
              setEditMode(true)
            }}
          />

        </ContainerData>
        {data?.id && (
        <DialogModal
          title="Excluir Filial"
          message="Tem certeza que deseja excluir esse usuário?"
          buttons={{
            confirm: 'Continuar',
            cancel: 'Cancelar',
          }}
          open={open}
          cancelCallback={() => setOpen(false)}
          confirmCallback={async () => {
            try {
              if (checkIsCustomer()) {
                await makeRemoteCustomer().delete(data?.id || 0)
              } else {
                await makeRemoteServiceProvider().delete(data?.id)
              }
              setOpen(false)
              history.push('/service-provider')
            } catch (error: any) {
              console.error(error)
              handleAlert({
                show: true,
                type: 'danger',
                message: `Essa usuário não pode ser excluída!:${error?.userMessage}`,
              })
            }
          }}
        />
        )}
        {data?.id && (
        <DialogModal
          title="Resetar senha para usuário"
          message="Tem certeza que resetar a senha deste usuário?"
          buttons={{
            confirm: 'Continuar',
            cancel: 'Cancelar',
          }}
          open={openResetConfirm}
          cancelCallback={() => setOpenReset(false)}
          confirmCallback={async () => {
            try {
              await makeRemoteUser().resetPassword(data?.user?.id)
              setOpenReset(false)
            } catch (error: any) {
              console.error(error)
              handleAlert({
                show: true,
                type: 'danger',
                message: `Esse usuário não pode ter a senha resetada!:${error?.userMessage}`,
              })
            }
          }}
        />
        )}
        {isAdmin() && data?.id && <MakeServiceProviderBranchs serviceProvider={data} />}
        <Modal show={!!show} onHide={() => setShow(null)}>
          <Modal.Header closeButton />
          <Modal.Body>
            <PhotoFullStyled src={show || ''} />
          </Modal.Body>
        </Modal>
      </ContainerForm>
    </Wrapper>
  )
}
export default ServiceProviderInternalPage
