/* eslint-disable react/jsx-indent */
import React from 'react'
import { CustomerProps } from '../../../../domain/models/customer'
import { ServiceProviderProps } from '../../../../domain/models/service-provider'
import { ItemField } from '../../../components/datagrid'
import Icons from '../../../components/icons-helper'
import { checkIsCustomer, getColorByStatusEnabled, isAdmin } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { ProgressStyled, RatingStyled, StatusStyled } from '../list/styles'
import { CustomComponentRow } from './styles'
import { BoxResendPass } from '../../operators/internal/styles'
import ButtonComponent from '../../../components/button'

export const makeFields = (values: ServiceProviderProps | CustomerProps | any,
  editMode: boolean,
  resendPass: (e:any) => void): any[] => [
  {
    groupName: 'Dados da tarefa',
    groupLabel: 'Dados da tarefa',
    items: [
      {
        name: 'name',
        type: 'text',
        label: isAdmin() ? 'Nome da Matriz' : 'Filial',
        disabled: editMode,
        defaultValue: values?.name,
        placeholder: 'Digite o nome da Filial',
        validations: 'inputRequired',
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        name: 'username',
        type: 'text',
        label: 'Responsável',
        disabled: editMode,
        defaultValue: values?.user?.name,
        placeholder: 'Digite o nome do Operador',
        validations: 'inputRequired',
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        name: 'email',
        type: 'text',
        label: 'E-mail',
        disabled: values?.id,
        defaultValue: values?.user?.email,
        placeholder: 'Digite o E-mail',
        validations: 'emailRequired',
        col: {
          md: 2,
          lg: 3,
        },
      },
      {
        type: 'component',
        label: 'ResetPass',
        hide: !values?.id,
        disabled: editMode,
        defaultValue: values?.meetsRequirement,
        component:
            <BoxResendPass>
               <ButtonComponent
                 onClick={async () => {
                   await resendPass(true)
                 }}
                 type="button"
                 title="Resetar Senha"
                 color={theme.colors.white}
                 backgroundColor={theme.colors.green}
               />
            </BoxResendPass>,
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        type: 'component',
        hide: !values?.id || !editMode || checkIsCustomer(),
        label: 'Avaliação Média',
        component: <CustomComponentRow>
                    <span className="mb-3">Avaliação Média</span>
                    <div>
                      <Icons
                        name="star-fill"
                        width={30}
                        height={30}
                        color={theme.colors.yellow}
                      />
                     {Number(values?.customerAverageReviewGrade || 0).toFixed(1)}
                    </div>
                   </CustomComponentRow>,
        col: {
          md: 2,
          lg: 2,
        },
      }, {
        name: 'enabled',
        type: 'toggle',
        label: 'Status',
        disabled: editMode,
        defaultValue: values?.enabled,
        validations: 'inputRequired',
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        type: 'component',
        label: 'Status',
        hide: !values?.id || !editMode || checkIsCustomer(),
        disabled: editMode,
        defaultValue: values?.customerAverageReviewGrade,
        component: <CustomComponentRow>
                    <span>Progresso do dia</span>
                    <div className="mt-3">
                      <ProgressStyled
                        className="me-2"
                        max={values?.progressDay?.completed}
                        value={values?.progressDay?.todo}
                      />
                      {String(values?.progressDay?.completed || 0).padStart(2, '0')}
                      /
                     {String(values?.progressDay?.todo || 0).padStart(2, '0')}
                    </div>
                   </CustomComponentRow>,
        col: {
          md: 3,
          lg: 3,
        },
      },
    ],
  },
]

export const listFields:ItemField[] = [
  { title: 'Filial', key: 'name', renderItem: (data:ServiceProviderProps) => data.name },
  {
    title: 'Avaliação do cliente',
    key: 'rating',
    hide: checkIsCustomer(),
    renderItem: (data:ServiceProviderProps) => (
      <RatingStyled>
        <Icons
          name="star-fill"
          width={20}
          height={20}
          color={theme.colors.yellow}
        />
        <p>
          {Number(data?.customerAverageReviewGrade || 0).toFixed(1)}
        </p>
      </RatingStyled>
    ),
  },
  { title: 'Status', key: 'status', renderItem: (data:ServiceProviderProps) => <StatusStyled color={getColorByStatusEnabled(!!data.enabled)}>{data.enabled ? 'Ativo' : 'Inativo'}</StatusStyled> },
  {
    title: 'Responsável',
    key: 'status',
    hide: !checkIsCustomer(),
    renderItem: (data:ServiceProviderProps) => data.user?.name,
  },
  {
    title: 'Tarefas a fazer',
    key: 'taskToDo',
    hide: !checkIsCustomer(),
    renderItem: (data:ServiceProviderProps) => data.taskToDo,
  },
  {
    title: 'Tarefas em andamento',
    key: 'taskInProgress',
    hide: !checkIsCustomer(),
    renderItem: (data:ServiceProviderProps) => data.taskInProgress,
  },
  {
    title: 'Tarefas concluídas',
    key: 'taskCompleted',
    hide: !checkIsCustomer(),
    renderItem: (data:ServiceProviderProps) => data.taskCompleted,
  },
  {
    title: 'Tarefas a avaliar',
    key: 'taskToBeEvaluated',
    hide: !checkIsCustomer(),
    renderItem: (data:ServiceProviderProps) => data.taskToBeEvaluated,
  },

  {
    title: 'Operadores', key: 'user', renderItem: (data:ServiceProviderProps) => data?.operatorsCount || 0,
  },
  {
    title: 'Tarefas do dia',
    key: 'email',
    hide: checkIsCustomer(),
    renderItem: (data:ServiceProviderProps) => (
      <RatingStyled>
        <ProgressStyled max={data.progressDay?.todo} value={data.progressDay?.completed} />
        <p>
          {String(data.progressDay?.completed || 0).padStart(2, '0')}
          /
          {String(data.progressDay?.todo || 0).padStart(2, '0')}
        </p>
      </RatingStyled>
    ),
  },
]
