import React from 'react'
import { Col } from 'react-bootstrap'
import { useAuth } from '../../../contexts/auth-context/auth-context'
import FormFactory from '../../components/form-factory'
import {
  BackgroudStyled,
  CardForm,
  SubTitle,
  Title,
} from './styles'

type PageProps = {
  typesRules: any[]
  handleSubmit: (values: any, actions: any) => void
}

const SelectType = ({ handleSubmit, typesRules }: PageProps):JSX.Element => {
  const { user } = useAuth()
  const stepOne = [
    {
      groupName: 'Login',
      items: [
        {
          name: 'env',
          type: 'radio',
          validations: 'inputRequired',
          options: typesRules || [],
          col: {
            xs: 12,
            lg: 6,
          },
        },
      ],
    },
  ]

  return (
    <Col xs={12} className="p-0 align-items-center d-flex">
      <BackgroudStyled>
        <CardForm>
          {user?.name && (
          <SubTitle>
            Bem vindo,
            {` ${user?.name}`}
          </SubTitle>
          )}
          <Title>
            Em qual perfil deseja prosseguir?
          </Title>
          <FormFactory groups={stepOne} onSubmit={handleSubmit} button={{ submit: 'Prosseguir', submitting: 'Prosseguindo...' }} />
        </CardForm>
      </BackgroudStyled>
    </Col>
  )
}
export default SelectType
