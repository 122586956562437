// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { Roles, useAuth } from '../../../../../contexts/auth-context/auth-context'
import { CustomerProps } from '../../../../../domain/models/customer'
import { ServiceProviderProps } from '../../../../../domain/models/service-provider'
import { checkIsCustomer, isAdmin } from '../../../../../presentation/helpers/utils'
import ServiceProviderInternalPage from '../../../../../presentation/pages/service-provider/internal'
import { makeRemoteCustomer } from '../../../usecases/customer/remote-customer-factory'
import { makeRemoteServiceProvider } from '../../../usecases/service-provider/remote-service-provider-factory'

function MakeServiceProviderInternal({ match }:any): JSX.Element {
  const { handleAlert } = useAlertBox()
  const [data, setData] = useState<ServiceProviderProps | CustomerProps>()
  const history = useHistory()
  const { user } = useAuth()

  const selectRequestByType = (userType: string, id: number):any => {
    if (userType === Roles.BRANCH_OFFICE || userType === Roles.HEAD_OFFICE
      || userType === Roles.ADMIN) {
      return makeRemoteServiceProvider().loadById(id)
    }
    if (userType === Roles.CUSTOMER_HEAD_OFFICE || userType === Roles.CUSTOMER_BRANCH_OFFICE) {
      return makeRemoteCustomer().loadById(id)
    }
    return null
  }

  const loadData = async (): Promise<void> => {
    try {
      const content = await selectRequestByType(user?.roles[0]?.authority, match?.params?.id)
      setData(content)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (async () => {
      if (match?.params?.id && user && match?.params?.id !== 'create') {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, user])

  const handleSubmit = async (values: any, actions: any): Promise<any> => {
    try {
      if (data?.id) {
        const updatedData = await makeRemoteServiceProvider().update(data?.id, {
          user: {
            email: values?.email,
            name: values?.username,
          },
          headOfficeServiceProvider: isAdmin() ? null : {
            id: user.id,
          },
          name: values?.name,
          headOffice: isAdmin() || false,
          enabled: values?.enabled,
        })

        setData(updatedData)
      } else {
        if (!values?.email) {
          handleAlert({
            show: true,
            type: 'error',
            message: 'Erro ao Enviar E-mail para o servidor! Tente novamente',
          })
          actions.setSubmitting(false)
          return
        }
        const createData = await makeRemoteServiceProvider().create({
          user: {
            email: values?.email,
            name: values?.username,
          },
          headOfficeServiceProvider: isAdmin() ? null : {
            id: user.id,
          },
          name: values?.name,
          headOffice: isAdmin() || false,
          enabled: values?.enabled,
        })
        history.push(`/service-provider/${createData.id}`)
      }

      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: (error?.error
          || error?.detail || error?.title || error?.userMessage || error?.message),
      })
      actions.setSubmitting(false)
    }
  }
  const handleSubmitCustomer = async (values: any, actions: any): Promise<any> => {
    try {
      if (data?.id) {
        const updatedData = await makeRemoteCustomer().update(data?.id, {
          user: {
            email: values?.email,
            name: values?.username,
          },
          headOfficeCustomer: isAdmin() ? null : {
            id: user.id,
          },
          name: values?.name,
          headOffice: false,
          enabled: values?.enabled,
        })

        setData(updatedData)
      } else {
        if (!values?.email) {
          handleAlert({
            show: true,
            type: 'error',
            message: 'Erro ao Enviar E-mail para o servidor! Tente novamente',
          })
          actions.setSubmitting(false)
          return
        }
        const createData = await makeRemoteCustomer().create({
          user: {
            email: values?.email,
            name: values?.username,
          },
          headOfficeCustomer: isAdmin() ? null : {
            id: user.id,
          },
          name: values?.name,
          headOffice: false,
          enabled: values?.enabled,
        })
        history.push(`/service-provider/${createData.id}`)
      }

      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: (error?.error
          || error?.detail || error?.title || error?.userMessage || error?.message),
      })
      actions.setSubmitting(false)
    }
  }
  return (
    <ServiceProviderInternalPage
      data={data}
      handleSubmit={checkIsCustomer()
        ? handleSubmitCustomer : handleSubmit}
    />
  )
}

export default MakeServiceProviderInternal
