import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Wrapper = styled.div`
  /* max-width: 300px;
  width: 100%; */
  margin-top: 10px;
`
export const ListStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
type ItemProp = {
  selected: boolean
}

export const ItemStyled = styled.span`
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: .6rem;
  color: ${theme.colors.middleGray};
  padding: 15px 15px 10px 15px;
  cursor: pointer;
  border-bottom:${(p: ItemProp) => (p.selected ? `3px solid ${theme.colors.primary}` : '2px solid #EFEFEF')} ;
`
