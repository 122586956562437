import { ProductModel } from '../../../../domain/models/product'

export const makeFields = (values: ProductModel | undefined, editMode: boolean): any[] => [
  {
    groupName: 'Dados',
    groupLabel: 'Dados do Produto',
    items: [
      {
        name: 'name',
        type: 'text',
        label: 'Nome',
        disabled: editMode,
        defaultValue: values?.name,
        placeholder: 'Digite o nome do Operador',
        validations: 'inputRequired',
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        name: 'image',
        type: 'file',
        label: 'Imagem',
        disabled: editMode,
        defaultValue: values?.imageUrl,
        placeholder: 'Anexar Foto',
        validations: 'inputRequired',
        icon: 'image',
        accept: 'image/*',
        col: {
          md: 4,
          lg: 4,
        },
      },
      {
        name: 'price',
        type: 'currency',
        label: 'Valor',
        disabled: editMode,
        defaultValue: values?.price,
        placeholder: 'Digite o Valor',
        validations: 'inputRequired',
        col: {
          md: 4,
          lg: 4,
        },
      },
      {
        name: 'purchasedAt',
        type: 'date',
        label: 'Data de compra',
        disabled: editMode,
        defaultValue: values?.purchasedAt,
        placeholder: 'Digite a data de compra',
        validations: 'inputRequired',
        col: {
          md: 2,
          lg: 2,
        },
      },

    ],
  },
]
