import React from 'react'
import ReactDOM from 'react-dom'
import Amplify, { I18n } from 'aws-amplify'

import { BrowserRouter } from 'react-router-dom'
import Routes from './presentation/components/routes'
import { GlobalStyle } from './presentation/styles/global'
import 'bootstrap/dist/css/bootstrap.min.css'
import cognitoConfig from './main/configs/aws-exports.js'
import { dictionary } from './presentation/dictionary'
import { addLanguage } from './presentation/adapters/translate/translate'

Amplify.configure(cognitoConfig)
I18n.setLanguage('pt_BR')
I18n.putVocabularies(dictionary)
addLanguage('pt_BR')

ReactDOM.render(
  <React.StrictMode>
    <BrowserRouter>
      <GlobalStyle />
      <Routes />
    </BrowserRouter>
  </React.StrictMode>,
  document.getElementById('root'),
)
