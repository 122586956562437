import * as yup from 'yup'

export const getValidationSchemaByName = (name: string): any => {
  if (!Array.isArray(name)) {
    switch (name) {
      case 'photoRequired':
        return yup.string().required('Foto obrigatório')
      case 'inputRequired':
        return yup.string().required('Campo obrigatório')
      case 'objectRequired':
        return yup.object().required('Campo obrigatório')
      case 'emailRequired':
        return yup.string()
          .email('Email inválido')
          .required('Campo obrigatório')
      case 'passwordMinRequired':
        return yup.string()
          .min(8, 'Senha pequena')
          .required('Campo obrigatório')
      case 'passwordWithMatches':
        return yup.string()
          .required('Campo obrigatório')
          .min(8, 'Senha pequena')
          .max(24, 'Senha muito grande')
          .matches(/[a-z]/, 'Ao menos uma letra minúscula')
          .matches(/[A-Z]/, 'Ao menos uma letra maiúscula')
          .matches(/[0-9]/, 'Ao menos um número')
          .matches(
            /[!@#$%*()_/\\\-+^&{}:;?.]/,
            'Ao menos um caractere especial',
          )
      case 'confirmPassword':
        return yup.string().when('password', {
          is: (val: any) => val && val.length >= 8,
          then: yup.string()
            .oneOf([yup.ref('password')], 'As senhas não são iguais')
            .required('Campo obrigatório'),
        })
      case 'inputRequiredCode':
        return yup.string()
          .required('Campo obrigatório')
          .matches(/[0-9]/, 'Apenas números')
      default:
        throw new Error(`Validation ${name} don't found`)
    }
  }

  return null
}
