import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Wrapper = styled.div`
  width: 100%;
`
export const GridStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  padding: 0 15px;
`
export const PaginateInfo = styled.span`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family:'poppins';
  font-weight: 400;
  font-size: 0.8rem;
  color: ${theme.colors.middleGray};
`

export const RowTable = styled.div`
  width: 100%;
  display: flex;
  border-radius: 16px;
  margin-bottom: 10px;
  cursor: pointer;
  box-shadow: 0px 0px 25px rgba(171, 171, 171, 0.15);
`
type BodyProps = {
  columns: number
}

export const BodyTableStyled = styled.div`
   display: grid;
  column-gap: 15px;
  grid-template-columns: ${(el:BodyProps) => Array.from({ length: el.columns }, (_, i) => i + 1).map(() => `${((100 / el.columns) - 1).toFixed(0)}%`).join(' ')};
  row-gap: 15px;
`
export const PaginateStyled = styled.div`
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  ul {
    list-style: none;
    display: flex;

    li {
      font-family: "poppins";
      font-weight: 400;
      font-size: 0.8rem;
      padding: 0px;
      color: ${theme.colors.darkGray};
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: ease all 50ms;
      :hover,:hover a {
        background-color: ${theme.colors.green};
        color: ${theme.colors.white};
        opacity: 0.8;
        border-radius: 6px;
      }
      margin: 0 2px;
    }
    a{
      width: 20px;
      justify-content: center;
      display: flex;
    }
    .previous,
    .next {
      a {
        font-size: 1rem;
        color: ${theme.colors.darkGray};
        padding: 0;
      }
    }
    .selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      border-radius: 6px;
    }
  }
`
