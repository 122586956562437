// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { ProductReponseModel } from '../../../../../domain/models/product'
import { StockProps } from '../../../../../domain/models/stock'
import { t } from '../../../../../presentation/adapters/translate/translate'
import StockInternalPage from '../../../../../presentation/pages/stock/stock'
import { makeRemoteProductStock } from '../../../usecases/product-stock/remote-product-stock'
import { makeRemoteProduct } from '../../../usecases/product/remote-product'

function MakeStockInternal({ match }:any): JSX.Element {
  const { profile } = useAuth()
  const { handleAlert } = useAlertBox()
  const [listProducts, setListProducts] = useState<ProductReponseModel>()
  const [data, setData] = useState<StockProps.Model>()
  const history = useHistory()

  const loadData = async (): Promise<void> => {
    const content = await makeRemoteProductStock().loadById(match?.params?.id)
    setData(content)
  }

  const loadDataStock = async (): Promise<void> => {
    try {
      const prod = await makeRemoteProduct().load({
        size: 1000,
      })
      setListProducts(prod)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (async () => {
      if (match?.params?.id && match?.params?.id !== 'create') {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (profile?.branch?.id) {
      loadDataStock()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [profile?.branch?.id])
  const handleSubmit = async (values: any, actions: any): Promise<any> => {
    try {
      if (data?.id) {
        const updatedData = await makeRemoteProductStock().update(data?.id, {
          product: {
            id: values?.product?.id,
          },
          quantity: Number(values?.quantity),
          quantityUnit: values?.quantityUnit?.value,
          serviceProvider: {
            id: profile?.branch?.id,
          },
        })
        setData(updatedData)
      } else {
        await makeRemoteProductStock().create({

          product: {
            id: values?.product?.id,
          },
          serviceProvider: {
            id: profile?.branch?.id,
          },
          quantity: Number(values?.quantity),
          quantityUnit: values?.quantityUnit?.value,

        })
        history.push('/stock')
      }

      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error?.userMessage || error?.message),
      })
      actions.setSubmitting(false)
    }
  }
  return (
    <StockInternalPage
      data={data}
      handleSubmit={handleSubmit}
      listProducts={listProducts}
    />
  )
}

export default MakeStockInternal
