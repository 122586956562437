import { Auth } from 'aws-amplify'
import axios, { AxiosResponse } from 'axios'
import { HttpClient, HttpRequest, HttpResponse } from '../../../data/protocols/http/http-client'

export class AxiosHttpClient implements HttpClient {
  constructor(
    private readonly baseUrl: string,
  ) { }

  async request(data: HttpRequest): Promise<HttpResponse> {
    let axiosResponse: AxiosResponse
    try {
      const token = await this.updateAmplifyToken()
      axiosResponse = await axios.request({
        url: this.baseUrl + data.url,
        method: data.method,
        data: data.body,
        ...(data?.params && { params: data?.params }),
        headers: { ...data?.headers, ...token },
      })
    } catch (error: any) {
      axiosResponse = error.response
    }
    switch (axiosResponse.status) {
      case 401:
        await Auth.signOut()
        break

      default:
        break
    }
    return {
      statusCode: axiosResponse.status,
      body: axiosResponse.data,
    }
  }

  updateAmplifyToken = async (): Promise<{ Authorization: string }> => {
    try {
      const session = await Auth.currentSession()
      return {
        Authorization: session.getIdToken().getJwtToken(),
      }
    } catch (_) {
      return {
        Authorization: this.baseUrl,
      }
    }
  }
}
