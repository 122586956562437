import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

export const BackButton = styled(Link)`
  color: ${theme.colors.black};
  font-size: .8rem;
  display: flex;
  align-items: center;

  svg{
    margin-right: 10px;
  }
`
export const ProgressBar = styled.progress`
  display: flex;
  margin-top:30px;
  margin-bottom: 15px;
  height: 25px;
`
export const TitleContainer = styled.div`
  display: flex;
  /* margin-top:30px; */
  margin-bottom: 15px;
`
export const ContainerForm = styled.div`
  box-shadow: 0px 0px 25px rgba(171, 171, 171, 0.15);
  margin-top: 10px;
  margin: 15px;
  border-radius: 20px;
  padding: 30px 30px;
`
export const CustomComponentRow = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 0px;
  flex-direction: column;
  color: ${theme.colors.black};
  font-family: 'poppins';
  font-weight: 300;
  font-size:.8rem;
  div{
    display: flex;
    align-items: center;
    svg{
      /* margin-top: 8px; */
    }
  }
  span{
    font-family: 'poppins';
    font-weight: 400;
    font-size: .7rem;
    margin-bottom: 5px;
    width: 100%;

  }
`
export const PhotoContainerRow = styled.div`
  display: flex;
  margin-bottom: 30px;
  margin-top: 15px;
`
export const PhotoContainerType = styled.div`
  display: flex;
  flex-direction: column;
  color: ${theme.colors.black};
  font-family: 'poppins';
  font-weight: 300;

`
export const PhotoContainer = styled.div`
  display: flex;
  margin-top: 15px;
`

export const PhotoFullStyled = styled.img`
  width: 100%;
  max-width: 600px;
`
export const PhotoStyled = styled.img`
  border-radius: 8px;
  margin-right: 15px;
  max-width: 120px;
  aspect-ratio: 1;
   cursor: pointer;
  object-fit: cover;
`
export const Subtitle = styled.h4`
  color: ${theme.colors.black};
  font-size: 1rem;
  font-weight: 500;
  text-align: start;
  margin-bottom: 5px;
`
export const TitleStyled = styled.h1`
  color: ${theme.colors.black};
  font-size: 2rem;
  padding: 0 15px;
  font-weight: 500;
`
export const TopContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 45px;
`
export const ContainerData = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
  p{
    color: ${theme.colors.darkGray};
    font-size: 1em;
    font-weight: 400;
  }
`
export const ActionBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`
export const ViewMore = styled.button`
  color: ${theme.colors.black};
  width: auto;
  height: 40px;
  border-radius:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
  font-size: .6rem;
  background-color: #C4FEB0;
  font-family: 'poppins';
  padding:0 15px;
  color: ${theme.colors.green};
  svg{
    margin-left: 5px;
  }
`
export const ActionButton = styled.button`
  color: ${theme.colors.black};
  width: 40px;
  height: 40px;
  border:1.5px solid #EFEFEF;
  border-radius:50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 10px;
`
