/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { FilterModel } from '../../../../domain/models/enum'
import { OperatorProps, OperatorResponseModel } from '../../../../domain/models/operator'
import DataGrid, { ItemField } from '../../../components/datagrid'
import FilterBar from '../../../components/filter-bar'
import Icons from '../../../components/icons-helper'
import { getColorByStatusEnabled } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { ExportButtonComponentStyled } from '../../task/task-list/styles'
import {
  ButtonComponentStyled,
  ButtonStyled,
  CheckboxStyled,
  ContainerButton,
  // DateContainer,
  // DateStyled,
  FieldCheckbox,
  FilterStyled,
  // LabelStyled,
  ProgressStyled,
  RatingStyled, SperatorStyled,
  StatusStyled, TitleContainer,
  TitleStyled, TitleStyledFilter, Wrapper,
} from './styles'

type PageProps = {
  list: OperatorResponseModel | undefined
  setParams: any
  params: any
  loading?:boolean
  exportList?: ()=> Promise<void>

}

type FilterComponentProps = {
  submit:(value:string)=>void
  params: any
}

const FilterComponent = ({ submit, params }:FilterComponentProps):JSX.Element => {
  const [values, setValues] = useState<any>({ ...params })
  const handleChange = (e:any):void => {
    setValues(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }

  const applyFilter = (): void => {
    submit(values)
  }

  return (
    <FilterStyled>
      <SperatorStyled />
      <TitleStyledFilter>
        Status
      </TitleStyledFilter>
      <FieldCheckbox>
        <CheckboxStyled
          name="enabled=false"
          value="false"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Inativo
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="true"
          name="enabled=true"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Ativo
      </FieldCheckbox>
      {/* <SperatorStyled />
      <TitleStyledFilter>
        Data
      </TitleStyledFilter>
      <DateContainer>

        <LabelStyled>
          Criado em
          <DateStyled type="date" name="createdAt" onChange={handleChange} />
        </LabelStyled>
      </DateContainer> */}
      <ButtonStyled onClick={() => applyFilter()}>Aplicar</ButtonStyled>
    </FilterStyled>
  )
}
const OperatorListPage = ({
  list, setParams, params, loading, exportList,
}:PageProps):JSX.Element => {
  const fields :ItemField[] = [
    {
      title: 'Nome',
      key: 'serviceDate',
      size: '20%',
      renderItem: (data:OperatorProps) => data.name.toString(),
    },
    {
      title: 'Status',
      key: 'status',
      size: '10%',
      renderItem: (data:OperatorProps) => <StatusStyled color={getColorByStatusEnabled(data.enabled)}>{data.enabled ? 'Ativo' : 'Inativo'}</StatusStyled>,
    },
    // {
    //   title: 'Telefone',
    //   key: 'assigneeOperator',
    //   size: '15%',
    //   renderItem: (data:OperatorProps) => data.phoneNumber,
    // },
    {
      title: 'E-mail',
      key: 'customer',
      size: '25%',
      renderItem: (data:OperatorProps) => data.email,
    },
    {
      title: 'Progresso do cadastro(%)',
      key: 'customer',
      size: '10%',
      renderItem: (data:OperatorProps) => {
        const val = Math.round((((data?.quantityLaborDocuments || 0) + 4) / 11) * 100)
        if (val > 100) return '100%'
        return `${val}%`
      },
    },
    {
      title: 'Atividades do dia',
      key: 'rating',
      size: '20%',
      renderItem: (data:OperatorProps) => {
        const val = (data?.progressDay?.todo || 0)
         + (data?.progressDay?.completed || 0)
          + (data?.progressDay?.in_progress || 0)
        return (
          <RatingStyled>
            <ProgressStyled max={val} value={data.progressDay?.completed} />
            <p>
              {String(data.progressDay?.completed).padStart(2, '0')}
              /
              {String(val || 0)?.padStart(2, '0')}
            </p>
          </RatingStyled>
        )
      },
    },
  ]

  const handlePage = (e:any):void => {
    setParams((prev: FilterModel) => ({
      ...prev,
      page: e,
    }))
  }

  const handleSearch = (callback:any):void => {
    const val = callback()

    setParams(
      (prev: any) => ({
        ...prev,
        filters: { ...prev?.filters, name: val.query },
      }
      ),
    )
  }

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>Operadores</TitleStyled>
      </TitleContainer>
      <FilterBar
        handleFilter={handleSearch}
        filterComponent={(
          <FilterComponent
            params={params}
            submit={(values: any) => setParams((prev: any) => ({
              ...prev,
              filters: { ...prev?.filters, ...values },
            }))}
          />
)}
      >
        <ContainerButton>
          <ButtonComponentStyled to="/operators/create">
            <Icons
              name="plus"
              width={15}
              height={15}
              color={theme.colors.white}
            />
            Novo operador
          </ButtonComponentStyled>
          <ExportButtonComponentStyled onClick={async () => {
            if (exportList) {
              await exportList()
            }
          }}
          >
            Exportar
          </ExportButtonComponentStyled>
        </ContainerButton>
      </FilterBar>
      <DataGrid
        loading={!!loading}
        fields={fields}
        data={list?.content || []}
        routeToEdit="/operators/"
        totalElements={list?.totalElements || 0}
        currentPage={list?.number || 0}
        totalPages={list?.totalPages || 0}
        changePage={handlePage}
      />

    </Wrapper>
  )
}
export default OperatorListPage
