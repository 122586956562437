import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { User, UserProps } from '../../../domain/models/user'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteUser implements User {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
  ) { }

  async load(): Promise<UserProps> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async resetPassword(id: number | undefined): Promise<UserProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `/update-password/${id}`,
      method: 'put',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
