import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { CustomerResponseModel } from '../../../../../domain/models/customer'
import { FilterModel } from '../../../../../domain/models/enum'
import CustomerListPage from '../../../../../presentation/pages/customer/list'
import { settings } from '../../../../configs/settings'
import { makeRemoteAdmin } from '../../../usecases/admin/remote-admin-factory'
import { makeRemoteServiceProvider } from '../../../usecases/service-provider/remote-service-provider-factory'

const generateFilter = (values: any):string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'startedAt' || el === 'completedAt') {
      return `${el}>=${values[el]}`
    }
    if (el === 'serviceProvider') {
      return `${el}=${values[el]}`
    }
    if (el === 'headOfficeCustomer') {
      return `${el}=${values[el]}`
    }
    if (el === 'name') {
      return `${el}~${values[el]}`
    }
    if (el === 'headOffice') {
      return `${el}=${values[el]}`
    }
    return el
  }).join(',')

function MakeCustomerList(): JSX.Element {
  const { profile, user } = useAuth()
  const [list, setList] = useState<CustomerResponseModel>()
  const [params, setParams] = useState<FilterModel>({ })
  const [loading, setLoading] = useState<boolean>()

  const loadData = async (): Promise<void> => {
    setLoading(true)

    const data = await makeRemoteAdmin().loadCustomers({
      size: settings.perPage,
      page: params?.page,
      query: params?.query,
      filters: generateFilter({
        ...params?.filters,
        headOffice: true,
      }),
    })
    setList(data)
    setLoading(false)
  }

  const exportList = (): Promise<void> => makeRemoteServiceProvider().exportCSV({
    filters: generateFilter({
      ...params?.filters,
      headOffice: true,
      ...(profile?.branch?.uuid && { serviceProvider: profile?.branch?.uuid }),
    }),
  })

  useEffect(() => {
    if (user) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, profile])

  return (
    <CustomerListPage
      params={params}
      list={list}
      setParams={setParams}
      loading={loading}
      exportList={exportList}
    />
  )
}

export default MakeCustomerList
