// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { DocumentsProps } from '../../../../../domain/models/documents'
// import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
// import { t } from '../../../../../presentation/adapters/translate/translate'
import SideModalComponent from '../../../../../presentation/pages/upload-documents/internal'
import { makeRemoteDocuments } from '../../../usecases/documents/remote-documents-factory'

type ComponentProps = {
  content: DocumentsProps
  onCloseCallBack?: () => void
  refetchList: ()=> void
}
function UploadDocumentInternal({
  content,
  onCloseCallBack,
  refetchList,
}:ComponentProps): JSX.Element {
  const { handleAlert } = useAlertBox()
  const [data, setData] = useState<DocumentsProps>()
  const [reload, setReload] = useState<number>(0)

  const refetch = ():void => {
    setReload((prev) => prev + 1)
  }

  const loadData = async (): Promise<void> => {
    const contentData = await makeRemoteDocuments().loadById(content?.id)
    setData(contentData)
  }
  // eslint-disable-next-line max-len
  const downloadZipOnly = (id: number | undefined): Promise<void> => makeRemoteDocuments().downloadZip(id)

  const complete = async (id?: number): Promise<void> => {
    try {
      const contentData = await makeRemoteDocuments().completeRequest(id)
      setData({ ...contentData, status: 'COMPLETE' })
      refetchList()
      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
    } catch (error) {
      handleAlert({
        show: true,
        type: 'danger',
        message: 'Erro ao salvar',
      })
    }
  }

  useEffect(() => {
    (async () => {
      if (content?.id) {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [content?.id, reload])

  return (
    <SideModalComponent
      content={data}
      onCloseCallBack={onCloseCallBack}
      refetch={refetch}
      complete={complete}
      downloadZipOnly={downloadZipOnly}
    />
  )
}

export default UploadDocumentInternal
