export const theme = {
  breakpoints: ['576px', '768px', '992px', '1200px', '1400px'],
  grid: {
    container: '130rem',
    gutter: '3.2rem',
  },
  border: {
    radius: '16px',
    xradius: '24px',
  },
  font: {
    roboto:
      'Roboto, -apple-system, BlinkMacSystemFont, Segoe UI, Oxygen, Ubuntu, Cantarell, "Open Sans", "Helvetica Neue", sans-serif',
    montserrat: 'Montserrat, sans-serif',
    comfortaa: 'Comfortaa, cursive',
    poppins: 'poppins',
    palanquin: 'palanquin',
    light: 300,
    normal: 400,
    semiBold: 500,
    bold: 600,
    xbold: 700,
    sizes: {
      xxxsmall: '0.875rem',
      xxsmall: '1.125rem',
      xsmall: '1.2rem',
      small: '1.4rem',
      medium: '1.5rem',
      large: '1.8rem',
      xlarge: '2.2rem',
      xxlarge: '2.8rem',
      huge: '4.6rem',
    },
  },
  colors: {
    primary: '#0172CB',
    secondary: '#00995D',
    mainBg: '##F2F2F2',
    white: '#fff',
    lightGray: '#EFEFEF',
    middleGray: '#ABABAB',
    gray: '#8F8F8F',
    darkGray: '#6f6f6e',
    black: '#3A3A4D',
    red: '#EB5757',
    green: '#02C321',
    lightBlue: '#EBF2FB',
    fontBack: '#71747D',
    yellow: '#FFB801',
    purple: '#9B51E0',
  },
  transition: {
    default: '0.2s ease-in-out',
    fast: '0.1s ease-in-out',
  },
}
