import React from 'react'
import { GuardProvider } from 'react-router-guards'
import { RouteFactory } from './route-factory'

// Layouts base
import { BaseLayout, LoginLayout } from '../layouts'

// Pages
import PageNotFound from '../../pages/page-not-found'
import AlertBoxProvider from '../../../contexts/alert-box/alert_box'
import MakeSignIn from '../../../main/factories/pages/Auth/signin'
import { AuthProvider } from '../../../contexts/auth-context/auth-context'
import MakeForgotPassword from '../../../main/factories/pages/Auth/forgotPassword'
import MakeSelectBranch from '../../../main/factories/pages/select-branch'
import MakeSelectType from '../../../main/factories/pages/select-type'
import DashboardPage from '../../pages/dashboard'
import MakeTaskInternal from '../../../main/factories/pages/task/internal'
import MakeTaskList from '../../../main/factories/pages/task/list'
import MakeOperatorList from '../../../main/factories/pages/operators/list'
import MakeOperatorInternal from '../../../main/factories/pages/operators/internal'
import MakeUploadDocuments from '../../../main/factories/pages/upload-documents/list'
import MakeStock from '../../../main/factories/pages/stock/list'
import MakeProductInternal from '../../../main/factories/pages/stock/product'
import MakeStockInternal from '../../../main/factories/pages/stock/stock'
import MakeServiceProviderList from '../../../main/factories/pages/service-provider/list'
import MakeServiceProviderInternal from '../../../main/factories/pages/service-provider/internal'
import MakeRequestDocument from '../../../main/factories/pages/upload-documents/request-document'
import MakeCustomerList from '../../../main/factories/pages/customer/list'
import MakeCustomerInternal from '../../../main/factories/pages/customer/internal'
import SettingsPage from '../../pages/settings'
import MakeChangePassword from '../../../main/factories/pages/Auth/change-password'

const Routes = ():JSX.Element => {
  const routes = [
    {
      layout: LoginLayout,
      pages: [
        { path: '/login', component: MakeSignIn, exact: true },
        { path: '/forgot-pass', component: MakeForgotPassword, exact: true },
        { path: '/select-env', component: MakeSelectType, exact: true },
        { path: '/select-branch', component: MakeSelectBranch, exact: true },
      ],
    },
    {
      layout: BaseLayout,
      pages: [
        { path: '/customer/:id', component: MakeCustomerInternal, exact: true },
        { path: '/customer/create', component: MakeCustomerInternal, exact: true },
        { path: '/customer', component: MakeCustomerList, exact: true },
        { path: '/request-documents', component: MakeRequestDocument, exact: true },
        { path: '/product/:id', component: MakeProductInternal, exact: true },
        { path: '/product/create', component: MakeProductInternal, exact: true },
        { path: '/stock/create', component: MakeStockInternal, exact: true },
        { path: '/stock/:id', component: MakeStockInternal, exact: true },
        { path: '/stock/', component: MakeStock, exact: true },
        { path: '/upload-documents', component: MakeUploadDocuments, exact: true },
        { path: '/operators/:id', component: MakeOperatorInternal, exact: true },
        { path: '/operators/create', component: MakeOperatorInternal, exact: true },
        { path: '/service-provider/:id', component: MakeServiceProviderInternal, exact: true },
        { path: '/service-provider/create', component: MakeServiceProviderInternal, exact: true },
        { path: '/service-provider', component: MakeServiceProviderList, exact: true },
        { path: '/operators', component: MakeOperatorList, exact: true },
        { path: '/tasks/:id', component: MakeTaskInternal, exact: true },
        { path: '/tasks/create', component: MakeTaskInternal, exact: true },
        { path: '/tasks', component: MakeTaskList, exact: true },
        { path: '/change-password', component: MakeChangePassword, exact: true },
        { path: '/settings', component: SettingsPage, exact: true },
        { path: '/', component: DashboardPage, exact: true },
        { path: '*', component: PageNotFound, exact: true },
      ],
    },
  ]

  return (
    <GuardProvider>
      <AlertBoxProvider>
        <AuthProvider>
          <RouteFactory
            routes={routes}
          />
        </AuthProvider>
      </AlertBoxProvider>
    </GuardProvider>
  )
}

export default Routes
