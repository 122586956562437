import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const Card = styled.div`
  box-shadow:  0px 7px 50px rgba(210, 214, 227, 0.4);
  background-color: ${theme.colors.white};
  border-radius: 24px;
  width: 100%;
  cursor: pointer;
  a{
    color: unset;
  }
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  font-size: 12px;
`

export const IconStyled = styled.div`
  background-color: ${theme.colors.yellow};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;

`

export const Title = styled.h2`
  color: ${theme.colors.middleGray};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: 8px;
  letter-spacing: 1px;
  margin-top: 12px;
  margin-bottom: 15px;
  margin-bottom: 0;
`
