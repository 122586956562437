import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import FormFactory from '../../../components/form-factory'
import {
  BackgroudStyled,
  BallonDoc,
  BallonImage,
  BoxImage,
  CardForm,
  ForgotPass,
  ImageAuthStyled,
  LogoImage,
  // SubTitle,
  Title,
  Wrapper,
} from './styles'
import placeholder from '../../../assets/images/placeholder-auth.png'
import logo from '../../../assets/images/logo.png'
import { ColStyled } from '../signin/styles'

type PageProps ={
  handleSubmit: (values: any, actions: any, setStep: any) => void
}

const ForgotPassword = ({ handleSubmit }:PageProps):JSX.Element => {
  const [step, setStep] = useState<number>(0)
  const stepOne = [
    {
      groupName: 'Login',
      items: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Digite seu E-mail',
          validations: 'emailRequired',
        },
      ],
    },
  ]
  const stepTwo = [
    {
      groupName: 'Login',
      items: [
        {
          name: 'code',
          type: 'etextmail',
          placeholder: 'Digite o código recebido por E-mail',
          validations: 'inputRequired',
        },
        {
          name: 'password',
          type: 'password',
          placeholder: 'Digite sua nova senha',
          validations: 'passwordMinRequired',
        },
        {
          name: 'confirmPassword',
          type: 'password',
          placeholder: 'Digite sua nova senha novamente',
          validations: 'passwordMinRequired',
        },
      ],
    },
  ]

  return (
    <Wrapper>
      <Row>
        <Col xs={6} lg={6} className="p-0">
          <BackgroudStyled>
            <BoxImage>
              <BallonDoc />
              <ImageAuthStyled src={placeholder} />
              <BallonImage />
            </BoxImage>
          </BackgroudStyled>
        </Col>
        <ColStyled xs={6} lg={6}>
          <CardForm>
            <LogoImage src={logo} />
            <Title>
              Recupere seu acesso
            </Title>
            {/* <SubTitle>
              Você receberá uma e-mail contendo um código de verificaç
            </SubTitle> */}
            <FormFactory
              groups={(!step && stepOne) || stepTwo}
              onSubmit={async (values, actions) => {
                try {
                  await handleSubmit(values, actions, setStep)
                  actions.setSubmitting(false)
                } catch (error) {
                  actions.setSubmitting(false)
                }
              }}
              button={{ submit: 'Enviar', submitting: 'Enviando...' }}
            />
            <ForgotPass to="/login">Voltar para o login</ForgotPass>
          </CardForm>
        </ColStyled>
      </Row>

    </Wrapper>
  )
}
export default ForgotPassword
