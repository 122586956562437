import React from 'react'
import { Content, Subtitle, Wrapper } from './styles'

const PageNotFound = ():JSX.Element => (
  <Wrapper>
    <Content>
      <Subtitle>Washme</Subtitle>
      <h3>Erro: 404</h3>
      <h4>Página não encontrada.</h4>
    </Content>
  </Wrapper>
)
export default PageNotFound
