import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

type MenuItemprops = {
  selected: boolean
}

export const Wrapper = styled.div`
  height: 100%;
`
export const MenuTop = styled.div`
  display: flex;
  flex-direction: column;
`
export const ExitMenu = styled.button`
  padding: 15px 20px;
  margin-top: 15px;
  text-decoration: none;
  position: relative;
  background-color: #fff;
  justify-content: flex-start;
  display: flex;
  svg {
    margin-right: 10px;
  }

  &:hover{
    & svg path{
      fill:${theme.colors.primary}
    }
    & span{
    font-weight: bold;
    color: ${theme.colors.black};
  }}
  margin-bottom: 30px;

`
export const ItemMenu = styled(Link)`
  padding: 12px 20px;
  margin-top: 15px;
  text-decoration: none;
  position: relative;
  background: ${(p:MenuItemprops) => (p.selected ? 'linear-gradient(90deg, #EBF1FF 0%, rgba(235, 241, 255, 0.1) 100%);' : 'transparent')};
  svg {
    margin-right: 10px;
  }
  &:before{
    content:'';
    width: 3px;
    background-color: ${theme.colors.primary};
    position: absolute;
    height: 100%;
    left: 0;
    top:0;
    display: ${(p:MenuItemprops) => (p.selected ? 'block' : 'none')};
  }
  &:hover{
    & svg path{
      fill:${theme.colors.primary}
    }
    & span{
    font-weight: bold;
    color: ${theme.colors.black};
  }}

`
export const TitleMenu = styled.span`
  color: ${(p:MenuItemprops) => (p.selected ? theme.colors.black : theme.colors.fontBack)};
  font-family: 'palanquin';
  font-weight: ${(p:MenuItemprops) => (p.selected ? 600 : 300)};
  font-size: .9rem;
`
export const ContainerMenu = styled.div`
  display:flex;
  flex-direction: column;
  width: 100%;
  background-color: ${theme.colors.white};
  justify-content:space-between;
  height: 100%;
`
