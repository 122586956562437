import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { makeRemoteOperator } from '../../../../main/factories/usecases/operator/remote-operator-factory'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import {
  Avatar,
  BoxOperator,
  Card, Content, Info, Name, RatingStyled, Title,
} from './styles'

type CardProps = {
  period: string
  isHeadOffice: boolean
  serviceProvider?: string | undefined
  customer?: string | undefined
}
const TopRatings = ({
  period, isHeadOffice, serviceProvider, customer,
}:CardProps):JSX.Element => {
  const [data, setData] = useState<any[]>()
  const colors = [
    '#FCDDEC',
    '#FFE380',
    '#A2D6FF',
    '#EAFFCD',
    '#FFDAB8']
  console.log(isHeadOffice)
  useEffect(() => {
    if (customer || serviceProvider) {
      (async () => {
        const response = await makeRemoteOperator().topFiveOperators({
          period,
          isHeadOffice: isHeadOffice || false,
          serviceProvider,
          customer,
        })
        setData(response.map((el, i) => ({
          ...el,
          rating: el.customerAverageReviewGrade || 0,
          color: colors[i],
        })))
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, isHeadOffice, customer])

  return (
    <Card>
      <Content>
        <Title>
          Top 5 operadores
        </Title>
        {data?.map((el) => (
          <BoxOperator to="/" key={el.color}>
            <Info>
              <Avatar
                color={el.color}
              >
                {el.name[0]}

              </Avatar>
              <div>
                <Name>
                  {el.name}
                </Name>
                <RatingStyled>
                  <Rating
                    ratingValue={0}
                    initialValue={el.rating}
                    readonly
                    size={12}
                    allowHalfIcon
                  />
                  <p>{Number(el.rating || 0).toFixed(1)}</p>
                </RatingStyled>
              </div>
            </Info>
            <Icons name="arrow-right" width={18} height={18} color={theme.colors.middleGray} />

          </BoxOperator>
        ))}

      </Content>
    </Card>
  )
}
export default TopRatings
