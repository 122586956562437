import { Container } from 'react-bootstrap'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

type ColProps = {
  width:number
  split?: boolean
}
type SplitProps = {
  split?: boolean
}

export const ColStyledMenu = styled.div`
  width: ${(p:ColProps) => p.width}%;
  /* min-width: 215px; */
  max-height: calc(100vh - 82px);
  overflow: auto;
  padding-top: 30px;
`

export const ContainerStyled = styled(Container)`
  padding: 0;
  width: ${(p:ColProps) => (p.split ? 'calc(100% - 450px)' : '100%')} ;
`
export const ContainerBox = styled.div`
  padding: 0;
  display: flex;
  flex-direction: row;
`
export const SpaceModalStyled = styled.div`
  padding: 0;
  background-color: #FAFAFA;
  width: ${(p:SplitProps) => (p.split ? '450px' : '0')} ;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  padding: 0 50px;
  font-family: 'palanquin';
  font-weight: 600;
  color: ${theme.colors.black};
  svg{
    margin-bottom: 15px;
  }
`

export const RowStyled = styled.div`
  padding: 0;
  display: flex;
  height: calc(100vh - 80px);
`
