import React, { useEffect, useState } from 'react'
import {
  Card, Content, IconStyled, ImgStyled, Title, ValueStyled,
} from './styles'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import todo from '../../../assets/images/todo.png'
import inProgress from '../../../assets/images/in-progress.png'
import completed from '../../../assets/images/completed.png'
import { makeRemoteSummary } from '../../../../main/factories/usecases/home-screen/remote-summary-factory'
import { SummaryModel } from '../../../../domain/models/summary'
import { Roles, useAuth } from '../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../domain/models/enum'

type ComponentProps = {
  type:'TODO' | 'IN_PROGRESS' | 'COMPLETED'
  period: string
  isHeadOffice: boolean
  serviceProvider?: string | undefined
  customer?: string | undefined
}

type CardProps = {
  img:any
  color: string
  title: string
  value:number
  icon: string
  key:'toDoTasks' | 'inProgressTasks' | 'completedTasks'
} | undefined

const TasksStatus = ({
  type, period, isHeadOffice, serviceProvider, customer,
}:ComponentProps):JSX.Element => {
  let element: CardProps
  const { user } = useAuth()
  const [data, setData] = useState<SummaryModel>({
    completedTasks: 0,
    inProgressTasks: 0,
    toDoTasks: 0,
  })

  const getRequestBytypeUser = (userType: string, customParams: FilterModel):any => {
    if (userType === Roles.BRANCH_OFFICE || userType === Roles.HEAD_OFFICE) {
      return makeRemoteSummary().load(customParams)
    }
    if (userType === Roles.CUSTOMER_HEAD_OFFICE || userType === Roles.CUSTOMER_BRANCH_OFFICE) {
      return makeRemoteSummary().loadByCustomer(customParams)
    }
    return makeRemoteSummary
  }

  useEffect(() => {
    if (customer || serviceProvider) {
      (async () => {
        const response = await getRequestBytypeUser(user?.roles[0]?.authority, {
          period, isHeadOffice, serviceProvider, customer,
        })
        setData(response)
      })()
    }
  }, [period, isHeadOffice, serviceProvider, customer, user?.roles])

  switch (type) {
    case 'TODO':
      element = {
        img: todo,
        color: '#0062af',
        title: 'Tarefas a fazer',
        value: 22,
        icon: 'file-dock',
        key: 'toDoTasks',
      }
      break
    case 'IN_PROGRESS':
      element = {
        img: inProgress,
        color: '#c73f04',
        title: 'Tarefas em andamento',
        value: 22,
        icon: 'humidity',
        key: 'inProgressTasks',
      }
      break
    case 'COMPLETED':
      element = {
        img: completed,
        color: '#009f19',
        title: 'Tarefas concluídas',
        value: 22,
        icon: 'done',
        key: 'completedTasks',
      }
      break

    default:
      break
  }

  return (
    <Card>
      <ImgStyled src={element?.img} />
      <Content>
        <IconStyled background={element?.color}>
          <Icons name={element?.icon || ''} width={22} height={22} color={theme.colors.white} />
        </IconStyled>
        <Title>
          {element?.title}
        </Title>
        <ValueStyled>
          {data[element?.key || 'toDoTasks']}
        </ValueStyled>
      </Content>
    </Card>
  )
}
export default TasksStatus
