import qs from 'qs'
import fileDownload from 'react-file-download'
import axios from 'axios'
import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { FilterModel } from '../../../domain/models/enum'
import {
  BranchGoalsReponseModel,
  Task, TaskChart, TaskModel, TaskModelResponse, TaskReponseModel,
} from '../../../domain/models/task'
import { PhotosResponseModel } from '../../../domain/models/task-photo'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'
import Authentication from '../../../infra/cognito/authentication'

export class RemoteTask implements Task {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
    private readonly baseUrl: string | undefined,
  ) { }

  async load(params: FilterModel): Promise<TaskReponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}?${qs.stringify(params)}`,
      method: 'get',

    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCustomerDashboard(params: FilterModel): Promise<TaskReponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customer/task-dashboard?${qs.stringify(params)}`,
      method: 'get',

    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async customerList(params: FilterModel): Promise<TaskReponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customer/list?${qs.stringify(params)}`,
      method: 'get',

    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCustomerPendingAverage(params: FilterModel): Promise<number> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customer/count-pending-average`,
      method: 'get',
      params,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCustomerReviewAverage(params: FilterModel): Promise<number> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customer-review-average`,
      method: 'get',
      params,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async countTasksCustomer(params: FilterModel): Promise<number> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customer/count-tasks`,
      method: 'get',
      params,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadBranchGoals(params: FilterModel): Promise<BranchGoalsReponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/branch-goals`,
      method: 'get',
      params,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async exportCSV(params: FilterModel): Promise<void> {
    const token = await Authentication.getToken()
    const resp = await axios.get(`${this.baseUrl}/tasks/export`, {
      responseType: 'blob',
      params,
      headers: {
        Authorization: token,
        accept: '*/*',
      },

    })
    fileDownload(resp.data, `Atividades-${new Date().getTime()}.xlsx`)
  }

  async customerTaskChart(params: FilterModel): Promise<TaskChart> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/customer/task-chart`,
      method: 'get',
      params,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadTaskChart(params: FilterModel): Promise<TaskChart> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/task-chart`,
      method: 'get',
      params,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadById(id: number): Promise<TaskModelResponse> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'get',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async delete(id: number): Promise<TaskModelResponse> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'delete',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadPhotos(id: number, params?: FilterModel): Promise<PhotosResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}/photos`,
      method: 'get',
      params,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async create(body: TaskModel): Promise<TaskModelResponse> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'post',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async update(id: number, body: TaskModel): Promise<TaskModelResponse> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async start(id: number): Promise<TaskModelResponse> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}/start`,
      method: 'post',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async complete(id: number): Promise<TaskModelResponse> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}/complete`,
      method: 'post',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
