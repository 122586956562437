import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { makeRemoteAdmin } from '../../../../main/factories/usecases/admin/remote-admin-factory'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import {
  Avatar,
  BoxOperator,
  Card, Content, Info, Name, RatingStyled, Title,
} from './styles'

type CardProps = {
  period: string
}
const TopRatingsServiceProviders = ({
  period,
}:CardProps):JSX.Element => {
  const [data, setData] = useState<any[]>()
  const colors = [
    '#FCDDEC',
    '#FFE380',
    '#A2D6FF',
    '#EAFFCD',
    '#FFDAB8']

  useEffect(() => {
    (async () => {
      const response = await makeRemoteAdmin().topFiveServiceProviders({
        period,
      })
      setData(response.map((el, i) => ({
        ...el,
        rating: el.customerAverageReviewGrade || 0,
        color: colors[i],
      })))
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period])

  return (
    <Card>
      <Content>
        <Title>
          Top 5 Prestadores de Serviço
        </Title>
        {data?.map((el) => (
          <BoxOperator to="/" key={el.color}>
            <Info>
              <Avatar
                color={el.color}
              >
                {el.name[0]}

              </Avatar>
              <div>
                <Name>
                  {el.name}
                </Name>
                <RatingStyled>
                  <Rating
                    ratingValue={0}
                    initialValue={el.rating}
                    readonly
                    size={12}
                    allowHalfIcon
                  />
                  <p>{Number(el.rating || 0).toFixed(1)}</p>
                </RatingStyled>
              </div>
            </Info>
            <Icons name="arrow-right" width={18} height={18} color={theme.colors.middleGray} />

          </BoxOperator>
        ))}

      </Content>
    </Card>
  )
}
export default TopRatingsServiceProviders
