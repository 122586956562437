import React, { useEffect, useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { theme } from '../../styles/theme'
import Icons from '../icons-helper'
import { DropdownStyled, OptionStyled, SelectStyled } from './styled'

type SelectProps = {
  name:string
  options: any[]
  defaultValue?:any
  placeholder:string
  labelKey: string
  valueKey: string
  onChange?: (e:any, name?: any) => void
  onBlur?: (e:any) => void
  setFieldValue: (n:string, o:any)=> void
  disabled?: boolean
}

const SelectComponent = ({
  name,
  options,
  placeholder,
  labelKey,
  valueKey,
  onChange,
  onBlur,
  setFieldValue,
  defaultValue,
  disabled,
}:SelectProps):JSX.Element => {
  const [open, setOpen] = useState<boolean>(false)
  const [selected, setSelected] = useState<any>(defaultValue || false)

  useEffect(() => {
    const find = options?.find((el) => el[valueKey] === defaultValue)
    if (defaultValue && find) {
      setTimeout(() => {
        setSelected(find)
        setFieldValue(name, find)
        if (onChange) {
          onChange(name)
        }
      }, 100)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValue, JSON.stringify(options)])

  const closeDropdown = ():void => {
    if (open) {
      setOpen((prev) => !prev)
    }
  }

  const ref = useDetectClickOutside({ onTriggered: closeDropdown })

  return (
    <SelectStyled disabled={disabled} onClick={() => !disabled && setOpen((prev) => !prev)} className="select-input" ref={ref}>
      {selected[labelKey] || placeholder}
      <Icons name="arrow-down" width={20} height={20} color={theme.colors.black} />
      <DropdownStyled open={open} onBlur={onBlur}>
        {options?.map((opt) => (
          <OptionStyled
            type="button"
            key={`${opt[labelKey]}-${opt[valueKey]}`}
            onClick={() => {
              setFieldValue(name, opt)
              setSelected(opt)
              if (onChange) {
                onChange(opt, name)
              }
            }}
          >
            {opt[labelKey]}
          </OptionStyled>
        ))}
      </DropdownStyled>

    </SelectStyled>
  )
}
export default SelectComponent
