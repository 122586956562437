import React, { useEffect, useState } from 'react'
import { RiEyeFill, RiEyeOffFill } from 'react-icons/ri'
import { theme } from '../../styles/theme'
import Icons from '../icons-helper'
import SelectComponent from '../select'
import {
  ButtonInput,
  Checkbox,
  CheckboxStyled,
  CheckboxToggle,
  DownloadButton,
  ErrorStyled,
  FieldSeclectStyled,
  FieldStyled,
  FileContainer,
  FileInfo,
  FileStyled, InpuImasktStyled, InputStyled, LabelRadioStyled,
  LabelStyled,
  NumberInputContainer,
  RadioBox,
  RadioStyled,
  ToogleButtonStyled,
  ToogleStyled, ViewPass,
} from './styled'

const MakeField = ({
  field,
  errors,
  handleChange,
  handleBlur,
  setFieldValue,
}: any): JSX.Element => {
  const [viewPass, setViewPass] = useState<boolean>(false)
  const [currentValue, setCurrentValue] = useState<any>()

  useEffect(() => {
    if (field.defaultValue && field?.type !== 'select') {
      setTimeout(() => {
        setFieldValue(field.name, field.defaultValue)
      }, 50)
    }

    if (field.defaultValue) {
      setCurrentValue(field?.defaultValue)
    }

  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [field.defaultValue, field.name, setFieldValue, field.disabled])

  if (field?.type === 'file') {
    return (
      <FileContainer>
        <CheckboxStyled key={field.name}>
          {field.label
              && (
              <LabelStyled>
                {field.label}
                <input
                  accept={field?.accept}
                  name={field.name}
                  type={field.type}
                  hidden
                  disabled={field.disabled}
                  placeholder={field.placeholder}
                  onBlur={handleBlur}
                  onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                    const file = (!!e?.target?.files && e?.target?.files[0]) || null
                    setFieldValue(`${field.name}`, file)
                    handleChange(field.name)
                    setCurrentValue(!!e?.target?.files && e?.target?.files[0])
                    const reader = new FileReader()
                    if (file) {
                      reader.readAsDataURL(file)
                      reader.onload = () => {
                        setFieldValue(`${field.name}_file`, reader.result)
                      }
                      reader.onerror = (error) => {
                        console.error('Error: ', error)
                      }
                    }
                    if (field.onChange) {
                      field.onChange(file)
                    }
                  }}
                />
                <FileStyled>
                  <Icons
                    name={field?.icon || 'file-pdf'}
                    width={20}
                    height={20}
                    color={theme.colors.darkGray}
                  />
                  <FileInfo>
                    <p>{currentValue?.name || (typeof currentValue === 'string' ? 'Trocar arquivo' : field?.placeholder)}</p>
                    {currentValue && (
                      <>
                        {currentValue?.size && (
                        <span>
                          {(currentValue?.size / 1000).toFixed(2)}
                          KB
                        </span>
                        )}
                      </>
                    )}

                  </FileInfo>

                  {typeof currentValue === 'string' && (
                  <DownloadButton target="_blank" href={currentValue}>
                    <Icons
                      name="download"
                      width={20}
                      height={20}
                      color={theme.colors.darkGray}
                    />
                  </DownloadButton>
                  )}
                </FileStyled>
              </LabelStyled>
              )}
        </CheckboxStyled>
        {errors[field.name] && <ErrorStyled className="error-field-file">{errors[field.name]}</ErrorStyled>}
      </FileContainer>
    )
  }
  if (field?.type === 'select') {
    return (
      <FieldSeclectStyled>
        {field.label
          && <LabelStyled>{field.label}</LabelStyled>}
        <SelectComponent
          name={field.name}
          disabled={field.disabled}
          options={field?.options}
          placeholder={field?.placeholder}
          labelKey={field?.labelKey || 'label'}
          valueKey={field?.valueKey || 'value'}
          defaultValue={currentValue}
          setFieldValue={setFieldValue}
          onChange={(e:any, name:any) => {
            handleChange(name)
            if (field?.onChange) {
              field?.onChange(e)
            }
          }}
        />
        {errors[field.name] && <ErrorStyled className="error-field">{errors[field.name]}</ErrorStyled>}

      </FieldSeclectStyled>
    )
  }
  if (field?.type === 'component') {
    return (
      field.component
    )
  }
  if (field?.type === 'radio') {
    return (
      <RadioStyled>
        {field?.options?.map((el: any): JSX.Element => (
          <React.Fragment key={el.label}>
            {el.label
             && (
               <LabelRadioStyled>
                 <RadioBox
                   name={field.name}
                   value={el.value}
                   type={field.type}
                   disabled={field.disabled}
                   placeholder={field.placeholder}
                   onBlur={handleBlur}
                   onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
                     handleChange(field.name)
                     setCurrentValue(e.target.value)
                     setFieldValue(field.name, e.target.value)
                     if (field.onChange) {
                       field.onChange(e.target.value)
                     }
                   }}
                 />
                 {el.label}
               </LabelRadioStyled>
             )}
          </React.Fragment>
        ))}
        {errors[field.name] && <ErrorStyled className="error-field">{errors[field.name]}</ErrorStyled>}

      </RadioStyled>
    )
  }

  if (field?.type === 'checkbox') {
    return (
      <CheckboxStyled>
        <Checkbox
          name={field.name}
          checked={currentValue}
          type={field.type}
          placeholder={field.placeholder}
          onBlur={handleBlur}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
            handleChange(field.name)
            setCurrentValue(e.target.checked)
            setFieldValue(field.name, e.target.checked)
            if (field.onChange) {
              field.onChange(e.target.value)
            }
          }}
        />
        {field.label
      && <LabelStyled>{field.label}</LabelStyled>}
        {errors[field.name] && <ErrorStyled>{errors[field.name]}</ErrorStyled>}
      </CheckboxStyled>
    )
  }

  if (field?.type === 'yesno') {
    return (
      <CheckboxStyled key={field.name}>
        {field.label
      && (
      <LabelStyled>
        {field.label}
        <div className="d-flex flex-row align-items-center mt-3">

          <Checkbox
            name={field.name}
            checked={currentValue}
            type="checkbox"
            disabled={field.disabled}
            placeholder={field.placeholder}
            onBlur={handleBlur}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
              handleChange(field.name)
              setCurrentValue(e.target.checked)
              setFieldValue(field.name, e.target.checked)
              if (field.onChange) {
                field.onChange(e.target.value)
              }
            }}
          />
          <span className="ms-2 me-3">
            Sim
          </span>

          <Checkbox
            name={field.name}
            checked={!currentValue}
            type="checkbox"
            disabled={field.disabled}
            placeholder={field.placeholder}
            onBlur={handleBlur}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
              handleChange(field.name)
              setCurrentValue(!e.target.checked)
              setFieldValue(field.name, !e.target.checked)
              if (field.onChange) {
                field.onChange(e.target.value)
              }
            }}
          />
          <span className="ms-2">
            Não
          </span>
        </div>
      </LabelStyled>
      )}
        {errors[field.name] && <ErrorStyled className="error-field-yesno">{errors[field.name]}</ErrorStyled>}
      </CheckboxStyled>
    )
  }

  if (field?.type === 'number') {
    return (
      <FieldStyled>
        {field.label
      && <LabelStyled>{field.label}</LabelStyled>}
        <NumberInputContainer disabled={field.disabled}>
          <ButtonInput
            type="button"
            onClick={() => {
              if (!field.disabled) {
                const t = (currentValue > 0 && currentValue - 1) || 0
                setCurrentValue(t)
                setFieldValue(field.name, t)
              }
            }}
          >
            -
          </ButtonInput>
          <InputStyled
            value={currentValue}
            name={field.name}
            disabled={field.disabled}
            type="number"
            min={0}
            step={1}
            placeholder={field.placeholder}
            onBlur={handleBlur}
            onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
              handleChange(field.name)
              setCurrentValue(e.target.value)
              setFieldValue(field.name, e.target.value)
              if (field.onChange) {
                field.onChange(e.target.value)
              }
            }}
          />
          <ButtonInput
            type="button"
            onClick={() => {
              if (!field.disabled) {
                const t = currentValue + 1 || 0
                setCurrentValue(t)
                setFieldValue(field.name, t)
              }
            }}
          >
            +
          </ButtonInput>
        </NumberInputContainer>
        {errors[field.name] && <ErrorStyled className="error-field">{errors[field.name]}</ErrorStyled>}
      </FieldStyled>

    )
  }

  if (field?.type === 'toggle') {
    return (
      <ToogleStyled key={field.name}>
        {field.label
      && (
      <LabelStyled>
        {field.label}
        <CheckboxToggle
          name={field.name}
          checked={currentValue}
          type="checkbox"
          hidden
          disabled={field.disabled}
          placeholder={field.placeholder}
          onBlur={handleBlur}
          onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
            handleChange(field.name)
            setCurrentValue(e.target.checked)
            setFieldValue(field.name, e.target.checked)
            if (field.onChange) {
              field.onChange(e.target.value)
            }
          }}
        />
        <div className="d-flex flex-row align-items-center mt-3">
          <ToogleButtonStyled className="toggle" />
          <span className="ms-2">
            {currentValue ? 'Ativo' : 'Inativo'}
          </span>
        </div>

      </LabelStyled>
      )}
        {errors[field.name] && <ErrorStyled className="error-field-toggle">{errors[field.name]}</ErrorStyled>}
      </ToogleStyled>
    )
  }

  if (field?.type === 'currency') {
    return (
      <FieldStyled>
        {field.label
      && <LabelStyled>{field.label}</LabelStyled>}
        <InpuImasktStyled
          mask={Number}
          radix=","
          thousandsSeparator="."
          prefix="R$"
          value={currentValue?.toString()}
          placeholder={field.placeholder}
          type="text"
          disabled={field.disabled}
          onAccept={
            (value: any) => {
              handleChange(field.name)
              setCurrentValue(value)
              setFieldValue(field.name, value)
              if (field.onChange) {
                field.onChange(value)
              }
            }
          }
        />

        {errors[field.name] && <ErrorStyled className="error-field">{errors[field.name]}</ErrorStyled>}
      </FieldStyled>
    )
  }

  if (field.mask) {
    return (
      <FieldStyled>
        {field.label
      && <LabelStyled>{field.label}</LabelStyled>}
        <InpuImasktStyled
          mask={field.mask}
          value={currentValue?.toUpperCase()}
          placeholder={field.placeholder}
          type={viewPass ? 'text' : field.type}
          disabled={field.disabled}
          onAccept={
            (value: any) => {
              handleChange(field.name)
              setCurrentValue(value)
              setFieldValue(field.name, value)
              if (field.onChange) {
                field.onChange(value)
              }
            }
          }
        />

        {errors[field.name] && <ErrorStyled className="error-field">{errors[field.name]}</ErrorStyled>}
      </FieldStyled>
    )
  }

  return (
    <FieldStyled>
      {field.type === 'password'
       && (
       <ViewPass
         onClick={() => setViewPass((prev) => !prev)}
       >
         {viewPass
           ? <RiEyeOffFill color={theme.colors.darkGray} />
           : <RiEyeFill color={theme.colors.darkGray} />}
       </ViewPass>
       )}
      {field.label
      && <LabelStyled>{field.label}</LabelStyled>}
      <InputStyled
        value={currentValue}
        name={field.name}
        disabled={field.disabled}
        type={viewPass ? 'text' : field.type}
        placeholder={field.placeholder}
        onBlur={handleBlur}
        onChange={(e:React.ChangeEvent<HTMLInputElement>) => {
          handleChange(field.name)
          setCurrentValue(e.target.value)
          setFieldValue(field.name, e.target.value)
          if (field.onChange) {
            field.onChange(e.target.value)
          }
        }}
      />
      {errors[field.name] && <ErrorStyled className="error-field">{errors[field.name]}</ErrorStyled>}
    </FieldStyled>

  )
}
export default MakeField
