import React, { useEffect } from 'react'
import { Formik, FormikProps } from 'formik'
import { Col, Row } from 'react-bootstrap'
import ButtonComponent from '../button'
import {
  ContainerForm, ContentFields, FormikStyled, LabelGroupStyled,
} from './styled'

import { getInitialValues, getValidationSchema, GroupProp } from './utils/util'
import MakeField from './MakeField'
import { theme } from '../../styles/theme'

type FormProps = {
  groups: GroupProp[]
  button: { submit: string,
    submitting: string,
    hide?: boolean,
    backgroundColor?: string,
    color?: string
  }
  onSubmit: (values: any, actions: any) => void
  className?: string
}

function FormFactory({
  groups, button, onSubmit, className,
}: FormProps): JSX.Element {
  const validationSchema = getValidationSchema(groups)
  const initialValues = getInitialValues(groups)

  useEffect(() => {
    console.log('Init Form')
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [(groups)])

  return (
    <Formik
      validationSchema={validationSchema}
      initialValues={initialValues}
      onSubmit={(values, actions) => { onSubmit(values, actions) }}
    >
      {({
        handleChange,
        handleBlur,
        // handleSubmit,
        setFieldValue,
        errors,
        isValid,
        // values,
        isSubmitting,
      }: FormikProps<any>) => (
        <FormikStyled className={className}>
          <Row className="w-100 h-auto m-0">
            {groups.map((group) => (
              <React.Fragment key={group.groupName}>
                {group.groupLabel && <LabelGroupStyled>{group.groupLabel}</LabelGroupStyled>}
                <ContainerForm key={group.groupName} className="container-form row">
                  {group.items.map((field) => (!field?.hide
                    && (
                    <Col
                      xs={field?.col?.xs || 12}
                      md={field?.col?.md || 12}
                      lg={field?.col?.lg || 12}
                      key={field.name}
                    >
                      <ContentFields>
                        <MakeField
                          field={field}
                          errors={errors}
                          handleChange={handleChange}
                          handleBlur={handleBlur}
                          setFieldValue={setFieldValue}
                        />
                      </ContentFields>
                    </Col>
                    )
                  ))}
                </ContainerForm>
              </React.Fragment>
            ))}
            {console.log(isValid)}
          </Row>
          {!button?.hide && (
            <ButtonComponent
              type="submit"
              title={isSubmitting ? button.submitting || 'Enviando...' : button.submit || 'Enviar'}
              color={button?.color || theme.colors.white}
              backgroundColor={button?.backgroundColor || theme.colors.primary}
              disabled={isSubmitting || !isValid}
            />
          )}
        </FormikStyled>
      )}
    </Formik>
  )
}

export default FormFactory
