import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../../domain/models/enum'
import { ProductStockReponseModel } from '../../../../../domain/models/stock'
import StockListPage from '../../../../../presentation/pages/stock/list'
import { settings } from '../../../../configs/settings'
import { makeRemoteProductStock } from '../../../usecases/product-stock/remote-product-stock'

function MakeStock(): JSX.Element {
  const [list, setList] = useState<ProductStockReponseModel>()
  const [params, setParams] = useState<FilterModel>()
  const [loading, setLoading] = useState<boolean>()
  const { profile } = useAuth()

  const generateFilter = (values: any):string => Object.keys(values)
    .filter((el) => !!values[el]).map((el) => {
      if (el === 'operator') {
        return `${el}=${values[el]}`
      }
      if (el === 'name') {
        return `${el}~${values[el]}`
      }
      if (el === 'acabou' || el === 'disponivel' || el === 'acabando') {
        return `${values[el]}`
      }
      if (el === 'serviceProvider') {
        return `${el}=${values[el]}`
      }
      return el
    }).join(',')

  const loadData = async (): Promise<void> => {
    try {
      setLoading(true)
      const data = await makeRemoteProductStock().load({
        page: params?.page,
        filters: generateFilter({
          ...params?.filters,
          ...(profile?.branch?.uuid && { serviceProvider: profile?.branch?.uuid }),
        }),
        size: settings.perPage,
      })
      setList(data)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error(error)
    }
  }

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params])

  return (
    <StockListPage list={list} setParams={setParams} loading={loading} />
  )
}

export default MakeStock
