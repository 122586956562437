/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { Roles, useAuth } from '../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../domain/models/enum'
import { ServiceProviderProps, ServiceProviderResponseModel } from '../../../../domain/models/service-provider'
import DataGrid, { ItemField } from '../../../components/datagrid'
import FilterBar from '../../../components/filter-bar'
import Icons from '../../../components/icons-helper'
import { checkIsCustomer, getColorByStatusEnabled, isAdmin } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { ExportButtonComponentStyled } from '../../task/task-list/styles'
import {
  ButtonComponentStyled,
  ButtonStyled,
  CheckboxStyled,
  ContainerButton,
  FieldCheckbox,
  FilterStyled,
  ProgressStyled,
  RatingStyled, SperatorStyled,
  StatusStyled, TitleContainer,
  TitleStyled, TitleStyledFilter, Wrapper,
} from './styles'

type PageProps = {
  list: ServiceProviderResponseModel | undefined
  setParams: any
  params: any
  loading?:boolean
  exportList?: ()=> void

}

type FilterComponentProps = {
  submit:(value:string)=>void
  params: any
}

const FilterComponent = ({ submit, params }:FilterComponentProps):JSX.Element => {
  const [values, setValues] = useState<any>({ ...params })

  const handleChange = (e:any):void => {
    setValues(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }

  const applyFilter = (): void => {
    submit(values)
  }

  return (
    <FilterStyled>
      <SperatorStyled />
      <TitleStyledFilter>
        Status
      </TitleStyledFilter>
      <FieldCheckbox>
        <CheckboxStyled
          name="enabled=false"
          value="false"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Inativo
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="true"
          name="enabled=true"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Ativo
      </FieldCheckbox>
      {/* <SperatorStyled />
      <TitleStyledFilter>
        Data
      </TitleStyledFilter>
      <DateContainer>

        <LabelStyled>
          Criado em
          <DateStyled type="date" name="createdAt" onChange={handleChange} />
        </LabelStyled>
      </DateContainer> */}
      <ButtonStyled onClick={() => applyFilter()}>Aplicar</ButtonStyled>
    </FilterStyled>
  )
}
const ServiceProviderListPage = ({
  list, setParams, params, loading, exportList,
}:PageProps):JSX.Element => {
  const { user } = useAuth()
  const fields :ItemField[] = [
    { title: (isAdmin() ? 'Matriz' : 'Filial'), key: 'name', renderItem: (data:ServiceProviderProps) => data.name },
    {
      title: 'Avaliação do cliente',
      key: 'rating',
      hide: checkIsCustomer(),
      renderItem: (data:ServiceProviderProps) => (
        <RatingStyled>
          <Icons
            name="star-fill"
            width={20}
            height={20}
            color={theme.colors.yellow}
          />
          <p>
            {Number(data?.customerAverageReviewGrade || 0).toFixed(1)}
          </p>
        </RatingStyled>
      ),
    },
    { title: 'Status', key: 'status', renderItem: (data:ServiceProviderProps) => <StatusStyled color={getColorByStatusEnabled(!!data.enabled)}>{data.enabled ? 'Ativo' : 'Inativo'}</StatusStyled> },
    {
      title: 'Responsável',
      key: 'status',
      hide: !checkIsCustomer(),
      renderItem: (data:ServiceProviderProps) => data.user?.name,
    },
    {
      title: 'Tarefas a fazer',
      key: 'taskToDo',
      hide: !checkIsCustomer(),
      renderItem: (data:ServiceProviderProps) => data.taskToDo,
    },
    {
      title: 'Tarefas em andamento',
      key: 'taskInProgress',
      hide: !checkIsCustomer(),
      renderItem: (data:ServiceProviderProps) => data.taskInProgress,
    },
    {
      title: 'Tarefas concluídas',
      key: 'taskCompleted',
      hide: !checkIsCustomer(),
      renderItem: (data:ServiceProviderProps) => data.taskCompleted,
    },
    {
      title: 'Tarefas a avaliar',
      key: 'taskToBeEvaluated',
      hide: !checkIsCustomer(),
      renderItem: (data:ServiceProviderProps) => data.taskToBeEvaluated,
    },

    {
      title: 'Operadores', hide: checkIsCustomer() || user?.roles[0]?.authority === Roles.ADMIN, key: 'user', renderItem: (data:ServiceProviderProps) => data?.operatorsCount || 0,
    },
    {
      title: 'Filiais', hide: user?.roles[0]?.authority !== Roles.ADMIN, key: 'user', renderItem: (data:ServiceProviderProps) => `${data?.branchOfficeNames?.substring(0, 35)}...` || '--',
    },
    {
      title: 'Tarefas do dia',
      key: 'email',
      hide: checkIsCustomer(),
      renderItem: (data:ServiceProviderProps) => (
        <RatingStyled>
          <ProgressStyled max={data.progressDay?.todo} value={data.progressDay?.completed} />
          <p>
            {String(data.progressDay?.completed || 0).padStart(2, '0')}
            /
            {String(data.progressDay?.todo || 0).padStart(2, '0')}
          </p>
        </RatingStyled>
      ),
    },
  ]

  const handlePage = (e:any):void => {
    setParams((prev: FilterModel) => ({
      ...prev,
      page: e,
    }))
  }

  const handleSearch = (callback:any):void => {
    const val = callback()

    setParams(
      (prev: any) => ({
        ...prev,
        filters: { ...prev?.filters, name: val.query },
      }
      ),
    )
  }

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>
          {isAdmin() ? 'Matrizes' : 'Filiais'}
        </TitleStyled>
      </TitleContainer>
      <FilterBar
        handleFilter={handleSearch}
        filterComponent={(
          <FilterComponent
            params={params}
            submit={(values: any) => setParams((prev: any) => ({
              ...prev,
              filters: { ...prev?.filters, ...values },
            }))}
          />
)}
      >
        <ContainerButton>
          <ButtonComponentStyled to="/service-provider/create">
            <Icons
              name="plus"
              width={15}
              height={15}
              color={theme.colors.white}
            />
            {(isAdmin() ? 'Nova Matriz' : 'Nova Filial')}
          </ButtonComponentStyled>
          <ExportButtonComponentStyled onClick={async () => {
            if (exportList) {
              await exportList()
            }
          }}
          >
            Exportar
          </ExportButtonComponentStyled>
        </ContainerButton>
      </FilterBar>
      <DataGrid
        loading={!!loading}
        fields={fields}
        data={list?.content || []}
        routeToEdit="/service-provider/"
        totalElements={list?.totalElements || 0}
        currentPage={list?.number || 0}
        totalPages={list?.totalPages || 0}
        changePage={handlePage}
      />

    </Wrapper>
  )
}
export default ServiceProviderListPage
