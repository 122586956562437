import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { Rating } from 'react-simple-star-rating'
import { useAlertBox } from '../../../../contexts/alert-box/alert_box'
import { OptionProps } from '../../../../domain/models/option-prop'
import { ServiceProviderProps } from '../../../../domain/models/service-provider'
import { TaskModelResponse } from '../../../../domain/models/task'
import { PhotosResponseModel } from '../../../../domain/models/task-photo'
import { makeRemoteTask } from '../../../../main/factories/usecases/task/remote-task-factory'
import { DialogModal } from '../../../components/dialog-modal'
import FormFactory from '../../../components/form-factory'
import Icons from '../../../components/icons-helper'
import { checkIsCustomer } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { RatingStyled } from '../task-list/styles'
import {
  ActionBox,
  ActionButton,
  BackButton,
  ContainerData,
  ContainerForm,
  PhotoContainer,
  PhotoContainerRow,
  PhotoContainerType,
  PhotoFullStyled,
  PhotoStyled,
  Subtitle,
  TitleContainer, TitleStyled, TopContainer, Wrapper,
} from './styles'

type PageProps = {
  data: TaskModelResponse | undefined
  vehicleTypeList: OptionProps[]
  assigneeOperatorList: OptionProps[]
  customerList: OptionProps[]
  serviceTypes: OptionProps[]
  photos: PhotosResponseModel | undefined
  serviceProviderList: ServiceProviderProps[] | undefined
  handleSubmit: (values: any, actions: any) => void
}

const TaskInternalPage = ({
  data,
  vehicleTypeList,
  customerList,
  assigneeOperatorList,
  serviceTypes,
  handleSubmit,
  photos, serviceProviderList,
}:PageProps):JSX.Element => {
  const [editMode, setEditMode] = useState<boolean>(false)
  const [open, setOpen] = useState<boolean>(false)
  const [show, setShow] = useState<string | null>()
  const { handleAlert } = useAlertBox()
  const history = useHistory()

  useEffect(() => {
    if (data?.id) {
      setEditMode(true)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.stringify(data)])

  const makeFields = (values:any):any[] => [
    {
      groupName: 'Dados da tarefa',
      groupLabel: 'Dados da tarefa',
      items: [
        {
          name: 'customer',
          type: 'select',
          label: 'Para quem será realizada',
          disabled: editMode,
          hide: !serviceProviderList,
          defaultValue: values?.serviceProvider?.uuid,
          placeholder: 'Selcione a filial',
          validations: serviceProviderList && 'objectRequired',
          options: serviceProviderList,
          labelKey: 'name',
          valueKey: 'uuid',
          col: {
            md: 3,
            lg: 3,
          },
        },
        {
          name: 'assigneeOperator',
          type: 'select',
          label: 'Responsável',
          hide: checkIsCustomer(),
          disabled: editMode,
          defaultValue: values?.assigneeOperator?.id,
          placeholder: 'Selcione operador',
          validations: !checkIsCustomer() && 'objectRequired',
          options: assigneeOperatorList,
          labelKey: 'label',
          valueKey: 'value',
          col: {
            md: 3,
            lg: 3,
          },
        },
        {
          name: 'vehicleType',
          type: 'select',
          label: 'Automóvel',
          disabled: editMode,
          defaultValue: values?.vehicleType?.id,
          placeholder: 'Selcione o tipo de veículo',
          validations: 'objectRequired',
          options: vehicleTypeList,
          labelKey: 'label',
          valueKey: 'value',
          col: {
            md: 3,
            lg: 3,
          },
        },
        {
          name: 'serviceType',
          type: 'select',
          label: 'Tipo de Serviço',
          disabled: editMode,
          defaultValue: values?.serviceType?.id,
          placeholder: 'Selcione o tipo de serviço',
          validations: 'objectRequired',
          options: serviceTypes,
          labelKey: 'label',
          valueKey: 'value',
          col: {
            md: 3,
            lg: 3,
          },
        },
        {
          name: 'bayNumber',
          type: 'select',
          label: 'Baia',
          disabled: editMode,
          hide: checkIsCustomer(),
          defaultValue: values?.bayNumber?.toString(),
          placeholder: 'Selcione a baia',
          options: [4, 6, 8, 10, 12].map((i) => ({
            label: `${i}`,
            value: `${i}`,
          })),
          labelKey: 'label',
          valueKey: 'value',
          col: {
            md: 3,
            lg: 3,
          },
        },
        {
          name: 'licensePlate',
          type: 'text',
          label: 'Placa',
          disabled: editMode,
          defaultValue: values?.licensePlate,
          placeholder: 'Digite a Placa do veículo',
          validations: 'inputRequired',
          mask: 'aaa-0*00',
          col: {
            md: 3,
            lg: 3,
          },
        },
        // {
        //   name: 'serviceDate',
        //   type: 'date',
        //   label: 'Data do serviço',
        //   disabled: editMode,
        //   defaultValue: values?.serviceDate,
        //   placeholder: 'Digite a data do serviço',
        //   validations: 'inputRequired',
        //   col: {
        //     md: 3,
        //     lg: 3,
        //   },
        // },
        {
          name: 'customer',
          type: 'select',
          label: 'Cliente',
          disabled: editMode,
          hide: checkIsCustomer(),
          defaultValue: values?.customer?.id,
          placeholder: 'Selecione o cliente',
          validations: !checkIsCustomer() && 'objectRequired',
          labelKey: 'label',
          valueKey: 'value',
          options: customerList?.map((el:any) => ({ label: el?.label, value: el?.value })),
          col: {
            md: 3,
            lg: 3,
          },
        },
      ],
    },
  ]
  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>
          Tarefas
        </TitleStyled>
      </TitleContainer>
      <ContainerForm>
        <TopContainer>
          <BackButton to="/tasks">
            <Icons
              name="arrow-left"
              width={12}
              height={12}
              color={theme.colors.black}
            />
            Voltar
          </BackButton>
          {data?.id && (
          <>
            {!checkIsCustomer() && (
            <ActionBox>
              <ActionButton onClick={() => setEditMode((prev) => !prev)}>
                <Icons
                  name={!editMode ? 'close' : 'substract'}
                  width={12}
                  height={12}
                  color={theme.colors.primary}
                />
              </ActionButton>
              <ActionButton onClick={() => setOpen(true)}>
                <Icons
                  name="trash"
                  width={12}
                  height={12}
                  color={theme.colors.red}
                />
              </ActionButton>
            </ActionBox>
            )}
          </>
          )}
        </TopContainer>

        <ContainerData>
          <FormFactory
            className={`internal-form mb-3 ${editMode && 'editMode'}`}
            groups={makeFields(data)}
            button={{ submit: 'Salvar', submitting: 'Salvando...' }}
            onSubmit={async (values:any, actions:any) => {
              await handleSubmit(values, actions)
              setEditMode(false)
            }}
          />
          {data && (
          <>
            {!!photos?.content.length && (
            <>
              <Subtitle>
                Fotos
              </Subtitle>
              <PhotoContainerRow>

                <PhotoContainerType>
                  Antes
                  <PhotoContainer>
                    {photos?.content?.map((pht) => <PhotoStyled onClick={() => setShow(pht?.photoUrlBefore)} src={pht?.photoUrlBefore || ''} />)}
                  </PhotoContainer>
                </PhotoContainerType>
                <PhotoContainerType>
                  Depois
                  <PhotoContainer>
                    {photos?.content?.map((pht) => <PhotoStyled onClick={() => setShow(pht?.photoUrlAfter)} src={pht?.photoUrlAfter || ''} />)}
                  </PhotoContainer>
                </PhotoContainerType>
              </PhotoContainerRow>
            </>
            )}
            <Subtitle>
              {checkIsCustomer() ? 'Avalie essa atividade aqui' : 'Avaliação do cliente'}
            </Subtitle>
            <RatingStyled>
              <Rating
                onClick={async (e:any) => {
                  await makeRemoteTask().update(data?.id || 0, {
                    ...data,
                    customerReviewGrade: Number(((5 * e) / 100).toFixed(1)),
                  })
                }}
                transition
                showTooltip
                ratingValue={0}
                initialValue={data?.customerReviewGrade || 0}
                readonly={!checkIsCustomer()}
                size={25}
                tooltipDefaultText="0"
                tooltipStyle={{
                  backgroundColor: 'transparent',
                  color: theme.colors.darkGray,
                  fontFamily: 'poppins',
                }}
                allowHalfIcon
              />
            </RatingStyled>
          </>
          )}
        </ContainerData>
        {data?.id && (
        <DialogModal
          title="Excluir Tarefa"
          message="Tem certeza que deseja excluir essa Tarefa?"
          buttons={{
            confirm: 'Continuar',
            cancel: 'Cancelar',
          }}
          open={open}
          cancelCallback={() => setOpen(false)}
          confirmCallback={async () => {
            try {
              await makeRemoteTask().delete(data?.id || 0)
              setOpen(false)
              history.push('/tasks')
            } catch (error) {
              console.error(error)
              handleAlert({
                show: true,
                type: 'danger',
                message: 'Essa Tarefa não pode ser excluída!',
              })
            }
          }}
        />
        )}
        <Modal show={!!show} onHide={() => setShow(null)}>

          <Modal.Header closeButton />
          <Modal.Body>
            <PhotoFullStyled src={show || ''} />
          </Modal.Body>

        </Modal>
      </ContainerForm>
    </Wrapper>
  )
}
export default TaskInternalPage
