import { Container, Row } from 'react-bootstrap'
import styled from 'styled-components'

export const ContainerStyled = styled(Container)`
  height: 100vh;
`
export const RowStyled = styled(Row)`
  height: 100vh;
`
// export const Container = styled.div`
//   height: 100vh;
//   position: relative;
//   overflow-x: hidden;

//   display: block;
//   flex-direction: column;
//   align-items: center;

//   .background-svg {
//     height: 100vh;
//     position: fixed;
//     bottom: 0;
//     left: 0px;
//     z-index: -999;
//   }
// `
