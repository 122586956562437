// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { ProductModel } from '../../../../../domain/models/product'
import { t } from '../../../../../presentation/adapters/translate/translate'
import { convertToFloat } from '../../../../../presentation/helpers/utils'
import ProductInternalPage from '../../../../../presentation/pages/stock/product'
import { makeRemoteProduct } from '../../../usecases/product/remote-product'

function MakeProductInternal({ match }:any): JSX.Element {
  const { handleAlert } = useAlertBox()
  const [data, setData] = useState<ProductModel>()
  const history = useHistory()

  const loadData = async (): Promise<void> => {
    const content = await makeRemoteProduct().loadProductById(match?.params?.id)
    setData(content)
  }

  useEffect(() => {
    (async () => {
      if (match?.params?.id && match?.params?.id !== 'create') {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = async (values: any, actions: any): Promise<any> => {
    try {
      if (data?.id) {
        const updatedData = await makeRemoteProduct().update(data?.id, {
          ...(!values?.image_file?.includes('https') && { image: values?.image_file }),
          price: convertToFloat(values?.price),
          name: values?.name,
          purchasedAt: values?.purchasedAt,
        })
        setData(updatedData)
      } else {
        await makeRemoteProduct().create({
          image: values?.image_file,
          price: convertToFloat(values?.price),
          name: values?.name,
          purchasedAt: values?.purchasedAt,
        })
        history.push('/stock')
      }

      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error?.userMessage || error?.message),
      })
      actions.setSubmitting(false)
    }
  }
  return (
    <ProductInternalPage
      data={data}
      handleSubmit={handleSubmit}
    />
  )
}

export default MakeProductInternal
