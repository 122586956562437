/* eslint-disable no-nested-ternary */
import { Field, Form } from 'formik'
import { IMaskInput } from 'react-imask'
import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const InputMaskStyled = styled.input`
  font-size: 1.6rem;
  padding: 5px 0px;
  width: 100%;
  height: 40px;
  border-bottom-width: 1px;

`
export const DownloadButton = styled.a`
  margin-left: 15px;
  right: 0;
  position: absolute;
  justify-content: center;
  display: flex;
  padding: 5px;
  border-radius: 6px;
  cursor: pointer;
  margin-right: 15px;
  transition: ease all 200ms;
  svg{
    margin: 0!important;
  }
  &:hover{
    background-color:${theme.colors.green};
    svg path{
      fill: #fff;
    }
  }
`
export const RadioStyled = styled.fieldset`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  margin-bottom: 15px;
  position: relative;
`
export const CheckboxStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  label{
    display: flex;
    flex-direction: column;
    input {
      margin: 0px 0 0 0
    }
  }

  .error-field-yesno{
    bottom:-15px;
    margin-left: 0;
  }
`
export const ToogleStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  position: relative;
  .error-field-toggle{
    bottom:-15px;
    margin-left: 0;
  }
`
export const ToogleButtonStyled = styled.div`
  display: flex;
  width:46px;
  height: 24px;
  border-radius: 20px;
  border: 1px solid ${theme.colors.lightGray};
  position: relative;
  transition: ease all 300ms;
  background-color: ${theme.colors.middleGray};
  &:before {
    content:'';
    width: 18px;
    height: 18px;
    background-color: #fff;
    border-radius: 10px;
    position: absolute;
    margin-top: 2px;
    margin: 2px;
    left: 0;
    right: unset;
  }
`
export const FieldSeclectStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  padding-bottom: 10px;
`
export const FileContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  .error-field-file{
    bottom: 5px
  }
`
export const FieldStyled = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
`
export const FileStyled = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background-color: ${theme.colors.lightGray};
  width: 100%;
  padding: 7px;
  border-radius: 10px;
  margin-top: 5px;
  height: 45px;
  cursor: pointer;
  svg{
    margin-right: 10px;
  }
`
export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  p{
    margin: 0;
    margin-bottom: 0px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden
  }
`
export const RadioBox = styled(Field)`
  height: 15px;
  width: 15px;
  accent-color: ${theme.colors.primary};
  margin-right: 10px;
  margin-left: 5px;
  /* margin-bottom: 15px; */
`

type ToggleProps = {
  disabled: boolean
}

export const CheckboxToggle = styled(Field)`
  height: 15px;
  width: 15px;
  accent-color: green;
  margin-right: 15px;
  margin-left: 5px;
  /* margin-bottom: 25px; */
  &:checked + div .toggle{
      background-color: ${(e:ToggleProps) => (e.disabled ? theme.colors.lightGray : theme.colors.green)};
      &:before {
        content:'';
        width: 18px;
        height: 18px;
        background-color: #fff;
        border-radius: 10px;
        position: absolute;
        margin-top: 2px;
        margin: 2px;
        right: 0;
        left: unset;
      }
    }
`
export const Checkbox = styled(Field)`
  height: 15px;
  width: 15px;
  accent-color: green;
  margin-right: 15px;
  margin-left: 5px;
  /* margin-bottom: 25px; */
`
export const InpuImasktStyled = styled(IMaskInput)`
  font-family: 'Poppins';
  padding: 10px 15px 10px 25px;
  border: 1px solid ${theme.colors.middleGray};
  border-radius: 100px;
  color: ${theme.colors.black};
  height: 56px;
  margin-bottom: 15px;
  &:disabled{
    border: 2px solid #ABABAB;
    background-color: ${theme.colors.lightGray};
    color:#ABABAB
  }
`
export const InputStyled = styled(Field)`
  font-family: 'Poppins';
  padding: 10px 15px 10px 25px;
  border: 1px solid ${theme.colors.middleGray};
  border-radius: 100px;
  color: ${theme.colors.black};
  height: 56px;
  margin-bottom: 15px;
  &:disabled{
    border: 2px solid #ABABAB;
    background-color: ${theme.colors.lightGray};
    color:#ABABAB
  }
`
export const LabelGroupStyled = styled.label`
  width: auto;
  margin-bottom: 15px;
  font-family: 'palanquin';
  padding: 0;
  font-weight: 600;
  font-size: .9rem;
`
export const ErrorStyled = styled.span`
  color:#FE5005;
  font-size: .7rem;
  position: absolute;
  margin-left: 17px;
  bottom: 0;
  margin-top: 5px;
`
export const ViewPass = styled.span`
  position: absolute;
  right: 25px;
  top:12px;
  font-size: 1.3rem;
`

type NumberProp = {
  disabled: boolean
}

export const NumberInputContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  border: 1px solid ${theme.colors.middleGray};
  border-radius: 12px;
  color: ${theme.colors.black};
  margin-bottom: 25px;
  justify-content: space-between;
  input{
    width: 45%;
    border: none;
    padding-left: 0!important;
    padding-right: 0;
    text-align:center;
    margin-bottom: 0!important;
    &::-webkit-inner-spin-button,&::-webkit-outer-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }
  ${(e:NumberProp) => e.disabled && `&{
    border: 2px solid #ABABAB;
    background-color: ${theme.colors.lightGray};
    color:#ABABAB;
    input:disabled{
      border: none;
    }
  }`}
`
export const ButtonInput = styled.button`
  display: flex;
  background-color: transparent;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 25%;
`
export const ContentFields = styled.div`
  display: flex;
  flex-direction: column;
`
export const FormikStyled = styled(Form)`
  width: 100%;
  &.internal-form{
    input[type='text'], input[type='date'] , input[type='number'], input[type='password'] {
      border-radius: 12px;
      max-height: 43px;
      padding-left: 15px;
      font-size: .8rem;
      margin-bottom: 25px;
    }
    button[type='submit']{
      width: auto;
      padding: 15px 60px;
      font-size: .8rem;
      font-weight: 400;
      margin-top: 50px;
    }
    .select-input{
      border-radius: 12px;
      max-height: 43px;
      padding-left: 15px;
      font-size: .8rem;
    }
    .container-form{
      padding: 0;
    }
    .error-field {
      position: absolute;
      bottom: 10px;
    }
  }

  &.editMode{
    button[type='submit']{
      display: none;
    }
  }
`
export const LabelRadioStyled = styled.label`
  font-family: 'poppins';
  font-weight: 400;
  font-size: .9rem;
  display: flex;
  align-items: center;
  margin-bottom: 15px;
`
export const LabelStyled = styled.label`
  font-family: 'poppins';
  font-weight: 400;
  font-size: .7rem;
  margin-bottom: 5px;
  width: 100%;
`
export const ContainerForm = styled.div`
 width: 100%;
`
