import React, { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { theme } from '../../styles/theme'
import ButtonComponent from '../button'

type ModalDialogProps = {
  title?:string
  message: string
  buttons:{
    confirm?:string,
    cancel?:string
  }
  open: boolean
  confirmCallback?: ()=> void
  cancelCallback?: ()=> void
}

export const DialogModal = ({
  message, title, buttons, open, confirmCallback, cancelCallback,
}:ModalDialogProps):JSX.Element => {
  const [show, setShow] = useState<boolean>(true)

  const handleClose = ():void => {
    setShow(false)
  }

  const onConfirmAction = ():void => {
    setShow(false)
    if (confirmCallback) {
      confirmCallback()
    }
    handleClose()
  }

  const onCancelAction = ():void => {
    handleClose()
    if (cancelCallback) {
      cancelCallback()
    }
  }
  useEffect(() => {
    setShow(open || false)
  }, [open])

  return (
    <Modal show={show} onHide={handleClose}>
      {title && (
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
      )}

      <Modal.Body>
        <p>{message}</p>
      </Modal.Body>

      <Modal.Footer>
        {buttons?.cancel
      && (
      <ButtonComponent
        onClick={() => onCancelAction()}
        title={buttons.cancel}
        color={theme.colors.black}
        backgroundColor={theme.colors.lightGray}
      />
      )}
        <ButtonComponent
          onClick={() => onConfirmAction()}
          title={buttons?.confirm || ''}
          color={theme.colors.white}
          backgroundColor={theme.colors.primary}
        />
      </Modal.Footer>
    </Modal>
  )
}
