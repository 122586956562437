import React, { useEffect, useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import {
  Card, Content, IconStyled, RattingRow, Title, ValueStyled,
} from './styles'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import { makeRemoteTask } from '../../../../main/factories/usecases/task/remote-task-factory'
import { Roles, useAuth } from '../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../domain/models/enum'

type CardProps = {
  period: string
  serviceProvider?: string | undefined
  customer?: string | undefined
}

const RatingsCard = ({
  period, serviceProvider, customer,
}:CardProps):JSX.Element => {
  const [data, setData] = useState(0)
  const { user } = useAuth()

  const getRequestBytypeUser = (userType: string, customParams: FilterModel):any => {
    if (userType === Roles.BRANCH_OFFICE || userType === Roles.HEAD_OFFICE) {
      return makeRemoteTask().loadCustomerReviewAverage(customParams)
    }
    if (userType === Roles.CUSTOMER_HEAD_OFFICE || userType === Roles.CUSTOMER_BRANCH_OFFICE) {
      return makeRemoteTask().loadCustomerPendingAverage(customParams)
    }
    return makeRemoteTask
  }
  useEffect(() => {
    if (customer || serviceProvider) {
      (async () => {
        const response = await getRequestBytypeUser(user?.roles[0]?.authority, {
          period,
          serviceProvider,
          customer,
        })
        setData(response)
      })()
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [period, serviceProvider, customer])

  return (
    <Card>
      <Content>
        <IconStyled>
          <Icons name="star" width={26} height={26} color={theme.colors.white} />
        </IconStyled>
        <Title>
          {customer ? 'Tarefas a avaliar' : 'Classificação Média'}
        </Title>
        <RattingRow>
          {/* <ReactStars
          count={5}
          size={15}
          isHalf
          activeColor={theme.colors.yellow}
        /> */}
          <Rating
            ratingValue={0}
            initialValue={data}
            readonly
            size={15}
            allowHalfIcon
          />
          <ValueStyled>
            {Number(data).toFixed(1)}
          </ValueStyled>
        </RattingRow>
      </Content>
    </Card>
  )
}
export default RatingsCard
