/* eslint-disable no-unused-expressions */
import * as Yup from 'yup'
import { ObjectShape } from 'yup/lib/object'
import { getValidationSchemaByName } from './validationsMap'

export type ItemsProp = {
  name: string;
  placeholder?: string
  onChangeText?: (val: any) => void
  onBlur?: (val: any) => void
  initialValue?: string
  label?: string
  secureTextEntry?: boolean | undefined
  validations?: string
  items?: any
  mask?: string
  hide?: boolean
  options?: { label: string, value: string }[] | any
  labelKey?: string
  valueKey?: string
  col?: {
    xs?: number
    md?: number
    lg?: number
  }
}

export type GroupProp = {
  groupName: string
  groupLabel?: string
  items: ItemsProp[]
}

export type FormFactoryProps = {
  groups: GroupProp
}

export const getInitialValues = (groups: GroupProp[]): object => {
  const initialValues = {}

  groups.forEach((group: { items: ItemsProp[] }) => {
    'items' in group
      && group.items.forEach((item: ItemsProp) => {
        Object.assign(initialValues, {
          [item.name]: item.initialValue || '',
        })
      })
  })

  return initialValues
}

const mapValidationSchema = (validations: any): ObjectShape => {
  const yupValidation = {}
  Object.keys(validations).forEach((key) => {
    Object.assign(yupValidation, {
      [key]: getValidationSchemaByName(validations[key]),
    })
  })

  return yupValidation
}

export const getValidationSchema = (groups: GroupProp[]): object | null => {
  const validations = {}

  groups.forEach((group) => {
    'items' in group
      && group.items.forEach((item) => {
        if (item.validations) {
          Object.assign(validations, {
            [item.name]: item.validations,
          })
        }
      })
  })

  const validationSchema = mapValidationSchema(validations)

  return Object.keys(validationSchema).length
    ? Yup.object().shape(validationSchema)
    : null
}
