// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { CustomerProps, CustomerResponseModel } from '../../../../../domain/models/customer'
import { FilterModel } from '../../../../../domain/models/enum'
import { ServiceProviderProps } from '../../../../../domain/models/service-provider'
import { t } from '../../../../../presentation/adapters/translate/translate'
import CustomerBranchsListPage from '../../../../../presentation/pages/customer/internal/branchs'
import { settings } from '../../../../configs/settings'
import { makeRemoteAdmin } from '../../../usecases/admin/remote-admin-factory'
import { makeRemoteCustomer } from '../../../usecases/customer/remote-customer-factory'

const generateFilter = (values: any):string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'headOfficeCustomer') {
      return `${el}=${values[el]}`
    }
    return el
  }).join(',')

type MakeProps = {
  customer:CustomerProps | undefined
}

function MakeCustomersBranchs({ customer }:MakeProps): JSX.Element {
  const { handleAlert } = useAlertBox()
  const [list, setList] = useState<CustomerResponseModel>()
  const [listServiceProvider, setListServiceProvider] = useState<ServiceProviderProps[]>()
  const [params, setParams] = useState<FilterModel>({ })
  const [loading, setLoading] = useState<boolean>()
  const [reload, setReload] = useState<boolean>()
  const { user } = useAuth()

  const loadData = async (): Promise<void> => {
    try {
      setLoading(true)
      const content = await makeRemoteAdmin().loadCustomers({
        size: settings.perPage,
        page: params?.page,
        query: params?.query,
        filters: generateFilter({
          ...params?.filters,
          headOfficeCustomer: customer?.uuid,
        }),
      })
      setList(content)
      const contentSP = await makeRemoteAdmin().loadServiceProvidersBranchs()
      setListServiceProvider(contentSP)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (async () => {
      if (customer?.id) {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer?.id, user, reload, params])

  const handleSubmitCustomer = async (
    sp: ServiceProviderProps, customerProps: CustomerProps,
  ): Promise<any> => {
    try {
      await makeRemoteCustomer().update(customerProps?.id || 0, {
        enabled: customerProps?.enabled,
        headOffice: false,
        name: customerProps?.name || '',
        // phoneNumber: customer.phoneNumber,
        serviceProvider: {
          id: sp.id,
        },
        headOfficeCustomer: {
          id: customer?.id || 0,
        },
        user: {
          email: customerProps?.user?.email || '',
          name: customerProps?.user?.name || '',
        },
      })
      setReload((prev) => !prev)
      handleAlert({
        show: true,
        type: 'success',
        message: 'Vínculo Registrado Com sucesso',
      })
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error?.userMessage || error?.message),
      })
    }
  }
  return (
    <CustomerBranchsListPage
      list={list}
      loading={loading}
      handleSubmit={handleSubmitCustomer}
      setParams={setParams}
      params={params}
      serviceProviderList={listServiceProvider || []}
    />
  )
}

export default MakeCustomersBranchs
