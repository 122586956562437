/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { FilterModel } from '../../../../domain/models/enum'
import { ProductStockReponseModel, StockProps } from '../../../../domain/models/stock'
import DataGridCard from '../../../components/datagrid-card'
import FilterBar from '../../../components/filter-bar'
import Icons from '../../../components/icons-helper'
import { getColorByQtd, getLabelByQtd, unitMeasurement } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { ButtonComponentStyled, ContainerButton } from '../../task/task-list/styles'
import SideModalComponent from '../internal'
import {
  BoxCardStyled,
  ButtonStyled,
  CardContentStyled,
  CheckboxStyled,
  TextCardStyled,
  FieldCheckbox,
  FilterStyled,
  LabelStyled,
  SperatorStyled,
  TitleCardContainer,
  TitleCardStyled,
  TitleContainer,
  TitleStyled, TitleStyledFilter, Wrapper, BoxCardInfoStyled,
  PhotoStyled, StatusProduct,
} from './styles'

type PageProps = {
  list: ProductStockReponseModel | undefined
  setParams: any
  loading?:boolean
}

type FilterComponentProps = {
  submit:(value:string)=>void
}

const FilterComponent = ({ submit }:FilterComponentProps):JSX.Element => {
  const [values, setValues] = useState<any>({})

  const handleChange = (e:any):void => {
    setValues(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }

  const applyFilter = (): void => {
    submit(values)
  }

  return (
    <FilterStyled>
      <SperatorStyled />
      <TitleStyledFilter>
        Status
      </TitleStyledFilter>
      <FieldCheckbox>
        <CheckboxStyled
          value="quantity<=2"
          name="acabando"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Acabando
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="quantity>2"
          name="disponivel"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Disponível
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="quantity=0"
          name="acabou"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Acabou
      </FieldCheckbox>

      <ButtonStyled onClick={() => applyFilter()}>Aplicar</ButtonStyled>
    </FilterStyled>
  )
}

const StockListPage = ({
  list, setParams, loading,
}:PageProps):JSX.Element => {
  const [open, setOpen] = useState<StockProps.Model | undefined>()

  const handlePage = (e:any):void => {
    setParams((prev: FilterModel) => ({
      ...prev,
      page: e,
    }))
  }

  const handleSearch = (callback:any):void => {
    const val = callback()

    setParams(
      (prev: any) => ({
        ...prev,
        filters: { ...prev?.filters, name: val.query },
      }
      ),
    )
  }

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>Estoque de produtos</TitleStyled>
        <ButtonComponentStyled
          to="/product/create"
        >
          <Icons
            name="plus"
            width={15}
            height={15}
            color={theme.colors.primary}
          />
          Novo produto
        </ButtonComponentStyled>
      </TitleContainer>

      <FilterBar
        placehoderSearch="Buscar pelo nome do produto"
        handleFilter={handleSearch}
        filterComponent={(
          <FilterComponent
            submit={(values: any) => setParams((prev: any) => ({
              ...prev,
              filters: { ...prev?.filters, ...values },
            }))}
          />
        )}
      >
        <ContainerButton>
          <ButtonComponentStyled to="/stock/create">
            <Icons
              name="plus"
              width={15}
              height={15}
              color={theme.colors.white}
            />
            Novo item
          </ButtonComponentStyled>
        </ContainerButton>
      </FilterBar>
      <DataGridCard
        columns={2}
        loading={!!loading}
        data={list?.content || []}
        totalElements={list?.totalElements || 0}
        currentPage={list?.number || 0}
        totalPages={list?.totalPages || 0}
        changePage={handlePage}
        render={
          (item:StockProps.Model) => (
            <CardContentStyled onClick={() => setOpen(item)}>
              <PhotoStyled src={item?.product?.imageUrl} />
              <BoxCardStyled>
                <TitleCardContainer>
                  <TitleCardStyled>
                    {item?.product?.name}
                  </TitleCardStyled>
                </TitleCardContainer>
                <BoxCardInfoStyled>
                  <TextCardStyled>
                    <LabelStyled>Estoque Atual</LabelStyled>
                    {String(item?.quantity).padStart(2, '0')}
                    {' '}
                    {unitMeasurement(item?.quantityUnit)}
                  </TextCardStyled>
                  <TextCardStyled>
                    <LabelStyled>Status</LabelStyled>
                    {' '}
                    <StatusProduct color={getColorByQtd(item?.quantity || 0)}>
                      {getLabelByQtd(item?.quantity || 0)}
                    </StatusProduct>
                  </TextCardStyled>

                </BoxCardInfoStyled>
              </BoxCardStyled>
            </CardContentStyled>
          )
        }
      />
      <SideModalComponent open={!!open} product={open} onCloseCallBack={() => setOpen(undefined)} />
    </Wrapper>
  )
}
export default StockListPage
