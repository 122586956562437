import React, { useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/auth-context/auth-context'
import {
  RatingsCard,
  TasksStatus,
  UploadDocumentsCard,
} from '../../components/dashboards'
import CountTasksCustomer from '../../components/dashboards/completed-tasks'
import TaskListCard from '../../components/dashboards/tasks-list'
import ViewTaskCard from '../../components/dashboards/view-tasks'
import Icons from '../../components/icons-helper'
import PeriodFilter from '../../components/period-filter'
import { theme } from '../../styles/theme'
import {
  BackButton,
  ColStyled, ContainerModal, ContinueButton, RowStyled, RowTitleStyled, SplitStyled, Title, Wrapper,
} from './styles'

const CustomerBranchDashboardPage = ():JSX.Element => {
  // const [show, setShow] = useState<boolean>(true)
  const [period, setPeriod] = useState<string>('month')
  const {
    profile, setBranch, setIsheadeOffice, user,
  } = useAuth()
  const history = useHistory()

  const show = !!profile?.branch?.openModal

  return (
    <Wrapper>
      <RowTitleStyled>
        <Row className="w-100">
          <Col xs={3}>
            <Title>
              Dashboard
            </Title>
          </Col>

        </Row>

        <PeriodFilter callback={(callback:string) => setPeriod(callback)} />
      </RowTitleStyled>
      <RowStyled>
        <ColStyled width={36}>
          <ViewTaskCard />
        </ColStyled>
        <ColStyled width={40}>
          <TaskListCard
            period={period}
            customer={user.uuid}
          />
        </ColStyled>
        <ColStyled width={18}>
          <SplitStyled>
            <CountTasksCustomer
              period={period}
              isHeadOffice={!profile?.branch && profile?.isHeadOffice}
              customer={profile?.branch?.uuid}
            />
          </SplitStyled>
          <SplitStyled>
            <RatingsCard
              period={period}
              customer={profile?.branch?.uuid}
            />
          </SplitStyled>
        </ColStyled>

      </RowStyled>
      <RowStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="TODO"
            period={period}
            isHeadOffice={!profile?.branch && profile?.isHeadOffice}
            customer={profile?.branch?.uuid}
          />
        </ColStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="IN_PROGRESS"
            period={period}
            isHeadOffice={!profile?.branch && profile?.isHeadOffice}
            customer={profile?.branch?.uuid}
          />
        </ColStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="COMPLETED"
            period={period}
            isHeadOffice={!profile?.branch && profile?.isHeadOffice}
            customer={profile?.branch?.uuid}
          />
        </ColStyled>
        <ColStyled width={46}>
          <UploadDocumentsCard
            period={period}
            isHeadOffice={!profile?.branch && profile?.isHeadOffice}
            uuid={profile?.branch?.uuid || user?.uuid}
          />
        </ColStyled>
      </RowStyled>
      <Modal
        show={show}
        onHide={async () => {
          await setBranch({ ...profile?.branch, openModal: false })
        }}
        className="modal-branch"
      >
        <Modal.Body>
          <ContainerModal>
            <Icons
              name="caution"
              width={200}
              height={200}
              color={theme.colors.yellow}
            />
            <span>Você estará visualizando:</span>
            <h5>
              Filial
              {' '}
              {profile?.branch?.name}
            </h5>
            <ContinueButton onClick={async () => {
              await setBranch({ ...profile?.branch, openModal: false })
            }}
            >
              Prosseguir
            </ContinueButton>
            <BackButton onClick={async ():Promise<void> => {
              await setBranch(null)
              await setIsheadeOffice(true)
              history.goBack()
            }}
            >
              Voltar
            </BackButton>
          </ContainerModal>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}
export default CustomerBranchDashboardPage
