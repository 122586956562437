import styled from 'styled-components'

type ButtonProps = {
  color?: string
  backgroundColor?: string
}

export const ButtonWrapper = styled.button`
  border-radius: 30px;
  width: 100%;
  justify-content: center;
  align-items: center;
  padding: 12px 15px 12px 15px;
  background-color: ${(props:ButtonProps) => props.backgroundColor};
  color: ${(props:ButtonProps) => props.color};
  line-height: 1;
  &:hover{
    opacity: .7;
  }
  &:disabled{
    opacity: .4;
  }
  svg{
    margin-right: 5px;
  }
`
