import React, { useEffect, useState } from 'react'
import { useDetectClickOutside } from 'react-detect-click-outside'
import { useHistory } from 'react-router-dom'
import { branchByUserType, useAuth } from '../../../../contexts/auth-context/auth-context'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import {
  ChangeMatrizBoxStyled,
  ContextButton,
  ContextMenu,
  DropDownStyled,
  ItemMenu,
  LiBranchs,
  Listbranches,
  LocalStyled,
  MatrizButton,
  MenuBar,
  NameStyled,
  SearchBoxStyled,
  SearchStyled,
  SelectStyled,
  SettingsButton,
  Subtitle,
  TitleMenu,
  UlBranchs,
  Wrapper,
} from './styled'
import { ServiceProviderProps } from '../../../../domain/models/service-provider'
import { checkNotHeadOffice, isAdmin } from '../../../helpers/utils'

type ComponentProps = {
  setOpen: (e:any) => void
}

const NavbarComponent = ({ setOpen }:ComponentProps):JSX.Element => {
  const {
    user, profile, setBranch, setIsheadeOffice, getAdminLocal, setUserLocal,
  } = useAuth()
  const [branchList, setBranchList] = useState<any>()
  const [search, setSearch] = useState<string>('')
  const [open, setOpenDropDown] = useState<boolean>(false)
  const history = useHistory()
  const closeDropdown = ():void => {
    if (open) {
      setOpenDropDown(false)
    }
  }
  const ref = useDetectClickOutside({ onTriggered: closeDropdown })

  useEffect(() => {
    if (user?.roles) {
      (async () => {
        const resp = await branchByUserType(user?.roles[0]?.authority, user)
        setBranchList(resp)
      })()
    }
  }, [user])

  return (
    <Wrapper>
      <MenuBar>
        <ItemMenu onClick={() => setOpen((prev: any) => !prev)}>
          <Icons
            name="hamburger"
            width={26}
            height={26}
            color={theme.colors.primary}
          />
          <TitleMenu>
            Menu
          </TitleMenu>
        </ItemMenu>
      </MenuBar>
      <ContextMenu>
        <ContextButton>
          <Icons name="user" width={48} height={48} color={theme.colors.primary} />
          <SelectStyled
            onClick={() => (branchList?.length || isAdmin()) && setOpenDropDown((prev) => !prev)}
            ref={ref}
          >
            <NameStyled>
              {user?.name}
            </NameStyled>
            {!checkNotHeadOffice(user?.roles[0]?.authority) && (
            <LocalStyled>
              {profile?.branch?.name || profile?.branch?.label || 'Matriz'}
              <Icons
                name="arrow-down"
                width={26}
                height={26}
                color={theme.colors.primary}
              />
            </LocalStyled>
            )}
          </SelectStyled>
          <DropDownStyled open={open}>
            {!isAdmin() && (
            <SearchBoxStyled>
              <Icons
                name="search"
                width={20}
                height={20}
                color={theme.colors.middleGray}
              />
              <SearchStyled placeholder="Buscar filial" onChange={(e) => setSearch(e.target.value)} />
            </SearchBoxStyled>
            )}
            <Listbranches>
              {!isAdmin() && <Subtitle>Alternar para filial</Subtitle>}
              <UlBranchs>
                {branchList?.filter((el:ServiceProviderProps) => {
                  if (search) {
                    if (el?.name?.toLowerCase().includes(search.toLowerCase())) {
                      return el?.name?.toLowerCase().includes(search.toLowerCase())
                    }
                  } else {
                    return el
                  }
                  return null
                }).map(
                  (el:ServiceProviderProps) => (
                    <LiBranchs
                      onClick={async ():Promise<void> => {
                        await setBranch(el)
                        await setIsheadeOffice(false)
                        history.push('/')
                      }}
                      selected={profile?.branch?.id === el.id}
                      key={el.name}
                    >
                      {el.name}
                    </LiBranchs>
                  ),
                )}
              </UlBranchs>
            </Listbranches>
            {isAdmin() && (
              <ChangeMatrizBoxStyled>
                <MatrizButton onClick={async ():Promise<void> => {
                  await setBranch(null)
                  await setIsheadeOffice(null)
                  history.push('/select-env')
                }}
                >
                  Alternar visualização

                </MatrizButton>
              </ChangeMatrizBoxStyled>
            )}
            {profile?.branch && (
              <ChangeMatrizBoxStyled>
                <MatrizButton onClick={async ():Promise<void> => {
                  const adm = getAdminLocal()
                  if (adm) {
                    await setUserLocal(adm)
                    await setBranch(null)
                    await setIsheadeOffice(true)
                    history.push('/')
                  } else {
                    await setBranch(null)
                    await setIsheadeOffice(true)
                    history.push('/')
                  }
                }}
                >
                  Alternar para
                  {' '}
                  {`${getAdminLocal() ? 'Admin Wash Me' : 'Matriz'}`}

                </MatrizButton>
              </ChangeMatrizBoxStyled>
            )}
          </DropDownStyled>
        </ContextButton>
        <SettingsButton to="/settings">
          <Icons
            name="settings"
            width={36}
            height={36}
            color={theme.colors.primary}
          />
        </SettingsButton>
      </ContextMenu>
    </Wrapper>
  )
}
export default NavbarComponent
