import React, { useEffect, useState } from 'react'
import { useLocation } from 'react-router-dom'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import MenuComponent from '../menu'
import NavbarComponent from '../navbar'
import {
  ColStyledMenu, ContainerBox, ContainerStyled, RowStyled, SpaceModalStyled,
} from './styled'

const BaseLayout = ({ children }: any):JSX.Element => {
  const [open, setOpen] = useState<boolean>(true)
  const [split, setSplit] = useState<any | null>()
  const { pathname } = useLocation()
  const routes = [
    {
      path: '/upload-documents',
      icon: 'file-up',
      label: 'Selecione um cliente para visualizar os documentos solicitados',
    },
    {
      path: '/stock',
      icon: 'makeups',
      label: 'Selecione um produto para ver mais detalhes',
    },
  ]

  useEffect(() => {
    setSplit(routes?.find((el) => el?.path === pathname) || null)
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname])

  return (
    <ContainerBox>
      <ContainerStyled
        fluid
        split={!!split}
      >
        <NavbarComponent setOpen={setOpen} />
        <RowStyled>
          <ColStyledMenu width={open ? 16 : 5} style={{ minWidth: open ? 215 : 0 }}>
            <MenuComponent open={open} />
          </ColStyledMenu>
          <ColStyledMenu width={open ? 84 : 95}>
            {children}
          </ColStyledMenu>
        </RowStyled>
      </ContainerStyled>
      {!!split && (
      <SpaceModalStyled split={!!split}>
        <Icons
          name={split?.icon}
          width={40}
          height={40}
          color={theme.colors.darkGray}
        />
        {split?.label}
      </SpaceModalStyled>
      )}
    </ContainerBox>
  )
}

export default BaseLayout
