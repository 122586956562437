import React, { useEffect, useState } from 'react'
import { Roles, useAuth } from '../../../../../contexts/auth-context/auth-context'
import { DocumentsResponseModel } from '../../../../../domain/models/documents'
import { FilterModel } from '../../../../../domain/models/enum'
import UploadDocumentsListPage from '../../../../../presentation/pages/upload-documents/list'
import { settings } from '../../../../configs/settings'
import { makeRemoteDocuments } from '../../../usecases/documents/remote-documents-factory'

const generateFilter = (values: any):string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'status') {
      return `${el}=${values[el]}`
    }
    if (el === 'customer') {
      return `${el}=${values[el]}`
    }
    return el
  }).join(',')

export const selectRequestByType = (userType: string, customParams: FilterModel):any => {
  if (userType === Roles.BRANCH_OFFICE || userType === Roles.HEAD_OFFICE) {
    return makeRemoteDocuments().sumary(customParams)
  }
  if (userType === Roles.CUSTOMER_HEAD_OFFICE || userType === Roles.CUSTOMER_BRANCH_OFFICE) {
    return makeRemoteDocuments().customerList(customParams)
  }
  return makeRemoteDocuments
}

function MakeUploadDocuments(): JSX.Element {
  const [list, setList] = useState<DocumentsResponseModel>()
  const [params, setParams] = useState<FilterModel>()
  const [loading, setLoading] = useState<boolean>()
  const { profile, user } = useAuth()
  const [reload, setReload] = useState<number>(0)

  const refetch = ():void => {
    setReload((prev) => prev + 1)
  }

  const loadData = async (): Promise<void> => {
    setLoading(true)
    const data = await selectRequestByType(user?.roles[0]?.authority, {
      ...params,
      size: settings.perPage,
      uuid: profile?.branch?.uuid || user.uuid,
      isHeadOffice: true,
      filters: generateFilter({
        ...params?.filters,
      }),
    })

    setList(data)
    setLoading(false)
  }

  useEffect(() => {
    if (profile || user) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, profile, reload])

  return (
    <UploadDocumentsListPage
      list={list}
      setParams={setParams}
      loading={loading}
      refetch={refetch}
    />
  )
}

export default MakeUploadDocuments
