import React from 'react'
import { Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { settings } from '../../../main/configs/settings'
import {
  GridStyled,
  Wrapper,
  BodyTableStyled,
  RowTable, PaginateStyled, PaginateInfo,
} from './styled'

export type ItemField = {
  title:string
  key:string
  size?: number
  renderItem: (callback: any) => any
}
export type DatgridProps = {
  data: any[]
  render: (e:any)=> any
  changePage?: (ev: any)=> void
  totalElements: number
  currentPage: number
  totalPages:number
  loading: boolean
  columns: number
}

const DataGridCard = ({
  data, changePage, totalElements, currentPage, totalPages, loading, render, columns,
}:DatgridProps):JSX.Element => {
  const handlePageClick = (event: any):void => {
    if (changePage) {
      changePage(event.selected)
    }
  }

  return (
    <Wrapper>
      <GridStyled>
        {loading ? <Spinner animation="grow" style={{ margin: '0 auto' }} variant="primary" /> : (
          <BodyTableStyled columns={columns}>
            {data?.map(
              (el) => (
                <RowTable key={el.id}>
                  {render(el)}
                </RowTable>
              ),
            )}
          </BodyTableStyled>
        )}
        <PaginateStyled>
          <PaginateInfo>
            {settings.perPage * (currentPage + 1) >= totalElements
              ? totalElements
              : settings.perPage * (currentPage + 1)}
            {' '}
            de
            {' '}
            {totalElements}
            {' '}
            itens
          </PaginateInfo>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={settings.perPage}
            pageCount={totalPages}
            previousLabel="<"
          />
        </PaginateStyled>
      </GridStyled>
    </Wrapper>
  )
}
export default DataGridCard
