import React from 'react'
import { Spinner } from 'react-bootstrap'
import ReactPaginate from 'react-paginate'
import { useHistory } from 'react-router-dom'
import { v4 as uuidv4 } from 'uuid'
import { settings } from '../../../main/configs/settings'
import {
  GridStyled,
  Wrapper,
  Itemheader,
  HeaderStyled,
  BodyTableStyled,
  CelTable, RowTable, PaginateStyled, PaginateInfo,
} from './styled'

export type ItemField = {
  title:string
  key:string
  size?: string
  renderItem: (callback: any) => any
  hide?: boolean
}
export type DatgridProps = {
  routeToEdit?:string
  fields: ItemField[]
  data: any[]
  changePage?: (ev: any)=> void
  onClickRow?: (ev: any)=> void
  totalElements: number
  currentPage: number
  totalPages:number
  loading: boolean
}

const DataGrid = ({
  fields,
  data,
  changePage,
  routeToEdit,
  totalElements,
  currentPage,
  totalPages,
  loading,
  onClickRow,
}:DatgridProps):JSX.Element => {
  const history = useHistory()
  const handlePageClick = (event: any):void => {
    if (changePage) {
      changePage(event.selected)
    }
  }

  const goToEdit = (id:number): void => {
    history.push((routeToEdit || '') + id)
  }

  return (
    <Wrapper>
      <GridStyled>
        <HeaderStyled>
          {fields?.filter((el) => !el?.hide)?.map(
            (el) => (
              <Itemheader key={el.key + uuidv4()} style={{ maxWidth: el.size || `${100 / fields?.filter((f) => !f?.hide).length}%` }}>
                {el.title}
              </Itemheader>
            ),
          )}

        </HeaderStyled>
        {loading ? <Spinner animation="grow" style={{ margin: '0 auto' }} variant="primary" /> : (
          <BodyTableStyled className="grid-list">
            {data?.map(
              (el, i) => (
                <RowTable
                  key={el.id + uuidv4()}
                  index={i}
                  onClick={() => {
                    if (onClickRow) {
                      onClickRow(el)
                    }

                    return routeToEdit && goToEdit(el.id)
                  }}
                >
                  {fields?.filter((f) => !f?.hide).map((field) => {
                    const cel = field.renderItem(el)
                    console.log(100 / fields?.filter((f) => !f?.hide).length)
                    if (typeof cel !== 'string' || typeof cel !== 'number' || typeof cel !== 'string') {
                      return (
                      // eslint-disable-next-line react/no-array-index-key
                        <CelTable style={{ maxWidth: field.size || `${100 / fields?.filter((f) => !f?.hide).length}%` }} key={`${i}-${el.id}-${field.key}-${uuidv4()}`}>
                          {cel}
                        </CelTable>
                      )
                    }
                    return 'dado inválido'
                  })}

                </RowTable>
              ),
            )}
          </BodyTableStyled>
        )}
        <PaginateStyled className="paginate">
          <PaginateInfo>
            {settings.perPage * (currentPage + 1) >= totalElements
              ? totalElements
              : settings.perPage * (currentPage + 1)}
            {' '}
            de
            {' '}
            {totalElements}
            {' '}
            itens
          </PaginateInfo>
          <ReactPaginate
            breakLabel="..."
            nextLabel=">"
            onPageChange={handlePageClick}
            pageRangeDisplayed={settings.perPage}
            pageCount={totalPages}
            previousLabel="<"
          />
        </PaginateStyled>
      </GridStyled>
    </Wrapper>
  )
}
export default DataGrid
