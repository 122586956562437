import React, { useEffect, useState } from 'react'
// import { useDetectClickOutside } from 'react-detect-click-outside'
import { useDebounce } from 'use-debounce'
import { theme } from '../../styles/theme'
import Icons from '../icons-helper'
import {
  ActionBar,
  BoxComponentStyled,
  BoxFilterContainer,
  BoxSearchContainer, CloseButton, DropDownStyled, InputSearch, Wrapper,
} from './styled'

type ComponentProps = {
  children?: any
  handleFilter: any
  filterComponent: any
  placehoderSearch?: string
}

const FilterBar = ({
  children, handleFilter,
  filterComponent, placehoderSearch,
}: ComponentProps):JSX.Element => {
  const [open, setOpenDropDown] = useState<boolean>(false)
  const [close, setClose] = useState<boolean>(false)
  const [text, setText] = useState()
  const [value] = useDebounce(text, 1000)

  useEffect(() => {
    if (value) {
      handleFilter((prev:any) => ({ ...prev, query: `${value}` }))
    }
    if (!value) {
      handleFilter((prev:any) => ({ ...prev, query: '' }))
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value])

  const closeDropdown = ():void => {
    if (open) {
      setOpenDropDown(false)
    }
  }
  useEffect(() => {
    if (close) {
      closeDropdown()
      setClose(false)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [close, handleFilter])

  // const ref = useDetectClickOutside({ onTriggered: closeDropdown })

  return (
    <Wrapper>
      <BoxComponentStyled>
        <BoxSearchContainer>
          <Icons
            name="search"
            width={20}
            height={20}
            color={theme.colors.middleGray}
          />
          <InputSearch value={text} onChange={(e: any) => setText(e.target.value)} placeholder={placehoderSearch || 'Buscar'} />
        </BoxSearchContainer>
        <BoxFilterContainer onClick={() => setOpenDropDown(true)}>
          <Icons
            name="filter"
            width={20}
            height={20}
            color={theme.colors.middleGray}
          />
          <DropDownStyled open={open}>
            <ActionBar>
              Filtrar por:
              <CloseButton onClick={() => setClose(true)}>
                <Icons
                  name="close"
                  width={10}
                  height={10}
                  color={theme.colors.middleGray}
                />
              </CloseButton>
            </ActionBar>
            {filterComponent}
          </DropDownStyled>
        </BoxFilterContainer>
      </BoxComponentStyled>
      {children}
    </Wrapper>
  )
}
export default FilterBar
