import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import { GuardedRoute } from 'react-router-guards'
// import Authentication from '../../../infra/cognito/authentication'
// import Authentication from '../../../infra/cognito/authentication'

type RouteFactoryProps = {
  routes: {
    layout: React.FC;
    pages: {
      path: string;
      component: React.FC;
      meta?: React.FC;
      exact?: boolean;
    }[];
  }[];
};

export const RouteFactory: React.FC<RouteFactoryProps> = ({
  routes,
}: RouteFactoryProps) => (

  <Switch>
    {routes.map(({ layout: Layout, pages }) => (
      <Route key={`${pages.length}`} path={pages.map((page) => page.path)}>
        <Layout>
          <Suspense fallback={<div>loading...</div>}>
            <Switch>
              {pages.map(({ path, component, ...page }) => (
                <GuardedRoute
                    // guards={[requireLogin]}
                  key={`${page.exact}`}
                  path={path}
                  component={component}
                  {...page}
                  exact={page.exact || true}
                />
              ))}
            </Switch>
          </Suspense>
        </Layout>
      </Route>
    ))}
  </Switch>
)
