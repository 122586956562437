import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const Card = styled.div`
  box-shadow:  0px 7px 50px rgba(210, 214, 227, 0.4);
  background-color: ${theme.colors.white};
  border-radius: 24px;
  width: 100%;
  position: relative;
  overflow: hidden;
  height: 100%;
`
export const ImgStyled = styled.img`
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 0;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 20px;
  font-size: 12px;
  z-index: 1;
  position: relative;
`
type PropsIcon = {
  background: string | undefined
}

export const IconStyled = styled.div`
  background-color: ${(p: PropsIcon) => p.background};
  border-radius: 50%;
  width: 48px;
  height: 48px;
  align-items: center;
  justify-content: center;
  display: flex;

`

export const ValueStyled = styled.span`
  color: ${theme.colors.white};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: 3rem;
  letter-spacing: 1px;
  margin-top: 25px;
  margin-bottom: 15px;
  margin-bottom: 0;
`
export const Title = styled.h2`
  color: ${theme.colors.white};
  font-family: ${theme.font.palanquin};
  font-weight: 400;
  font-size: 1.4em;
  letter-spacing: 1px;
  margin-top: 20px;
  margin-bottom: 15px;
  margin-bottom: 0;
  width: 90%;
`
