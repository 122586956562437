import axios from 'axios'
import fileDownload from 'react-file-download'
import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { EnumTypesProps, FilterModel } from '../../../domain/models/enum'
import {
  LaborDocsProps,
  LaborDocsResponseProps,
  Operator, OperatorProps, OperatorResponseModel, OperatorTopFiveProps,
} from '../../../domain/models/operator'
import Authentication from '../../../infra/cognito/authentication'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteOperator implements Operator {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
    private readonly baseUrl: string | undefined,
  ) { }

  async load(params: FilterModel): Promise<OperatorResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async create(body: OperatorProps): Promise<OperatorProps> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'post',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async sendLaborDocuments(body: LaborDocsProps): Promise<LaborDocsProps> {
    const httpResponse = await this.httpPostClient.request({
      url: '/labor-documents',
      method: 'post',
      body,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async updateLaborDocuments(id: number, body: LaborDocsProps): Promise<LaborDocsResponseProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `/labor-documents/${id}`,
      method: 'put',
      body,
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async resetPass(id: number | undefined): Promise<OperatorProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/update-password/${id}`,
      method: 'put',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  // async resetPass(id: number): Promise<LaborDocsResponseProps> {
  //   const httpResponse = await this.httpPostClient.request({
  //     url: `/labor-documents/${id}`,
  //     method: 'put',
  //   })
  //   switch (httpResponse.statusCode) {
  //     case HttpStatusCode.ok: return httpResponse.body
  //     case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
  //     default: throw new UnexpectedError()
  //   }
  // }

  async getLaborDocuments(uuid: string): Promise<LaborDocsResponseProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `/labor-documents/operator/${uuid}`,
      method: 'get',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async delete(id: number): Promise<OperatorProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'delete',
    })
    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      case HttpStatusCode.conflict: throw httpResponse.body
      default: throw new UnexpectedError()
    }
  }

  async loadById(id: number): Promise<OperatorProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async update(id: number, body: OperatorProps): Promise<OperatorProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadAll(): Promise<EnumTypesProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/all`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async activeCount(params: FilterModel): Promise<number> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/active-count`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async topFiveOperators(params: FilterModel): Promise<OperatorTopFiveProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/top-five-operators`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async branchs(): Promise<OperatorProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/branch-offices`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async exportCSV(params: FilterModel): Promise<void> {
    const token = await Authentication.getToken()
    const resp = await axios.get(`${this.baseUrl}/${this.url}/export`, {
      responseType: 'blob',
      params,
      headers: {
        Authorization: token,
        accept: '*/*',
      },

    })
    fileDownload(resp.data, `Operadores - ${new Date().getTime()}.xlsx`)
  }
}
