import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../../contexts/auth-context/auth-context'
import Authentication from '../../../../infra/cognito/authentication'
import { isAdmin } from '../../../../presentation/helpers/utils'
import SelectType from '../../../../presentation/pages/select-type'

function MakeSelectType(): JSX.Element {
  const history = useHistory()
  const { setIsheadeOffice } = useAuth()
  const handleSubmit = (values: any, actions: any): void => {
    actions.setSubmitting(true)
    if ((values?.env === 'Filial' || values?.env === 'Cliente')) {
      setIsheadeOffice(false)
      if (isAdmin()) {
        history.push(`select-branch#${values?.env}`)
      } else {
        history.push('select-branch')
      }
    } else {
      setIsheadeOffice(true)
      history.push('/')
    }
    actions.setSubmitting(false)
  }

  useEffect(() => {
    (async () => {
      const profileInfo = await Authentication.getProfileInfo()
      if (profileInfo?.isHeadOffice) {
        history.push('/')
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const options = [{ label: 'Matriz', value: 'Matriz' }, { label: 'Filial', value: 'Filial' }]
  // const optionsAdmin = [{ label: 'Matriz', value: 'Matriz' }]
  const optionsAdmin = [{ label: 'Matriz', value: 'Matriz' }, { label: 'Prestador de serviço', value: 'Filial' }, { label: 'Cliente', value: 'Cliente' }]
  return <SelectType handleSubmit={handleSubmit} typesRules={isAdmin() ? optionsAdmin : options} />
}

export default MakeSelectType
