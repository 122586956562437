/* eslint-disable react/jsx-indent */
import React from 'react'
import { CustomerProps } from '../../../../domain/models/customer'
import { ServiceProviderProps } from '../../../../domain/models/service-provider'
import { ItemField } from '../../../components/datagrid'
import Icons from '../../../components/icons-helper'
import { getColorByStatusEnabled } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { StatusStyled } from '../list/styles'
import { BoxResendPass } from '../../operators/internal/styles'
import ButtonComponent from '../../../components/button'

export const makeFields = (values: ServiceProviderProps | CustomerProps | any,
  editMode: boolean,
  resendPass: (e:any) => void): any[] => [
  {
    groupName: 'Dados da tarefa',
    groupLabel: 'Dados da tarefa',
    items: [
      {
        name: 'name',
        type: 'text',
        label: 'Nome',
        disabled: editMode,
        defaultValue: values?.name,
        placeholder: 'Digite o nome da Filial',
        validations: 'inputRequired',
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        name: 'username',
        type: 'text',
        label: 'Responsável',
        disabled: editMode,
        defaultValue: values?.user?.name,
        placeholder: 'Digite o nome do Operador',
        validations: 'inputRequired',
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        name: 'email',
        type: 'text',
        label: 'E-mail',
        disabled: values?.id,
        defaultValue: values?.user?.email,
        placeholder: 'Digite o E-mail',
        validations: 'inputRequired',
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        type: 'component',
        label: 'ResetPass',
        hide: !values?.id,
        disabled: editMode,
        defaultValue: values?.meetsRequirement,
        component:
            <BoxResendPass>
               <ButtonComponent
                 onClick={async () => {
                   await resendPass(true)
                 }}
                 type="button"
                 title="Resetar Senha"
                 color={theme.colors.white}
                 backgroundColor={theme.colors.green}
               />
            </BoxResendPass>,
        col: {
          md: 3,
          lg: 3,
        },
      },
      {
        name: 'enabled',
        type: 'toggle',
        label: 'Status',
        disabled: editMode,
        defaultValue: values?.enabled,
        validations: 'inputRequired',
        col: {
          md: 3,
          lg: 3,
        },
      },

    ],
  },
]

export const fields :ItemField[] = [
  { title: 'Filial', key: 'name', renderItem: (data:CustomerProps) => data.name },
  {
    title: 'Responsável',
    key: 'status',
    renderItem: (data:CustomerProps) => data.user?.name,
  },
  {
    title: 'E-mail',
    key: 'taskInProgress',
    renderItem: (data:CustomerProps) => data.user?.email,
  },
  { title: 'Status', key: 'status', renderItem: (data:CustomerProps) => <StatusStyled color={getColorByStatusEnabled(!!data.enabled)}>{data.enabled ? 'Ativo' : 'Inativo'}</StatusStyled> },
  {
    title: 'Prestador de Serviço',
    key: 'user',
    renderItem: (data:CustomerProps) => (data?.serviceProvider?.name ? `${data?.serviceProvider?.name}` : (
      <div style={{
        backgroundColor: theme.colors.red,
        color: theme.colors.white,
        borderRadius: 6,
        padding: 5,
      }}
      >
        Não Associado
      </div>
    )),
  },

]

export const fieldsModal = (uuid: string):ItemField[] => [
  { title: 'Filial', key: 'name', renderItem: (data:CustomerProps) => data.name },
  {
    title: 'Responsável',
    key: 'status',
    renderItem: (data:CustomerProps) => data.user?.name,
  },
  {
    title: 'E-mail',
    key: 'taskInProgress',
    renderItem: (data:CustomerProps) => data.user?.email,
  },
  { title: 'Status', key: 'status', renderItem: (data:CustomerProps) => <StatusStyled color={getColorByStatusEnabled(!!data.enabled)}>{data.enabled ? 'Ativo' : 'Inativo'}</StatusStyled> },

  {
    title: 'Vincular',
    key: 'user',
    renderItem: (data:CustomerProps) => (data?.serviceProvider?.name ? `${data?.serviceProvider?.name}` : (
      <div style={{
        paddingLeft: 15,
      }}
      >
        <Icons
          name={uuid === data?.uuid ? 'done' : 'clip'}
          width={uuid === data?.uuid ? 25 : 15}
          height={uuid === data?.uuid ? 25 : 15}
          color={uuid === data?.uuid ? theme.colors.green : theme.colors.purple}
        />
      </div>
    )),
  },
]
