import React from 'react'
import { Link } from 'react-router-dom'
import {
  BoxButton,
  Card, Content, ImageStyled, Title,
} from './styles'
import image from '../../../assets/images/sally.png'

const ViewTaskCard = ():JSX.Element => (
  <Card>
    <Content>
      <div className="d-flex align-items-end">
        <Title>
          Acompanhe as tarefas
          <span> com facilidade</span>
        </Title>
        <ImageStyled src={image} />
      </div>
      <Link to="/tasks">
        <BoxButton>
          Ver tarefas
        </BoxButton>
      </Link>
    </Content>
  </Card>
)
export default ViewTaskCard
