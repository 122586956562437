import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { branchByUserType, Roles, useAuth } from '../../../../contexts/auth-context/auth-context'
import Authentication from '../../../../infra/cognito/authentication'
import { isAdmin } from '../../../../presentation/helpers/utils'

import SelectBranch from '../../../../presentation/pages/select-branch'
import { makeRemoteAdmin } from '../../usecases/admin/remote-admin-factory'

export const discrimitorRole = (type: string): boolean => type === '#Filial'
function MakeSelectBranch(): JSX.Element {
  const [branchList, setBranchList] = useState<any>()
  const {
    user, setBranch, setIsheadeOffice, setUserLocal, setAdminLocal,
  } = useAuth()
  const history = useHistory()
  const branchByAdmin = (isServiceprovider: boolean):any => {
    if (isServiceprovider) {
      return makeRemoteAdmin().loadServiceProviders({
        size: 100,
        isHeadOffice: true,
      })
    }
    return makeRemoteAdmin().loadCustomers({
      size: 100,
      filters: 'headOffice=true',
    })
  }
  const handleSubmit = async (values: any, actions: any): Promise<void> => {
    actions.setSubmitting(true)
    if (isAdmin()) {
      await setBranch(values?.branch)
      await setIsheadeOffice(true)
      await setAdminLocal(user)
      setUserLocal({
        ...values?.branch,
        roles: [{
          authority: discrimitorRole(history.location?.hash)
            ? Roles.HEAD_OFFICE : Roles.CUSTOMER_HEAD_OFFICE,
        }],
      })
    } else {
      await setBranch(values?.branch)
      await setIsheadeOffice(false)
    }
    history.push('/')
    actions.setSubmitting(false)
  }

  useEffect(() => {
    (async () => {
      if (user?.roles[0]?.authority) {
        if (history.location?.hash) {
          const resp = await branchByAdmin(discrimitorRole(history.location?.hash))
          setBranchList(resp.content)
          return
        }

        const resp = await branchByUserType(user?.roles[0]?.authority, user)
        setBranchList(resp)
      }
    })()
  }, [history.location?.hash, user, user?.roles])

  useEffect(() => {
    (async () => {
      const profileInfo = await Authentication.getProfileInfo()
      if ((!profileInfo?.isHeadOffice && profileInfo?.branch)
       || (profileInfo?.isHeadOffice && !profileInfo?.branch)) {
        history.push('/')
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return <SelectBranch handleSubmit={handleSubmit} branchs={branchList} />
}

export default MakeSelectBranch
