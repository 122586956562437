/* eslint-disable react/jsx-indent */
import moment from 'moment'
import React, { useState } from 'react'
import { useAuth } from '../../../../contexts/auth-context/auth-context'
import { ServiceProviderProps } from '../../../../domain/models/service-provider'

import FormFactory from '../../../components/form-factory'
import Icons from '../../../components/icons-helper'
import { checkNotHeadOffice } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import {
  BackButton,
  ButtonAddMore,
  ContainerData,
  ContainerForm,
  TitleContainer, TitleStyled, TopContainer, Wrapper,
} from './styles'

type PageProps = {
  handleSubmit: (values: any, actions: any) => void
  serviceProvider: ServiceProviderProps | undefined
}

const RequestInternalPage = ({
  handleSubmit,
  serviceProvider,
}:PageProps):JSX.Element => {
  const { profile, user } = useAuth()
  const [fieldsControl, setFieldsControl] = useState<number[]>([0])

  const makeFields = ():any[] => [
    {
      groupName: 'Dados da tarefa',
      groupLabel: 'Dados da tarefa',
      items: [
        {
          name: 'requestTo',
          type: 'text',
          label: 'Solicitar para',
          disabled: true,
          defaultValue: serviceProvider?.name || profile?.branch?.serviceProvider?.name,
          placeholder: 'Selcione a filial',
          validations: 'inputRequired',

          col: {
            md: 3,
            lg: 3,
          },
        },
        {
          name: 'limiteDate',
          type: 'date',
          label: 'Data Limite',
          disabled: true,
          defaultValue: moment().add('day', 10).format('YYYY-MM-DD'),
          placeholder: 'Selcione o tipo de veículo',
          validations: 'inputRequired',
          col: {
            md: 3,
            lg: 3,
          },
        },

      ],
    },
  ]

  const makeFieldsTypes = ():any[] => [
    {
      groupName: 'Dados da tarefa',
      groupLabel: 'Entre com tipos o(s) documento(s)',
      items: [...fieldsControl.map((i) => ({
        name: `${i}`,
        type: 'text',
        disabled: false,
        placeholder: 'Digite o tipo de documento',
        validations: 'inputRequired',

        col: {
          md: 7,
          lg: 7,
        },
      })), {
        type: 'component',
        component: <ButtonAddMore
          type="button"
          onClick={() => setFieldsControl((prev) => [...prev, prev.length + 1])}
        >
          + Adicionar documento
                   </ButtonAddMore>,
        col: {
          md: 12,
          lg: 12,
        },
      }],
    },
  ]
  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>
          Solicitações de Documentos
        </TitleStyled>
      </TitleContainer>
      <ContainerForm>
        <TopContainer>
          <BackButton to="/tasks">
            <Icons
              name="arrow-left"
              width={12}
              height={12}
              color={theme.colors.black}
            />
            Voltar
          </BackButton>

        </TopContainer>

        <ContainerData>
          <FormFactory
            className="internal-form mb-3 "
            groups={((profile || checkNotHeadOffice(user?.roles[0]?.authority))
               && makeFields()) || []}
            button={{ submit: 'Salvar', submitting: 'Salvando...', hide: true }}
            onSubmit={async () => null}
          />
          <FormFactory
            className="internal-form mb-3 "
            groups={((profile || checkNotHeadOffice(user?.roles[0]?.authority))
              && makeFieldsTypes()) || []}
            button={{ submit: 'Salvar e enviar solicitação', submitting: 'Salvando...' }}
            onSubmit={async (values:any, actions:any) => {
              await handleSubmit(values, actions)
            }}
          />
        </ContainerData>

      </ContainerForm>
    </Wrapper>
  )
}
export default RequestInternalPage
