/* eslint-disable no-nested-ternary */
import React, { useState } from 'react'
import { Roles, useAuth } from '../../../../contexts/auth-context/auth-context'
import { CustomerProps, CustomerResponseModel } from '../../../../domain/models/customer'
import { FilterModel } from '../../../../domain/models/enum'
import DataGrid, { ItemField } from '../../../components/datagrid'
import FilterBar from '../../../components/filter-bar'
import Icons from '../../../components/icons-helper'
import { getColorByStatusEnabled } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { ExportButtonComponentStyled } from '../../task/task-list/styles'
import {
  ButtonComponentStyled,
  ButtonStyled,
  CheckboxStyled,
  ContainerButton,
  FieldCheckbox,
  FilterStyled,
  SperatorStyled,
  StatusStyled, TitleContainer,
  TitleStyled, TitleStyledFilter, Wrapper,
} from './styles'

type PageProps = {
  list: CustomerResponseModel | undefined
  setParams: any
  params: any
  loading?:boolean
  exportList?: ()=> Promise<void>

}

type FilterComponentProps = {
  submit:(value:string)=>void
  params: any
}

const FilterComponent = ({ submit, params }:FilterComponentProps):JSX.Element => {
  const [values, setValues] = useState<any>({ ...params })

  const handleChange = (e:any):void => {
    setValues(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }

  const applyFilter = (): void => {
    submit(values)
  }

  return (
    <FilterStyled>
      <SperatorStyled />
      <TitleStyledFilter>
        Status
      </TitleStyledFilter>
      <FieldCheckbox>
        <CheckboxStyled
          name="enabled=false"
          value="false"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Inativo
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="true"
          name="enabled=true"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Ativo
      </FieldCheckbox>
      <ButtonStyled onClick={() => applyFilter()}>Aplicar</ButtonStyled>
    </FilterStyled>
  )
}
const CustomerListPage = ({
  list, setParams, params, loading, exportList,
}:PageProps):JSX.Element => {
  const { user } = useAuth()
  const fields :ItemField[] = [
    { title: 'Filial', key: 'name', renderItem: (data:CustomerProps) => data.name },
    { title: 'Status', key: 'status', renderItem: (data:CustomerProps) => <StatusStyled color={getColorByStatusEnabled(!!data.enabled)}>{data.enabled ? 'Ativo' : 'Inativo'}</StatusStyled> },
    {
      title: 'Responsável',
      key: 'status',
      renderItem: (data:CustomerProps) => data.user?.name,
    },
    {
      title: 'Filiais', hide: user?.roles[0]?.authority !== Roles.ADMIN, key: 'user', renderItem: (data:CustomerProps) => `${data?.branchOfficeNames?.substring(0, 35)}...` || '--',
    },
    {
      title: 'Tarefas realizadas',
      key: 'taskInProgress',
      renderItem: (data:CustomerProps) => data.taskCompleted,
    },

  ]

  const handlePage = (e:any):void => {
    setParams((prev: FilterModel) => ({
      ...prev,
      page: e,
    }))
  }

  const handleSearch = (callback:any):void => {
    const val = callback()

    setParams(
      (prev: any) => ({
        ...prev,
        filters: { ...prev?.filters, name: val.query },
      }
      ),
    )
  }

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>Cliente</TitleStyled>
      </TitleContainer>
      <FilterBar
        handleFilter={handleSearch}
        filterComponent={(
          <FilterComponent
            params={params}
            submit={(values: any) => setParams((prev: any) => ({
              ...prev,
              filters: { ...prev?.filters, ...values },
            }))}
          />
)}
      >
        <ContainerButton>
          <ButtonComponentStyled to="/customer/create">
            <Icons
              name="plus"
              width={15}
              height={15}
              color={theme.colors.white}
            />
            Novo cliente
          </ButtonComponentStyled>
          <ExportButtonComponentStyled onClick={async () => {
            if (exportList) {
              await exportList()
            }
          }}
          >
            Exportar
          </ExportButtonComponentStyled>
        </ContainerButton>
      </FilterBar>
      <DataGrid
        loading={!!loading}
        fields={fields}
        data={list?.content || []}
        routeToEdit="/customer/"
        totalElements={list?.totalElements || 0}
        currentPage={list?.number || 0}
        totalPages={list?.totalPages || 0}
        changePage={handlePage}
      />

    </Wrapper>
  )
}
export default CustomerListPage
