import React from 'react'
import { BackgroudStyled } from '../../../pages/select-branch/styles'
import { ContainerStyled, RowStyled } from './styles'
// import { Container } from './styles'

const LoginLayout = ({ children }: any): JSX.Element => (
  <BackgroudStyled>
    <ContainerStyled fluid>
      <RowStyled className="h-100">
        {children}
      </RowStyled>
    </ContainerStyled>
  </BackgroudStyled>
)

export default LoginLayout
