// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../../domain/models/enum'
import { LaborDocsResponseProps, OperatorProps } from '../../../../../domain/models/operator'
import { TaskReponseModel } from '../../../../../domain/models/task'
import { t } from '../../../../../presentation/adapters/translate/translate'
import OperatorInternalPage from '../../../../../presentation/pages/operators/internal'
import { settings } from '../../../../configs/settings'
import { makeRemoteOperator } from '../../../usecases/operator/remote-operator-factory'
import { makeRemoteTask } from '../../../usecases/task/remote-task-factory'

const generateFilter = (values: any): string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'startedAt' || el === 'completedAt') {
      return `${el}>=${values[el]}`
    }
    if (el === 'assigneeOperator') {
      return `${el}=${values[el]}`
    }
    if (el === 'serviceProvider') {
      return `${el}=${values[el]}`
    }
    if (el === 'operator') {
      return `${el}=${values[el]}`
    }
    return el
  }).join(',')

function MakeOperatorInternal({ match }: any): JSX.Element {
  const { handleAlert } = useAlertBox()
  const [data, setData] = useState<OperatorProps>()
  const history = useHistory()
  const { profile, user } = useAuth()

  const [listTasks, setListTasks] = useState<TaskReponseModel>()
  const [paramsTasks, setParamsTasks] = useState<FilterModel>()
  const [loadingTasks, setLoadingTasks] = useState<boolean>()
  const [documents, setDocuments] = useState<LaborDocsResponseProps>()

  const loadDataTasks = async (cuuid: string): Promise<void> => {
    setLoadingTasks(true)
    const datatask = await makeRemoteTask().load({
      ...paramsTasks,
      size: settings.perPage,
      sort: 'serviceDate,desc',
      query: paramsTasks?.query,
      filters: generateFilter({
        ...paramsTasks?.filters,
        ...(cuuid && { assigneeOperator: cuuid }),
        serviceProvider: profile?.branch?.uuid || user.uuid,
      }),
    })
    setListTasks(datatask)
    setLoadingTasks(false)
  }

  useEffect(() => {
    if (data?.cognitoUsername) {
      loadDataTasks(data?.cognitoUsername)
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [paramsTasks, data?.cognitoUsername])

  const loadData = async (): Promise<void> => {
    try {
      const content = await makeRemoteOperator().loadById(match?.params?.id)
      setData(content)
      if (content?.cognitoUsername) {
        const docs = await makeRemoteOperator().getLaborDocuments(content?.cognitoUsername)
        setDocuments(docs)
      }
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (async () => {
      if (match?.params?.id && match?.params?.id !== 'create') {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const handleSubmit = async (values: any, actions: any): Promise<any> => {
    console.log('********', values)
    try {
      if (data?.id) {
        const updatedData = await makeRemoteOperator().update(data?.id, {
          ...data,
          cpf: values?.cpf,
          ctps: values?.ctps,
          email: values?.email,
          drive: values?.drive,
          meetsRequirement: values?.meetsRequirement,
          name: values?.name,
          phoneNumber: values?.phoneNumber,
          pis: values?.pis,
          serviceProvider: {
            id: profile?.branch?.id || user?.id,
          },
          ...(values?.asoDate
              && { asoDate: values?.asoDate }),
        })
        if (data?.id && documents?.id) {
          const docs = await makeRemoteOperator().updateLaborDocuments(documents?.id, {
            ...(values?.occupationalHealthCertificatePath_file
              && { occupationalHealthCertificate: values?.occupationalHealthCertificatePath_file }),
            operator: { id: data?.id || 0 },
            ...(values?.proofOfAddressPath_file
              && { proofOfAddress: values?.proofOfAddressPath_file }),
            ...(values?.epiSheet_file && { epiSheet: values?.epiSheet_file }),
            ...(values?.evidenceOfTrainingFISPQ_file
              && { evidenceOfTrainingFISPQ: values?.evidenceOfTrainingFISPQ_file }),
            ...(values?.workContract_file && { workContract: values?.workContract_file }),
            ...(values?.aso_file && { aso: values?.aso_file }),
            ...(values?.orderOfService_file && { orderOfService: values?.orderOfService_file }),
            ...(values?.driverLicense_file && { driverLicense: values?.driverLicense_file }),
            ...(values?.registrationForm_file
              && { registrationForm: values?.registrationForm_file }),
            ...(values?.evidenceOfLupiAmbev_file
              && { evidenceOfLupiAmbev: values?.evidenceOfLupiAmbev_file }),
          })
          setDocuments(docs)
        } else {
          await makeRemoteOperator().sendLaborDocuments({
            ...(values?.occupationalHealthCertificatePath_file
              && { occupationalHealthCertificate: values?.occupationalHealthCertificatePath_file }),
            operator: { id: data?.id || 0 },
            ...(values?.proofOfAddressPath_file
              && { proofOfAddress: values?.proofOfAddressPath_file }),
            ...(values?.epiSheet_file && { epiSheet: values?.epiSheet_file }),
            ...(values?.evidenceOfTrainingFISPQ_file
              && { evidenceOfTrainingFISPQ: values?.evidenceOfTrainingFISPQ_file }),
            ...(values?.workContract_file && { workContract: values?.workContract_file }),
            ...(values?.aso_file && { aso: values?.aso_file }),
            ...(values?.orderOfService_file && { orderOfService: values?.orderOfService_file }),
            ...(values?.driverLicense_file && { driverLicense: values?.driverLicense_file }),
            ...(values?.registrationForm_file
              && { registrationForm: values?.registrationForm_file }),
            ...(values?.evidenceOfLupiAmbev_file
              && { evidenceOfLupiAmbev: values?.evidenceOfLupiAmbev_file }),
          })
          history.push(`/operators/${data.id}`)
        }
        setData(updatedData)
      } else {
        if (!values?.email) {
          handleAlert({
            show: true,
            type: 'error',
            message: 'Erro ao Enviar E-mail para o servidor! Tente novamente',
          })
          actions.setSubmitting(false)
          return
        }
        const createData = await makeRemoteOperator().create({
          cpf: values?.cpf,
          ctps: values?.ctps,
          email: values?.email,
          drive: values?.drive,
          meetsRequirement: values?.meetsRequirement,
          name: values?.name,
          phoneNumber: values?.phoneNumber,
          pis: values?.pis,
          serviceProvider: {
            id: profile?.branch?.id || user?.id,
          },
          enabled: values?.enabled,
          ...(values?.asoDate
              && { asoDate: values?.asoDate }),
        })
        if (createData?.id) {
          await makeRemoteOperator().sendLaborDocuments({
            ...(values?.occupationalHealthCertificatePath_file
              && { occupationalHealthCertificate: values?.occupationalHealthCertificatePath_file }),
            operator: { id: createData?.id || 0 },
            ...(values?.proofOfAddressPath_file
              && { proofOfAddress: values?.proofOfAddressPath_file }),
            ...(values?.epiSheet_file && { epiSheet: values?.epiSheet_file }),
            ...(values?.evidenceOfTrainingFISPQ_file
              && { evidenceOfTrainingFISPQ: values?.evidenceOfTrainingFISPQ_file }),
            ...(values?.workContract_file && { workContract: values?.workContract_file }),
            ...(values?.aso_file && { aso: values?.aso_file }),
            ...(values?.orderOfService_file && { orderOfService: values?.orderOfService_file }),
            ...(values?.driverLicense_file && { driverLicense: values?.driverLicense_file }),
            ...(values?.registrationForm_file
              && { registrationForm: values?.registrationForm_file }),
            ...(values?.evidenceOfLupiAmbev_file
              && { evidenceOfLupiAmbev: values?.evidenceOfLupiAmbev_file }),
          })
          history.push(`/operators/${createData.id}`)
        }
      }

      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error?.userMessage || error?.message),
      })
      actions.setSubmitting(false)
    }
  }

  return (
    <OperatorInternalPage
      data={data}
      handleSubmit={handleSubmit}
      listTasks={listTasks}
      setParamsTasks={setParamsTasks}
      loadingTasks={loadingTasks}
      documents={documents}
    />
  )
}

export default MakeOperatorInternal
