import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { ServiceProviderProps } from '../../../../../domain/models/service-provider'
import { t } from '../../../../../presentation/adapters/translate/translate'
import RequestInternalPage from '../../../../../presentation/pages/upload-documents/request-document'
import { makeRemoteCustomer } from '../../../usecases/customer/remote-customer-factory'
import { makeRemoteDocuments } from '../../../usecases/documents/remote-documents-factory'

function MakeRequestDocument(): JSX.Element {
  const { profile, user } = useAuth()
  const { handleAlert } = useAlertBox()
  const history = useHistory()
  const [sp, setSp] = useState<ServiceProviderProps>()

  useEffect(() => {
    (async () => {
      const res = await makeRemoteCustomer().getServiceProviderByCustomer({
        customerUUID: profile?.branch?.uuid || user?.uuid,
      })
      setSp(res[0])
    })()
  }, [profile?.branch?.uuid, user?.uuid])

  const handleSubmit = async (values: any, actions: any): Promise<any> => {
    try {
      await makeRemoteDocuments().createRequest({
        customer: {
          id: profile?.branch?.id || user?.id,
        },
        deadLine: moment(values?.limiteDate).add('day', 30).format('YYYY-MM-DD'),
        documents: Object.values(values)?.filter((el) => el !== '')?.map((el: any) => ({
          documentType: el,
        })),
        serviceProvider: {
          id: sp?.id || profile?.branch?.serviceProvider?.id,
        },
      })
      history.push('/upload-documents')

      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error?.userMessage || error?.message),
      })
      actions.setSubmitting(false)
    }
  }

  return (
    <RequestInternalPage
      handleSubmit={handleSubmit}
      serviceProvider={sp}
    />
  )
}

export default MakeRequestDocument
