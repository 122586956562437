import React, { useEffect, useState } from 'react'
import {
  Card, Content, IconStyled, Title,
} from './styles'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import { makeRemoteOperator } from '../../../../main/factories/usecases/operator/remote-operator-factory'

type CardProps = {
  period: string
  isHeadOffice: boolean
  serviceProvider?: string | undefined
  customer?: string | undefined
}

const OperatorCard = ({
  period, isHeadOffice, serviceProvider, customer,
}:CardProps):JSX.Element => {
  const [data, setData] = useState<number>(0)

  useEffect(() => {
    (async () => {
      const response = await makeRemoteOperator().activeCount({
        period,
        isHeadOffice: isHeadOffice || false,
        serviceProvider,
        customer,
      })
      setData(response)
    })()
  }, [period, isHeadOffice, serviceProvider, customer])

  return (
    <Card>
      <Content>
        <IconStyled>
          <Icons name="peoples" width={26} height={26} color={theme.colors.white} />
        </IconStyled>
        <Title>
          Operadores ativos
        </Title>
        {data}
      </Content>
    </Card>
  )
}
export default OperatorCard
