import React, { useEffect, useState } from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/auth-context/auth-context'
import { CustomerProps } from '../../../domain/models/customer'
import { makeRemoteCustomer } from '../../../main/factories/usecases/customer/remote-customer-factory'
import {
  RatingsCard,
  TaskByBranch,
  TasksStatus,
  UploadDocumentsCard,
} from '../../components/dashboards'
import CountTasksCustomer from '../../components/dashboards/completed-tasks'
import TaskListCard from '../../components/dashboards/tasks-list'
import ViewTaskCard from '../../components/dashboards/view-tasks'
import FormFactory from '../../components/form-factory'
import Icons from '../../components/icons-helper'
import PeriodFilter from '../../components/period-filter'
import { theme } from '../../styles/theme'
import {
  BackButton,
  ColStyled, ContainerModal, ContinueButton, RowStyled, RowTitleStyled, SplitStyled, Title, Wrapper,
} from './styles'

const CustomerDashboardPage = ():JSX.Element => {
  // const [show, setShow] = useState<boolean>(true)
  const [period, setPeriod] = useState<string>('month')
  const [customers, setCustomers] = useState<CustomerProps[]>()
  const [params, setParams] = useState<any>()
  const {
    profile, setBranch, setIsheadeOffice, user,
  } = useAuth()
  const history = useHistory()

  useEffect(() => {
    (async () => {
      const customerList = await makeRemoteCustomer().branchs({
        customerUUID: profile?.uuid || user?.uuid,
        isHeadOffice: true,
      })
      setCustomers(customerList)
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const show = !!profile?.branch?.openModal
  const makeFields = ():any => ([{
    groupName: 'filter',
    // groupLabel: 'Selecione um Operador para ver o seu estoque',
    items: [
      {
        name: 'operator',
        type: 'select',
        // label: 'Selecione um Operador para ver o seu estoque',
        // defaultValue: values?.operator?.id,
        placeholder: 'Filtre por filial',
        validations: 'objectRequired',
        options: customers,
        labelKey: 'name',
        onChange: (val: any) => { setParams(val) },
        valueKey: 'uuid',
        col: {
          md: 12,
          lg: 12,
        },
      }],
  }])

  return (
    <Wrapper>
      <RowTitleStyled>
        <Row className="w-100">
          <Col xs={3}>
            <Title>
              Dashboard
            </Title>
          </Col>
          {profile?.isHeadOffice && (
          <Col xs={6}>
            <FormFactory
              className="internal-form form-filter mt-2"
              groups={makeFields()}
              button={{ submit: 'Salvar', submitting: 'Salvando...', hide: true }}
              onSubmit={() => null}
            />
          </Col>
          )}
        </Row>

        <PeriodFilter callback={(callback:string) => setPeriod(callback)} />
      </RowTitleStyled>
      <RowStyled>
        {!profile?.branch && (
        <ColStyled width={48}>
          <TaskByBranch period={period} customer={profile?.branch?.uuid || user?.uuid} />
        </ColStyled>
        )}
        {profile?.branch && (
        <ColStyled width={36}>
          <ViewTaskCard />
        </ColStyled>
        )}
        <ColStyled width={40}>
          <TaskListCard
            period={period}
            customer={profile?.branch?.uuid || user.uuid}
          />
        </ColStyled>
        <ColStyled width={18}>
          <SplitStyled>
            <CountTasksCustomer
              period={period}
              isHeadOffice={!profile?.branch && profile?.isHeadOffice && !params}
              customer={profile?.branch?.uuid || params?.uuid}
            />
          </SplitStyled>
          <SplitStyled>
            <RatingsCard
              period={period}
              customer={profile?.branch?.uuid || params?.uuid}
            />
          </SplitStyled>
        </ColStyled>

      </RowStyled>
      <RowStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="TODO"
            period={period}
            isHeadOffice
            customer={profile?.branch?.uuid || params?.uuid}
          />
        </ColStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="IN_PROGRESS"
            period={period}
            isHeadOffice
            customer={profile?.branch?.uuid || params?.uuid}
          />
        </ColStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="COMPLETED"
            period={period}
            isHeadOffice
            customer={profile?.branch?.uuid || params?.uuid}
          />
        </ColStyled>
        <ColStyled width={46}>
          <UploadDocumentsCard
            period={period}
            isHeadOffice={!profile?.branch && profile?.isHeadOffice && !params}
            uuid={profile?.branch?.uuid || params?.uuid || user?.uuid}
          />
        </ColStyled>
      </RowStyled>
      <Modal
        show={show}
        onHide={async () => {
          await setBranch({ ...profile?.branch, openModal: false })
        }}
        className="modal-branch"
      >
        <Modal.Body>
          <ContainerModal>
            <Icons
              name="caution"
              width={200}
              height={200}
              color={theme.colors.yellow}
            />
            <span>Você estará visualizando:</span>
            <h5>
              Filial
              {' '}
              {profile?.branch?.name}
            </h5>
            <ContinueButton onClick={async () => {
              await setBranch({ ...profile?.branch, openModal: false })
            }}
            >
              Prosseguir
            </ContinueButton>
            <BackButton onClick={async ():Promise<void> => {
              await setBranch(null)
              await setIsheadeOffice(true)
              history.goBack()
            }}
            >
              Voltar
            </BackButton>
          </ContainerModal>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}
export default CustomerDashboardPage
