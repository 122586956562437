import React from 'react'
import Icons from '../../components/icons-helper'
import { theme } from '../../styles/theme'
import {
  Card, ChangePassword, Title, Wrapper,
} from './styles'

const SettingsPage = ():JSX.Element => (
  <Wrapper>
    <Title>Configurações</Title>
    <div className="mt-2">
      <Card>
        <ChangePassword to="/change-password">
          Trocar Senha
          <Icons
            name="arrow-right"
            width={20}
            height={20}
            color={theme.colors.primary}
          />
        </ChangePassword>

      </Card>

    </div>
  </Wrapper>
)
export default SettingsPage
