import React, { Component } from 'react'
import Icons from '../icons-helper'
import { ButtonWrapper } from './styled'

type ButtonProps = {
  color?: string
  backgroundColor?: string
  disabled?: boolean
  title: string
  type?: 'submit' | 'button'
  icon?: string | Component
  onClick?: ()=> void
}

const ButtonComponent = ({
  title, color, backgroundColor, disabled, type, icon, onClick,
}:ButtonProps):JSX.Element => (
  <ButtonWrapper
    type={type}
    backgroundColor={backgroundColor}
    color={color}
    disabled={disabled}
    onClick={onClick}
  >
    {typeof icon === 'string' && <Icons name={icon} width={20} height={20} color={color} />}
    {title}
  </ButtonWrapper>
)
export default ButtonComponent
