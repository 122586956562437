import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Wrapper = styled.div`
  width: calc(100% - 40px);
  display: flex;
  margin: 0 20px;
  flex-direction: column;
`

export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;

  .forget-password-link{
    width: 100%;
    text-align:end;
    font-size: ${theme.font.sizes.xxxsmall};
    color: ${theme.colors.white};
    text-decoration: underline;
    margin-right: 20px;
    margin-bottom: 18px;
    transition: ${theme.transition.default};

    :hover{
      filter: brightness(0.85);
    }
  }

  .register-link{
    color: ${theme.colors.white};
    font-family: ${theme.font.montserrat};
    font-weight: ${theme.font.semiBold};
    font-size: ${theme.font.sizes.xxsmall};
    text-decoration: none;
    margin-top: 26px;
    text-align:center;
    transition: ${theme.transition.default};

    :hover{
      filter: brightness(0.85);
    }
  }
`

export const Card = styled.div`
  box-shadow:  0px 7px 50px rgba(210, 214, 227, 0.4);
  background-color: ${theme.colors.white};
  border-radius: 12px;
  width: 30%;
  padding: 15px 30px;
  a{
    color: ${theme.colors.black};
    &:hover{
      opacity: .5;
    }
  }
  /* min-height: 200px; */
`
export const Title = styled.h2`
  color: ${theme.colors.black};
  font-family: ${theme.font.palanquin};
  font-weight: ${theme.font.normal};
  font-size: ${theme.font.sizes.xlarge};
  margin-bottom: 15px;
`
export const ChangePassword = styled(Link)`
  color: ${theme.colors.black};
  font-family: ${theme.font.poppins};
  font-weight: ${theme.font.normal};
  font-size: 1rem;
  margin-bottom: 15px;
  margin-top: 50px;
  svg{
    margin-left: 30px;
  }
`
