import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const Card = styled.div`
  box-shadow:  0px 7px 50px rgba(210, 214, 227, 0.4);
  background-color: ${theme.colors.white};
  border-radius: 24px;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 30px;
`

export const BoxButton = styled.button`
  width: 70%;
  background-color: ${theme.colors.green};
  border-radius: 24px;
  padding: 10px 15px;
  justify-content: center;
  display: flex;
  font-size: 12px;
  align-items: center;
  color: ${theme.colors.white};
  text-decoration: none;
  transition:  ease all 400ms;
  &:hover{
    opacity: .7;
  }
`
export const ImageStyled = styled.img`
  width: 75%;
  align-self: flex-end;
`

export const Title = styled.h2`
  color: ${theme.colors.black};
  font-family: ${theme.font.palanquin};
  font-weight: ${theme.font.semiBold};
  font-size: 1.7rem;
  letter-spacing: 1px;
  margin-top: 12px;
  margin-bottom: 15px;
  width: 75%;
`
