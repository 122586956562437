import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Wrapper = styled.div`
  box-shadow: 0px 0px 25px rgba(171, 171, 171, 0.15);
  margin-top: 10px;
  margin: 15px;
  border-radius: 20px;
  display: flex;
  align-items: center;
  padding: 15px;
  justify-content: space-between;
`
export const ListStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
export const ActionBar = styled.div`
  display: flex;
  font-weight: 500;
  font-family: "poppins";
  font-size: 0.8rem;
  color:${theme.colors.black};
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 15px 15px 10px 15px;
  cursor: text;
`
export const CloseButton = styled.button`
  display: flex;
  background-color: ${theme.colors.white};
  cursor: pointer;
`
export const BoxComponentStyled = styled.div`
  display: flex;
  justify-content: space-between;
`
export const BoxFilterContainer = styled.div`
  display: flex;
  position: relative;
  max-width: 300px;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.lightGray};
  width: 43px;
  height: 43px;
  border-radius: 50%;
  cursor: pointer;
  `
export const BoxSearchContainer = styled.div`
  margin-right: 15px;
  display: flex;
  position: relative;
  min-width: 300px;
  svg {
    position: absolute;
    right: 15px;
    top: 10px;
  }
`

export const InputSearch = styled.input`
  display: flex;
  justify-content: space-between;
  border: 1px solid ${theme.colors.middleGray};
  border-radius: 100px;
  padding: 7px 25px;
  width: 100%;
  font-weight: 300;
  font-family: "poppins";
  font-size: 0.8rem;
`
type ItemProp = {
  selected: boolean;
};

export const ItemStyled = styled.span`
  font-family: 'poppins';
  font-weight: 400;
  font-size: 0.6rem;
  color: ${theme.colors.middleGray};
  padding: 15px 15px 10px 15px;
  cursor: pointer;
  width: 0;
  border-bottom: ${(p: ItemProp) => (p.selected ? `3px solid ${theme.colors.primary}` : '2px solid #EFEFEF')};
`
type DropDownProps ={
  open: boolean
}

export const DropDownStyled = styled.div`
  display:flex;
  align-items: center;
  border-radius: 12px;
  box-shadow: 0px 7px 50px rgb(210 214 227 / 40%);
  padding:0px 0px;
  position: absolute;
  background-color: #fff;
  max-width: 312px;
  width: auto;
  top: calc(100% + 15px);
  right: 0;
  flex-direction: column;
  opacity: ${(p:DropDownProps) => (p.open ? 1 : 0)};
  visibility: ${(p:DropDownProps) => (p.open ? 'visible' : 'hidden')};
  transition: ease all 400ms;
  border: 0.5px solid #EFEFEF;
  z-index: 9;

  &:before{
    content:'';
    border-left: 15px solid transparent;
    border-right: 15px solid transparent;
    border-bottom: 17px solid #ffffff;
    width: 0;
    height: 0;
    position: absolute;
    top: -17px;
    right: 25px;
    /* border-radius: 26px; */
    box-shadow: 0px 7px 50px 0px rgb(210 214 227 / 40%);
  }
`
