import React, { useState } from 'react'
import { Col, Row } from 'react-bootstrap'
import FormFactory from '../../../components/form-factory'
import {
  BackgroudStyled,
  BallonDoc,
  BallonImage,
  BoxImage,
  CardForm,
  ColStyled,
  ForgotPass,
  ImageAuthStyled,
  LogoImage,
  SubTitle,
  Title,
  Wrapper,
} from './styles'
import placeholder from '../../../assets/images/placeholder-auth.png'
import logo from '../../../assets/images/logo.png'

type PageProps ={
  handleSubmit: (values: any, actions: any, setStep:any) => void
}

const Signin = ({ handleSubmit }:PageProps):JSX.Element => {
  const [step, setStep] = useState<number>(0)
  const stepOne = [
    {
      groupName: 'Login',
      items: [
        {
          name: 'email',
          type: 'email',
          placeholder: 'Digite seu E-mail',
          validations: 'emailRequired',
        },
        {
          name: 'password',
          type: 'password',
          placeholder: 'Digite sua senha',
          validations: 'passwordMinRequired',
        },
        {
          name: 'keepConnect',
          type: 'checkbox',
          label: 'Manter-me conectado',
          placeholder: 'Manter-me conectado',
        },
      ],
    },
  ]

  const stepTwo = [
    {
      groupName: 'Login',
      items: [
        {
          name: 'password',
          type: 'password',
          placeholder: 'Digite sua nova senha',
          validations: 'passwordMinRequired',
        },

      ],
    },
  ]

  return (
    <Wrapper>
      <Row>
        <Col xs={6} lg={6} className="p-0">
          <BackgroudStyled>
            <BoxImage>
              <BallonDoc />
              <ImageAuthStyled src={placeholder} />
              <BallonImage />
            </BoxImage>
          </BackgroudStyled>
        </Col>
        <ColStyled xs={6} lg={6}>
          <CardForm>
            <LogoImage src={logo} />
            {!step && (
            <Title>
              Seja bem vindo!
            </Title>
            )}
            <SubTitle>
              {step ? 'Entre com uma nova senha para continuar' : 'Faça seu login para continuar'}
            </SubTitle>
            <FormFactory
              groups={(!step && stepOne) || stepTwo}
              onSubmit={async (values, actions) => {
                try {
                  await handleSubmit(values, actions, setStep)
                  actions.setSubmitting(false)
                } catch (error) {
                  actions.setSubmitting(false)
                }
              }}
              button={{ submit: step ? 'Continuar' : 'Entrar', submitting: 'Entrando...' }}
            />
            {!step && (<ForgotPass to="/forgot-pass">Esqueceu sua Senha?</ForgotPass>)}
          </CardForm>
        </ColStyled>
      </Row>

    </Wrapper>
  )
}
export default Signin
