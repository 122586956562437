import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Wrapper = styled.div`
  box-shadow: 0px 0px 25px rgba(171, 171, 171, 0.15);
  margin-top: 10px;
  margin: 15px;
  border-radius: 20px;
`
export const GridStyled = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
`
export const PaginateInfo = styled.span`
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  font-family:'poppins';
  font-weight: 400;
  font-size: 0.8rem;
  color: ${theme.colors.middleGray};
`

export const HeaderStyled = styled.div`
  font-family:'poppins';
  font-weight: 400;
  font-size: 0.6rem;
  color: ${theme.colors.middleGray};
  padding: 15px 15px 10px 15px;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  width: 100%;
`

export const CelTable = styled.span`
  padding: 10px 15px 10px 10px;
  overflow: hidden;
  height: 50px;
  font-family: "poppins";
  color: ${theme.colors.darkGray};
  font-size: 0.8rem;
  font-weight: 400;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  flex: 1 0 0;
  align-items: center;
  display: flex;
`
type RowProp = {
  index: number;
};

export const RowTable = styled.div`
  padding: 0px 10px;
  width: 100%;
  display: flex;
  background-color: ${(p: RowProp) => (p.index % 2 === 0 ? theme.colors.lightGray : '#efefef7a')};
  max-height: 55px;
  border-radius: 16px;
  margin-bottom: 10px;
  cursor: pointer;
`
export const Itemheader = styled.span`
  padding: 10px 10px;
  width: 100%;
  font-family: "poppins";
  color: ${theme.colors.black};
  font-size: 0.7rem;
  font-weight: 600;
`

export const BodyTableStyled = styled.div`
  padding: 10px 10px;
`
export const PaginateStyled = styled.div`
  padding: 10px 30px;
  display: flex;
  justify-content: space-between;
  ul {
    list-style: none;
    display: flex;

    li {
      font-family: "poppins";
      font-weight: 400;
      font-size: 0.8rem;
      padding: 0px;
      color: ${theme.colors.darkGray};
      display: flex;
      align-items: center;
      cursor: pointer;
      transition: ease all 50ms;
      :hover,:hover a {
        background-color: ${theme.colors.green};
        color: ${theme.colors.white};
        opacity: 0.8;
        border-radius: 6px;
      }
      margin: 0 2px;
    }
    a{
      width: 20px;
      justify-content: center;
      display: flex;
    }
    .previous,
    .next {
      a {
        font-size: 1rem;
        color: ${theme.colors.darkGray};
        padding: 0;
      }
    }
    .selected {
      background-color: ${theme.colors.primary};
      color: ${theme.colors.white};
      border-radius: 6px;
    }
  }
`
