import React from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { Roles, useAuth } from '../../../../contexts/auth-context/auth-context'
import Authentication from '../../../../infra/cognito/authentication'
import { checkIsCustomer } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import {
  ContainerMenu, ExitMenu, ItemMenu, MenuTop, TitleMenu, Wrapper,
} from './styled'

type ItemMenuProp ={
  title: string
  icon: string
  url: string
  allow: string[]
}

type ComponentProp = {
  open: boolean
}

const MenuComponent = ({ open }:ComponentProp):JSX.Element => {
  const location = useLocation()
  const { user, profile } = useAuth()
  const history = useHistory()
  console.log('>>>', profile, user)
  const menuOptions:ItemMenuProp[] = [
    {
      title: 'Dashboard',
      icon: 'data-sheet',
      url: '/',
      allow: [Roles.ADMIN,
        Roles.HEAD_OFFICE,
        Roles.BRANCH_OFFICE,
        Roles.CUSTOMER_BRANCH_OFFICE,
        Roles.CUSTOMER_HEAD_OFFICE],
    },
    {
      title: 'Prestador de Serviço',
      icon: 'provider',
      url: '/service-provider',
      allow: [Roles.ADMIN],
    },
    {
      title: 'Cliente',
      icon: 'car',
      url: '/customer',
      allow: [Roles.ADMIN],
    },
    {
      title: 'Filiais',
      icon: 'car',
      url: '/service-provider',
      allow: [
        profile?.isHeadOffice && Roles.CUSTOMER_HEAD_OFFICE,
        profile?.isHeadOffice && Roles.HEAD_OFFICE,
      ],
    },
    {
      title: 'Operadores',
      icon: 'peoples',
      url: '/operators',
      allow: [
        Roles.BRANCH_OFFICE,
        !profile?.isHeadOffice && Roles.HEAD_OFFICE,
        Roles.BRANCH_OFFICE,
      ]
      ,
    },
    {
      title: 'Tarefas',
      icon: 'books',
      url: '/tasks',
      allow: [
        !profile?.isHeadOffice && Roles.HEAD_OFFICE,
        Roles.BRANCH_OFFICE,
        !profile?.isHeadOffice && Roles.CUSTOMER_HEAD_OFFICE,
        Roles.CUSTOMER_BRANCH_OFFICE],
    },
    {
      title: checkIsCustomer() ? 'Documentos' : 'Upload documentos',
      icon: 'folder',
      url: '/upload-documents',
      allow: [
        !profile?.isHeadOffice && Roles.HEAD_OFFICE,
        Roles.BRANCH_OFFICE,
        Roles.BRANCH_OFFICE,
        Roles.CUSTOMER_BRANCH_OFFICE,
        !profile?.isHeadOffice && Roles.CUSTOMER_HEAD_OFFICE],
    },
    {
      title: 'Estoque produtos',
      icon: 'makeups',
      url: '/stock',
      allow: [
        Roles.BRANCH_OFFICE,
        !profile?.isHeadOffice && Roles.HEAD_OFFICE,
        Roles.BRANCH_OFFICE],
    },

  ]

  return (
    <Wrapper>
      <ContainerMenu>
        <MenuTop>
          {menuOptions.filter(
            (el) => el.allow.find((item) => item === user?.roles[0].authority),
          )
            .map((el) => {
              const selected = ((el.url !== '/' && location.pathname.includes(el.url)) || location.pathname === el.url)
              return (
                <ItemMenu to={el.url} selected={selected} key={el.url}>
                  <Icons
                    name={el.icon}
                    width={18}
                    height={18}
                    color={selected ? theme.colors.primary : theme.colors.fontBack}
                  />
                  {open && (
                  <TitleMenu selected={selected}>
                    {el.title}
                  </TitleMenu>
                  )}
                </ItemMenu>
              )
            })}
        </MenuTop>
        <ExitMenu
          onClick={async () => {
            await Authentication.logout()
            history.push('/login')
          }}
        >

          <Icons
            name="exit"
            width={24}
            height={24}
            color={theme.colors.fontBack}
          />
          {open && (
          <TitleMenu selected={false}>
            Sair
          </TitleMenu>
          )}
        </ExitMenu>
      </ContainerMenu>

    </Wrapper>
  )
}
export default MenuComponent
