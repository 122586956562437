/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-indent */
import React, { useEffect, useState } from 'react'
import { LaborDocsResponseProps } from '../../../../domain/models/operator'
// import Icons from '../../../components/icons-helper'
// import { theme } from '../../../styles/theme'
import { ProgressStyled } from '../list/styles'
import { BoxResendPass, CustomComponentRow } from './styles'
import ButtonComponent from '../../../components/button'
import { theme } from '../../../styles/theme'

export const MakeFields = (values: any,
  documents: LaborDocsResponseProps | undefined,
  editMode: boolean,
  resendPass: (e: any) => void): any[] => {
  const [hasCNH, setHasCNH] = useState(false)
  const [asoFile, setAsoFile] = useState(false)

  useEffect(() => {
    if (documents?.occupationalHealthCertificate) {
      setAsoFile(true)
    }
  }, [documents?.occupationalHealthCertificate])
  return [
    {
      groupName: 'Dados da tarefa',
      groupLabel: 'Dados da tarefa',
      items: [
        {
          name: 'name',
          type: 'text',
          label: 'Nome',
          disabled: editMode,
          defaultValue: values?.name,
          placeholder: 'Digite o nome do Operador',
          validations: 'inputRequired',
          col: {
            md: 3,
            lg: 3,
          },
        },
        {
          name: 'cpf',
          type: 'text',
          label: 'CPF',
          disabled: editMode,
          defaultValue: values?.cpf,
          placeholder: 'Digite o nome do Operador',
          validations: 'inputRequired',
          mask: '000.000.000-00',
          col: {
            md: 3,
            lg: 3,
          },
        },
        // {
        //   name: 'phoneNumber',
        //   type: 'text',
        //   label: 'Telefone para contato',
        //   disabled: editMode,
        //   defaultValue: values?.phoneNumber,
        //   placeholder: 'Telefone para contato',
        //   mask: '(00) 0 0000-0000',
        //   col: {
        //     md: 3,
        //     lg: 3,
        //   },
        // },
        {
          name: 'email',
          type: 'text',
          label: 'E-mail',
          disabled: editMode,
          defaultValue: values?.email,
          placeholder: 'Digite o E-mail',
          validations: 'emailRequired',
          col: {
            md: 3,
            lg: 3,
          },
        },
        {
          type: 'component',
          label: 'ResetPass',
          hide: !values?.id,
          disabled: editMode,
          defaultValue: values?.meetsRequirement,
          component:
            <BoxResendPass>
               <ButtonComponent
                 onClick={async () => {
                   await resendPass(true)
                 }}
                 type="button"
                 title="Resetar Senha"
                 color={theme.colors.white}
                 backgroundColor={theme.colors.green}
               />
            </BoxResendPass>,
          col: {
            md: 3,
            lg: 3,
          },
        },
        // {
        //   name: 'ctps',
        //   type: 'text',
        //   label: 'Carteira de trabalho',
        //   disabled: editMode,
        //   defaultValue: values?.ctps,
        //   placeholder: 'Digite a CTPS do Operador',
        //   // validations: 'inputRequired',
        //   col: {
        //     md: 3,
        //     lg: 3,
        //   },
        // },
        // {
        //   name: 'pis',
        //   type: 'text',
        //   label: 'PIS',
        //   disabled: editMode,
        //   defaultValue: values?.pis,
        //   placeholder: 'Número da carteira de trabalho',
        //   // validations: 'inputRequired',
        //   col: {
        //     md: 3,
        //     lg: 3,
        //   },
        // },
        // {
        //   name: 'proofOfAddressPath',
        //   type: 'file',
        //   label: 'Comprovante de residência',
        //   disabled: editMode,
        //   accept: 'application/pdf',
        //   defaultValue: documents?.proofOfAddress,
        //   placeholder: 'Anexar PDF',
        //   // validations: 'inputRequired',
        //   col: {
        //     md: 6,
        //     lg: 6,
        //   },
        // },
        {
          name: 'occupationalHealthCertificatePath',
          type: 'file',
          accept: 'application/pdf',
          label: 'Atestado de saúde ocupacional',
          disabled: editMode,
          defaultValue: documents?.occupationalHealthCertificate,
          placeholder: 'Anexar PDF',
          // validations: 'inputRequired',
          onChange: (e: any) => {
            setAsoFile(!!e)
          },
          col: {
            md: 6,
            lg: 6,
          },
        },
        {
          name: 'asoDate',
          type: 'date',
          accept: 'application/pdf',
          label: 'Data do Atest. de saúde ocupacional',
          disabled: editMode,
          defaultValue: values?.asoDate,
          placeholder: '01/02/2000',
          hide: !asoFile,
          // validations: 'inputRequired',
          col: {
            md: 6,
            lg: 6,
          },
        },
        {
          name: 'epiSheet',
          type: 'file',
          accept: 'application/pdf',
          label: 'Ficha de EPI',
          disabled: editMode,
          defaultValue: documents?.epiSheet,
          placeholder: 'Anexar PDF',
          // validations: 'inputRequired',
          col: {
            md: 6,
            lg: 6,
          },
        },
        {
          name: 'evidenceOfTrainingFISPQ',
          type: 'file',
          accept: 'application/pdf',
          label: 'Evidência Treinamento FISPQ',
          disabled: editMode,
          defaultValue: documents?.evidenceOfTrainingFISPQ,
          placeholder: 'Anexar PDF',
          // validations: 'inputRequired',
          col: {
            md: 6,
            lg: 6,
          },
        },
        {
          name: 'workContract',
          type: 'file',
          accept: 'application/pdf',
          label: 'Contrato de trabalho',
          disabled: editMode,
          defaultValue: documents?.workContract,
          placeholder: 'Anexar PDF',
          // validations: 'inputRequired',
          col: {
            md: 6,
            lg: 6,
          },
        },
        // {
        //   name: 'aso',
        //   type: 'file',
        //   accept: 'application/pdf',
        //   label: 'ASO',
        //   disabled: editMode,
        //   defaultValue: documents?.aso,
        //   placeholder: 'Anexar PDF',
        //   // validations: 'inputRequired',
        //   col: {
        //     md: 6,
        //     lg: 6,
        //   },
        // },
        {
          name: 'orderOfService',
          type: 'file',
          accept: 'application/pdf',
          label: 'Ordem de serviço',
          disabled: editMode,
          defaultValue: documents?.orderOfService,
          placeholder: 'Anexar PDF',
          // validations: 'inputRequired',
          col: {
            md: 6,
            lg: 6,
          },
        },
        {
          name: 'drive',
          type: 'toggle',
          label: 'Profissional dirige veículos?',
          disabled: editMode,
          defaultValue: values?.drive,
          // validations: 'inputRequired',
          onChange: () => {
            setHasCNH((prev) => !prev)
          },
          col: {
            md: 7,
            lg: 7,
          },
        },
        {
          name: 'driverLicense',
          type: 'file',
          accept: 'application/pdf',
          label: 'CNH',
          hide: !hasCNH,
          disabled: editMode,
          defaultValue: documents?.driverLicense,
          placeholder: 'Anexar PDF',
          ...(hasCNH && { validations: 'inputRequired' }),
          col: {
            md: 6,
            lg: 6,
          },
        },
        {
          name: 'registrationForm',
          type: 'file',
          accept: 'application/pdf',
          label: 'Ficha de Registro',
          disabled: editMode,
          defaultValue: documents?.registrationForm,
          placeholder: 'Anexar PDF',
          // validations: 'inputRequired',
          col: {
            md: 6,
            lg: 6,
          },
        },
        {
          name: 'evidenceOfLupiAmbev',
          type: 'file',
          accept: 'application/pdf',
          label: 'Evidencia da LUPI*AmBev',
          disabled: editMode,
          defaultValue: documents?.evidenceOfLupiAmbev,
          placeholder: 'Anexar PDF',
          // validations: 'inputRequired',
          col: {
            md: 6,
            lg: 6,
          },
        },
        {
          name: 'meetsRequirement',
          type: 'yesno',
          label: 'Atende requisitos Wash me',
          disabled: editMode,
          defaultValue: values?.meetsRequirement,
          // validations: 'inputRequired',
          col: {
            md: 7,
            lg: 7,
          },
        },
        {
          name: 'enabled',
          type: 'toggle',
          label: 'Status',
          disabled: editMode,
          defaultValue: values?.meetsRequirement,
          // validations: 'inputRequired',
          col: {
            md: 7,
            lg: 7,
          },
        },
        // {
        //   type: 'component',
        //   hide: !values?.id || !editMode,
        //   label: 'Status',
        //   disabled: editMode,
        //   defaultValue: values?.meetsRequirement,
        //   component: <CustomComponentRow>
        //     <span>Avaliação Média</span>
        //     <div>
        //       <Icons
        //         name="star-fill"
        //         width={30}
        //         height={30}
        //         color={theme.colors.yellow}
        //       />
        //       4.6
        //     </div>
        //              </CustomComponentRow>,
        //   col: {
        //     md: 3,
        //     lg: 3,
        //   },
        // },
        {
          type: 'component',
          label: 'Status',
          hide: !values?.id || !editMode,
          disabled: editMode,
          defaultValue: values?.meetsRequirement,
          component: <CustomComponentRow>
            <span>Progresso do dia</span>
            <div className="mt-3">
              <ProgressStyled
                className="me-2"
                max={values?.progressDay?.completed}
                value={values?.progressDay?.todo}
              />
              {String(values?.progressDay?.todo).padStart(2, '0')}
              /
              {String(values?.progressDay?.completed).padStart(2, '0')}
            </div>
                     </CustomComponentRow>,
          col: {
            md: 3,
            lg: 3,
          },
        },
      ],
    },
  ]
}
