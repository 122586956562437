import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { Col } from 'react-bootstrap'
import bg from '../../../assets/images/bg-auth.png'
import ballon from '../../../assets/images/ballon.png'
import doc from '../../../assets/images/file-dock.png'
import { theme } from '../../../styles/theme'

export const Wrapper = styled.div`
  height: 100vh;
  .row{
    height: 100%;
  }
  .container-form{
    padding: 0!important;
    margin: 0 auto;
  }
  .col-lg-12{
    padding: 0!important;
  }
`
export const BackgroudStyled = styled.div`
  background: url(${bg});
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  display: flex;
  background-size: cover;
  align-items: center;
  justify-content: center;
  position: relative;
`
export const ColStyled = styled(Col)`
  background-color: #fff;
`
export const BoxImage = styled.div`
  position: relative;
`
export const ImageAuthStyled = styled.img`
  max-width: 25rem;
`
export const Title = styled.h1`
  font-family: 'Palanquin';
  font-weight: 700;
  font-size: 2.5rem;
  color: ${theme.colors.black};
  margin-bottom: 15px;
`
export const SubTitle = styled.h3`
  font-family: 'Palanquin';
  font-weight: 300;
  font-size: 1.4rem;
  color: ${theme.colors.darkGray};
  margin-bottom: 30px;
`
export const CardForm = styled.div`
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  max-width: 400px;
  margin: 0 auto;
`
export const BallonImage = styled.div`
  background: url(${ballon});
  width: 10rem;
  height: 6rem;
  background-size: contain;
  border: none;
  position: absolute;
  right: 0;
  background-repeat: no-repeat;
  bottom: 30px;
`
export const BallonDoc = styled.div`
  background: url(${doc});
  width: 5rem;
  height: 5rem;
  background-size: contain;
  position: absolute;
  left: 0;
  top: 40px;
`
export const ForgotPass = styled(Link)`
  text-decoration: none;
  font-family: 'Poppins';
  font-weight: 300;
  margin-top: 20px;
`
export const LogoImage = styled.img`
  margin-bottom: 20px;
  max-width: 150px;
`
