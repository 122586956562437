/* eslint-disable no-return-await */
import moment from 'moment'
import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { DocRequestProp, DocumentsProps } from '../../../../domain/models/documents'
import Icons from '../../../components/icons-helper'
import { checkIsCustomer } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import { DocumentItem } from './DocumentItem'
import {

  ActionButton,
  CloseButton,
  ColStyled,
  CustomerBox,
  DocumentsContainer,
  DocumentsList,
  DownloadButtonAll,
  HeadModal,
  LabelStyled,
  NameCustomerStyled,
  ProgressBarStyled, RowStyled,
  RowTitle,
  // SaveButton,
  SaveSendButton,
  TextStyled, TitleStyled, WrapperModal,
} from './styled'

type ComponentProps = {
  content: DocumentsProps | undefined
  onCloseCallBack?: () => void
  refetch: () => void
  complete: (id?:number) => Promise<void>
  downloadZipOnly: (id?:number) => Promise<void>
}

const SideModalComponent = ({
  content, onCloseCallBack, refetch, complete, downloadZipOnly,
}:ComponentProps):JSX.Element => {
  const [show, setShow] = useState(false)
  const handleClose = (): void => {
    setShow(false)
    if (onCloseCallBack) {
      onCloseCallBack()
    }
  }
  const handleShow = (): void => setShow(true)

  useEffect(() => {
    if (content) {
      handleShow()
    }
  }, [content])

  if (!content) {
    return <div />
  }

  return (
    <>
      <Modal show={show} onHide={handleClose} className="side-modal-component">
        <HeadModal>
          <TitleStyled>
            <Icons
              name="file-up"
              width={20}
              height={20}
              color={theme.colors.darkGray}
            />
            Solicitação de documentos

          </TitleStyled>
          <CloseButton onClick={() => handleClose()} type="button">
            <Icons
              name="close"
              width={15}
              height={15}
              color={theme.colors.darkGray}
            />
          </CloseButton>
        </HeadModal>
        <WrapperModal>
          <CustomerBox>
            <NameCustomerStyled>
              {' '}
              {checkIsCustomer()
                ? content?.serviceProvider?.name
                : content?.customer?.name}
            </NameCustomerStyled>
            <TextStyled>
              Responsável:
              {' '}
              {checkIsCustomer()
                ? content?.serviceProvider?.user?.name
                : content?.customer?.user?.name}

            </TextStyled>
            <RowStyled>
              <ColStyled xs={12}>
                <TextStyled style={{ marginBottom: 10 }}>
                  <Icons
                    name="email"
                    width={15}
                    height={15}
                    color={theme.colors.primary}
                  />
                  {checkIsCustomer()
                    ? content?.serviceProvider?.user?.email
                    : content?.customer?.user?.email}
                </TextStyled>
                <TextStyled>
                  <Icons
                    name="phone"
                    width={15}
                    height={15}
                    color={theme.colors.primary}
                  />
                  telefone do Cliente
                </TextStyled>
              </ColStyled>
            </RowStyled>
            <RowStyled>
              <ColStyled xs={5}>
                <LabelStyled>ID solicitação</LabelStyled>
                <TextStyled>{content.requestNumber}</TextStyled>
              </ColStyled>
              <ColStyled xs={7}>
                <LabelStyled>Progresso de envio</LabelStyled>
                <TextStyled>
                  <ProgressBarStyled value={content.sendProgress} max="100" />
                  {content.sendProgress}
                  %
                </TextStyled>
              </ColStyled>
            </RowStyled>
            <RowStyled>
              <ColStyled xs={5}>
                <LabelStyled>Data da solicitação</LabelStyled>
                <TextStyled>{moment(content.createdAt).format('DD/MM/YYYY')}</TextStyled>
              </ColStyled>
              <ColStyled xs={7}>
                <LabelStyled>Data limite para envio</LabelStyled>
                <TextStyled>{moment(content.deadLine).format('DD/MM/YYYY')}</TextStyled>
              </ColStyled>
            </RowStyled>
            {checkIsCustomer() && (
            <RowStyled>
              <ColStyled xs={5}>
                <LabelStyled>Status</LabelStyled>
                <TextStyled>
                  <li
                    style={{
                      color: content?.sendProgress === 100
                        ? theme.colors.green : theme.colors.black,
                    }}
                  >
                    {content?.sendProgress === 100 ? 'Recebido' : 'Em Aguardo'}
                  </li>

                </TextStyled>
              </ColStyled>
            </RowStyled>
            )}
          </CustomerBox>
          <DocumentsContainer>
            <RowTitle>
              <TitleStyled>Documentos solicitados</TitleStyled>
              {content?.status === 'COMPLETE' && (
              <DownloadButtonAll
                onClick={async () => await downloadZipOnly(content?.id)}
              >
                <Icons
                  name="download"
                  width={15}
                  height={15}
                  color={theme.colors.darkGray}
                />
                Baixar Tudo
              </DownloadButtonAll>
              )}
            </RowTitle>
            <DocumentsList>
              {content?.documents?.map((el: DocRequestProp) => (
                <DocumentItem
                  doc={el}
                  documentRequest={content.id}
                  refetch={refetch}
                />
              ))}
            </DocumentsList>
          </DocumentsContainer>

          {!checkIsCustomer() && (
          <ActionButton>
            {/* <SaveButton>Salvar</SaveButton> */}
            {content?.status !== 'COMPLETE' && (
            <SaveSendButton
              onClick={async () => await complete(content?.id)}
              disabled={content.sendProgress < 100}
            >
              Salvar
            </SaveSendButton>
            )}
          </ActionButton>
          )}

        </WrapperModal>
      </Modal>
    </>
  )
}

export default SideModalComponent
