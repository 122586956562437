import React, { useEffect, useState } from 'react'
import { Spinner } from 'react-bootstrap'
import { useAuth } from '../../../../contexts/auth-context/auth-context'
import { CustomerProps } from '../../../../domain/models/customer'
import { TaskChart } from '../../../../domain/models/task'
import { makeRemoteAdmin } from '../../../../main/factories/usecases/admin/remote-admin-factory'
import { makeRemoteCustomer } from '../../../../main/factories/usecases/customer/remote-customer-factory'
import { theme } from '../../../styles/theme'
import FormFactory from '../../form-factory'
import Icons from '../../icons-helper'
import {
  Card, Content, ContentTitle, SubtitleContainer, Title,
} from './styles'

declare global {
  interface Window {
    Chart?: any;
  }
}

type CardProps = {
  period: string
}

const TaskByCustomer = ({ period }: CardProps):JSX.Element => {
  const [chartData, setChartData] = useState<TaskChart>()
  const [customer, setCustomer] = useState<CustomerProps>()
  const [customerList, setCustomerList] = useState<CustomerProps[]>([])
  const [loading, setloading] = useState<boolean>()
  const { user } = useAuth()
  useEffect(() => {
    (async () => {
      const list = await makeRemoteCustomer().all()
      setCustomerList(list)
    })()
  }, [])

  useEffect(() => {
    if (user || customer) {
      (async () => {
        setloading(true)
        const dataChart = await makeRemoteAdmin().taskChartByCustomer({
          period,
          customerUUID: customer?.uuid,
        })

        const data = {
          labels: dataChart?.branchOffices,
          datasets: [
            {
              barThickness: 20,
              label: 'Fully Rounded',
              data: dataChart?.branchGoals,
              borderRadius: Number.MAX_VALUE,
              borderSkipped: false,
              backgroundColor: theme.colors.yellow,
            },
          ],
        }

        const config = {
          type: 'bar',
          data,
          options: {
            responsive: true,
            plugins: {
              legend: {
                display: false,
                position: 'top',
              },
              title: {
                display: false,
              },
            },
            scales: {
              x: {
                grid: {
                  display: false,
                },
              },
              y: {
                grid: {
                  display: false,
                },
              },
            },
          },
        }

        setChartData(dataChart)
        setloading(false)
        const ctx = document?.getElementById('myChartCustomer')
        const myChart = new window.Chart(ctx, config)
        // window.Chart().update()
        console.warn(myChart)
      })()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [customer, period])

  const makeFields = ():any => ([{
    groupName: 'filter',
    // groupLabel: 'Selecione um Operador para ver o seu estoque',
    items: [
      {
        name: 'operator',
        type: 'select',
        // label: 'Selecione um Operador para ver o seu estoque',
        defaultValue: 'Todos',
        placeholder: 'Todos',
        validations: 'objectRequired',
        options: [{ name: 'Todos', uuid: null }, ...customerList],
        labelKey: 'name',
        onChange: (val: any) => { setCustomer(val) },
        valueKey: 'uuid',
        col: {
          md: 12,
          lg: 12,
        },
      }],
  }])
  return (
    <Card>
      <Content>
        <ContentTitle>
          <Title>
            Tarefas realizadas por cliente
          </Title>
          <div className="d-flex w-50">
            <FormFactory
              className="internal-form form-filter mt-2"
              groups={makeFields()}
              button={{ submit: 'Salvar', submitting: 'Salvando...', hide: true }}
              onSubmit={() => null}
            />
          </div>
        </ContentTitle>
        <SubtitleContainer>
          <p>{chartData?.totalPeriodTasks}</p>
          <span>
            <Icons height={5} width={5} name="arrow-up" color={theme.colors.green} />
            +
            {Number(chartData?.percentageToPreviousPeriod).toFixed(2)}
            %
          </span>
        </SubtitleContainer>
        {loading ? <Spinner animation="grow" style={{ margin: '0 auto' }} variant="primary" /> : <canvas id="myChartCustomer" />}

      </Content>
    </Card>
  )
}
export default TaskByCustomer
