import styled from 'styled-components'
import { theme } from '../../styles/theme'

type DropdownProps = {
  open?: boolean
  disabled?: boolean
}

export const SelectStyled = styled.div`
  font-family: 'Poppins';
  padding: 10px 15px 10px 25px;
  border-radius: 100px;
  color: ${theme.colors.black};
  height: 56px;
  margin-bottom: 15px;
  position: relative;
  justify-content: space-between;
  display: flex;
  align-items: center;
  cursor: pointer;
  background-color: ${(p:DropdownProps) => (p.disabled ? theme.colors.lightGray : 'transparent')};
  border: ${(p:DropdownProps) => (p.disabled ? '2px solid #ABABAB' : `1px solid ${theme.colors.middleGray}`)};
  color: ${(p:DropdownProps) => (p.disabled ? '#ABABAB' : theme.colors.black)};
`
export const OptionStyled = styled.button`
  color: ${theme.colors.black};
  padding: 15px 30px 15px 30px;
  width: 100%;
  text-align: start;
  background-color: transparent;
  font-family: 'Poppins';
  &:hover{
    background-color: #EBF2FB;
    color: ${theme.colors.primary};
  }
`
export const DropdownStyled = styled.div`
 position: absolute;
 background-color: ${theme.colors.white};
 width: 100%;
 left: 0;
 top: 115%;
 border: 1px solid ${theme.colors.middleGray};
 border-radius: 24px;
 padding: 15px 0px;
 opacity: ${(p:DropdownProps) => (p.open ? 1 : 0)};
 visibility: ${(p:DropdownProps) => (p.open ? 'visible' : 'hidden')};
 transition: ease all 200ms;
 z-index: 9;
 height: 300px;
  overflow: auto;
`
