import { I18n } from 'aws-amplify'
import { dictionary } from '../../dictionary'

export const t = (key: any, defVal?: any): string => {
  const { get } = I18n
  return get(key, defVal)
}

export const addLanguage = (language: string): void => {
  I18n.setLanguage(language)
  I18n.putVocabularies(dictionary)
}
