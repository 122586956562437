import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useHistory } from 'react-router-dom'
import { useAuth } from '../../../contexts/auth-context/auth-context'
import {
  NewTaskCard,
  OperatorCard,
  RatingsCard,
  TaskByBranch,
  TaskCard,
  TasksStatus,
  TopRatings,
  UploadDocumentsCard,
} from '../../components/dashboards'
import Icons from '../../components/icons-helper'
import PeriodFilter from '../../components/period-filter'
import { theme } from '../../styles/theme'
import {
  BackButton,
  ColStyled, ContainerModal, ContinueButton, RowStyled, RowTitleStyled, SplitStyled, Title, Wrapper,
} from './styles'

const ServiceProviderDashboardPage = ():JSX.Element => {
  const [period, setPeriod] = useState<string>('month')
  const {
    profile, setBranch, setIsheadeOffice, user,
  } = useAuth()
  const history = useHistory()

  const show = !!profile?.branch?.openModal
  console.log(profile)
  return (
    <Wrapper>
      <RowTitleStyled>
        <Title>Dashboard</Title>
        <PeriodFilter callback={(callback:string) => setPeriod(callback)} />
      </RowTitleStyled>
      <RowStyled>
        {!profile?.branch && (
        <ColStyled width={48}>
          <TaskByBranch period={period} serviceProvider={profile?.branch?.uuid || user?.uuid} />
        </ColStyled>
        )}
        {profile?.branch && (
        <ColStyled width={36}>
          <NewTaskCard />
        </ColStyled>
        )}
        <ColStyled width={18}>
          <SplitStyled>
            <OperatorCard
              period={period}
              isHeadOffice={profile?.headoffice}
              serviceProvider={profile?.branch?.uuid || user?.uuid}
            />
          </SplitStyled>
          <SplitStyled>
            <RatingsCard
              period={period}
              serviceProvider={profile?.branch?.uuid}
            />
          </SplitStyled>
        </ColStyled>
        {profile?.branch && (
        <ColStyled width={22}>
          <TaskCard
            period={period}
            isHeadOffice={profile?.isHeadOffice}
            serviceProvider={profile?.branch?.uuid}
          />
        </ColStyled>
        )}
        <ColStyled width={profile?.branch ? 22 : 34}>
          <TopRatings
            period={period}
            isHeadOffice={profile?.isHeadOffice}
            serviceProvider={profile?.branch?.uuid || user?.uuid}
          />
        </ColStyled>
      </RowStyled>
      <RowStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="TODO"
            period={period}
            isHeadOffice={profile?.isHeadOffice}
            serviceProvider={profile?.branch?.uuid}
          />
        </ColStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="IN_PROGRESS"
            period={period}
            isHeadOffice={profile?.isHeadOffice}
            serviceProvider={profile?.branch?.uuid}
          />
        </ColStyled>
        <ColStyled width={18}>
          <TasksStatus
            type="COMPLETED"
            period={period}
            isHeadOffice={profile?.isHeadOffice}
            serviceProvider={profile?.branch?.uuid}
          />
        </ColStyled>
        <ColStyled width={46}>
          <UploadDocumentsCard
            period={period}
            isHeadOffice={profile?.isHeadOffice}
            uuid={profile?.branch?.uuid || user?.uuid}
          />
        </ColStyled>
      </RowStyled>
      <Modal
        show={show}
        onHide={async () => {
          await setBranch({ ...profile?.branch, openModal: false })
        }}
        className="modal-branch"
      >
        <Modal.Body>
          <ContainerModal>
            <Icons
              name="caution"
              width={200}
              height={200}
              color={theme.colors.yellow}
            />
            <span>Você estará visualizando:</span>
            <h5>
              Filial
              {' '}
              {profile?.branch?.name}
            </h5>
            <ContinueButton onClick={async () => {
              await setBranch({ ...profile?.branch, openModal: false })
            }}
            >
              Prosseguir
            </ContinueButton>
            <BackButton onClick={async ():Promise<void> => {
              await setBranch(null)
              await setIsheadeOffice(true)
              history.goBack()
            }}
            >
              Voltar
            </BackButton>
          </ContainerModal>
        </Modal.Body>
      </Modal>
    </Wrapper>
  )
}
export default ServiceProviderDashboardPage
