import React, { useEffect, useState } from 'react'
import Modal from 'react-bootstrap/Modal'
import { useHistory } from 'react-router-dom'
import { StockProps } from '../../../../domain/models/stock'
import Icons from '../../../components/icons-helper'
import { getColorByQtd, getLabelByQtd, unitMeasurement } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import {
  CloseButton,
  ColStyled,
  CustomerBox,
  DocumentsContainer,
  DocumentsList,
  DownloadButtonAll,
  HeadModal,
  ImageProduct,
  LabelStyled,
  RowStyled,
  RowTitle, StatusProduct, TextStyled, TitleStyled, WrapperModal,
} from './styled'

type ComponentProps = {
  open: boolean
  product: StockProps.Model | undefined
  onCloseCallBack?: () => void
}

const SideModalComponent = ({ open, product, onCloseCallBack }:ComponentProps):JSX.Element => {
  const [show, setShow] = useState(false)
  const history = useHistory()
  const handleClose = (): void => {
    setShow(false)
    if (onCloseCallBack) {
      onCloseCallBack()
    }
  }
  const handleShow = (): void => setShow(true)

  useEffect(() => {
    if (open) {
      handleShow()
    }
  }, [open])

  return (
    <>
      <Modal show={show} onHide={handleClose} className="side-modal-component">
        <HeadModal>
          <TitleStyled>
            <Icons
              name="makeups"
              width={20}
              height={20}
              color={theme.colors.darkGray}
            />
            Produto

          </TitleStyled>
          <CloseButton onClick={() => handleClose()} type="button">
            <Icons
              name="close"
              width={15}
              height={15}
              color={theme.colors.darkGray}
            />
          </CloseButton>
        </HeadModal>
        <WrapperModal>
          <CustomerBox>
            <ImageProduct src={product?.product?.imageUrl} />
          </CustomerBox>
          <DocumentsContainer>
            <RowTitle>
              <TitleStyled>{product?.product?.name}</TitleStyled>
              <DownloadButtonAll onClick={() => history.push(`/product/${product?.product?.id || 'create'}`)}>
                <Icons
                  name="substract"
                  width={15}
                  height={15}
                  color={theme.colors.primary}
                />
              </DownloadButtonAll>
            </RowTitle>
            <DocumentsList>
              <RowStyled>
                <ColStyled xs={6}>
                  <LabelStyled>Estoque atual</LabelStyled>
                  <TextStyled>

                    <span className="me-2">{`${String(product?.quantity).padStart(2, '0')} ${unitMeasurement(product?.quantityUnit)}`}</span>
                    <DownloadButtonAll onClick={() => history.push(`/stock/${product?.id || 'create'}`)}>
                      <Icons
                        name="substract"
                        width={15}
                        height={15}
                        color={theme.colors.primary}
                      />
                    </DownloadButtonAll>
                  </TextStyled>
                </ColStyled>
                <ColStyled xs={6}>
                  <LabelStyled className="mb-3">Status</LabelStyled>
                  <StatusProduct color={getColorByQtd(product?.quantity || 0)}>
                    {getLabelByQtd(product?.quantity || 0)}
                  </StatusProduct>
                </ColStyled>
              </RowStyled>
              {/* <RowStyled>
                <ColStyled xs={6}>
                  <LabelStyled>ID produto</LabelStyled>
                  <TextStyled>{String(product?.id)}</TextStyled>
                </ColStyled>
                <ColStyled xs={6}>
                  <LabelStyled>Quantidade última compra</LabelStyled>
                  <TextStyled>{String(product?.id)}</TextStyled>
                </ColStyled>
              </RowStyled> */}
              {/* <RowStyled>
                <ColStyled xs={6}>
                  <LabelStyled>Valor unidade</LabelStyled>
                </ColStyled>
                <ColStyled xs={6}>
                  <LabelStyled>Data ultima compra</LabelStyled>
                </ColStyled>
              </RowStyled> */}
            </DocumentsList>

          </DocumentsContainer>

        </WrapperModal>
      </Modal>
    </>
  )
}

export default SideModalComponent
