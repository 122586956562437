import {
  createGlobalStyle,
  DefaultTheme,
  GlobalStyleComponent,
} from 'styled-components'
import { theme } from './theme'

export const GlobalStyle: GlobalStyleComponent<
  object,
  DefaultTheme
> = createGlobalStyle`
  *,
  ::after,
  ::before {
    box-sizing: border-box;
    outline: none;
  }

  html {
    line-height: 1.15;
    -webkit-text-size-adjust: 100%;
    -ms-text-size-adjust: 100%;
    -ms-overflow-style: scrollbar;
    -webkit-tap-highlight-color: transparent;
    scroll-behavior: smooth;
  }

  @keyframes pulse {
    0% {transform: scale(1);}
    50% {transform: scale(1.25);}
    100% {transform: scale(1);}
  }

  article,
  aside,
  figcaption,
  figure,
  footer,
  header,
  hgroup,
  main,
  nav,
  section {
    display: block;
  }

  button{
    border: none;
    font-family: ${theme.font.montserrat};
  }

  body {
    padding: 0;
    margin: 0;
    font-size: 16px;
    line-height: 1.35;
    background: ${theme.colors.mainBg};
    color: ${theme.colors.black};
  }

  body, input, textarea {
    font-family: ${theme.font.roboto};
  }

  h1, h2, h3, h4, h5, h6 {
    font-family: ${theme.font.roboto};
    margin-top: 0;
    margin-bottom: 0;
    font-weight: bold;
  }
  a{
    text-decoration: none;
  }
  .alert {
    position: absolute;
    padding: 1rem 1rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: 0.25rem;
    bottom: 50px;
    left: 50%;
    transform: translateX(-60%);
  }
  .modal{
    transform: translateY(-50%);
    top: 50%;
  }

  .side-modal-component{
    &.modal.fade.show {
       .modal-dialog {
        transform: translate(calc(100vw - 100%), 0)!important;
        margin: 0!important;
      }
    }
    &.modal {
        top: 0;
        transform: translateY(0%);
        margin: 0;
        padding-right: 0px!important;
    }

    & .modal-dialog {
        padding: 0;
        max-width: 450px;
        .modal-content {
            height: 100vh;
        }
    }

    &.modal.fade .modal-dialog {
        transform: translate(calc(100vw), 0)!important;
    }
  }
  .modal-branch.modal .modal-content {
    border-radius: 30px;
  }
`
