import React from 'react'
import { Roles, useAuth } from '../../../contexts/auth-context/auth-context'
import AdminDashboardPage from './admin'
import CustomerDashboardPage from './customer'
import CustomerBranchDashboardPage from './customerBranch'
import ServiceProviderDashboardPage from './serviceProvider'
import ServiceProviderBranchDashboardPage from './serviceProviderBranch'

const DashboardPage = ():JSX.Element => {
  const { user } = useAuth()
  switch (user?.roles[0].authority) {
    case Roles.HEAD_OFFICE:
      return <ServiceProviderDashboardPage />
    case Roles.BRANCH_OFFICE:
      return <ServiceProviderBranchDashboardPage />
    case Roles.CUSTOMER_HEAD_OFFICE:
      return <CustomerDashboardPage />
    case Roles.CUSTOMER_BRANCH_OFFICE:
      return <CustomerBranchDashboardPage />
    case Roles.ADMIN:
      return <AdminDashboardPage />
    default:
      return <div />
  }
}
export default DashboardPage
