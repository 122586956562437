import styled from 'styled-components'
import { theme } from '../../styles/theme'

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`
export const RowStyled = styled.div`
  width: 100%;
  display: flex;
`
export const RowTitleStyled = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-right: 15px;
  margin-bottom: 15px;
  .select-input{
    margin: 0!important;
  }
`
export const SplitStyled = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: 20px;
  height: calc(50% - 10px);
`
export const Content = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
`
export const ContinueButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  background-color: ${theme.colors.primary};
  color: #fff;
  font-family: 'poppins';
  padding: 10px 15px;
  border-radius: 30px;
  margin-bottom: 15px;
  font-size: .8rem;
  `

export const ContainerModal = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  span{
    margin-bottom: 10px;
    color: ${theme.colors.darkGray};
    font-family: 'palanquin';
    font-weight: 400;
    font-size: 1rem;
  }
  h5{
    margin-bottom: 40px;
    color: ${theme.colors.primary};
    font-family: 'palanquin';
    font-weight: 700;
    font-size: 1.6rem;
  }
`
export const BackButton = styled.button`
  font-size: .8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 70%;
  background-color:#fff;
  border:1px solid  ${theme.colors.primary};
  color: ${theme.colors.primary};
  font-family: 'poppins';
  padding: 10px 15px;
  border-radius: 30px;
  margin-bottom: 15px;
`
type ColProp = {
  width: number
}

export const ColStyled = styled.div`
  margin: 10px;
  padding: 0;
  width: ${(p: ColProp) => p.width}%;
`

export const Title = styled.h1`
  margin: 10px;
  padding: 0;
  font-family: ${theme.font.palanquin};
  font-weight: 500;
  font-size: 2rem;
`
