import React, { useState } from 'react'
import { ItemStyled, ListStyled, Wrapper } from './styled'

const PeriodFilter = ({ callback }:any):JSX.Element => {
  const [current, setCurrent] = useState('month')
  const items = [{
    title: 'Ontem',
    value: 'yesterday',
  },
  {
    title: 'Hoje',
    value: 'today',
  },
  {
    title: 'Semana',
    value: 'week',
  },
  {
    title: 'Mês',
    value: 'month',
  },
  {
    title: 'Ano',
    value: 'year',
  }]
  return (
    <Wrapper>
      <ListStyled>
        {items?.map(
          (el) => (
            <ItemStyled
              key={el.title}
              selected={el.value === current}
              onClick={() => {
                setCurrent(el.value)
                callback(el.value)
              }}
            >
              {el.title}
            </ItemStyled>
          ),
        )}
      </ListStyled>
    </Wrapper>
  )
}
export default PeriodFilter
