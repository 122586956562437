// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { CustomerProps } from '../../../../../domain/models/customer'
import { t } from '../../../../../presentation/adapters/translate/translate'
import { isAdmin } from '../../../../../presentation/helpers/utils'
import CustomerInternalPage from '../../../../../presentation/pages/customer/internal'
import { makeRemoteCustomer } from '../../../usecases/customer/remote-customer-factory'

function MakeCustomerInternal({ match }:any): JSX.Element {
  const { handleAlert } = useAlertBox()
  const [data, setData] = useState< CustomerProps>()
  const history = useHistory()
  const { user } = useAuth()

  const loadData = async (): Promise<void> => {
    try {
      const content = await makeRemoteCustomer().loadById(match?.params?.id)
      setData(content)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (async () => {
      if (match?.params?.id && user && match?.params?.id !== 'create') {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [match, user])

  const handleSubmitCustomer = async (values: any, actions: any): Promise<any> => {
    try {
      if (data?.id) {
        const updatedData = await makeRemoteCustomer().update(data?.id, {
          user: {
            email: values?.email,
            name: values?.username,
          },
          headOfficeCustomer: isAdmin() ? null : {
            id: user.id,
          },
          name: values?.name,
          headOffice: true,
          enabled: values?.enabled,
        })

        setData(updatedData)
      } else {
        if (!values?.email) {
          handleAlert({
            show: true,
            type: 'error',
            message: 'Erro ao Enviar E-mail para o servidor! Tente novamente',
          })
          actions.setSubmitting(false)
          return
        }
        const createData = await makeRemoteCustomer().create({
          user: {
            email: values?.email,
            name: values?.username,
          },
          headOfficeCustomer: isAdmin() ? null : {
            id: user.id,
          },
          name: values?.name,
          headOffice: true,
          enabled: values?.enabled,
        })
        history.push(`/customer/${createData.id}`)
      }

      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error?.userMessage || error?.message),
      })
      actions.setSubmitting(false)
    }
  }
  return (
    <CustomerInternalPage
      data={data}
      handleSubmit={handleSubmitCustomer}
    />
  )
}

export default MakeCustomerInternal
