import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { Roles, useAuth } from '../../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../../domain/models/enum'
import { TaskReponseModel } from '../../../../../domain/models/task'
import { checkIsCustomer } from '../../../../../presentation/helpers/utils'
import TaskListPage from '../../../../../presentation/pages/task/task-list'
import { settings } from '../../../../configs/settings'
import { makeRemoteTask } from '../../../usecases/task/remote-task-factory'

const generateDatesQuery = (values: any):string | undefined => {
  let result = values && Object.keys(values).length ? 'serviceDate' : undefined

  if (values?.start) {
    result += `,${moment(values?.start).format('YYYY-MM-DD')}`
  }
  if (values?.end) {
    result += `,${moment(values?.end).format('YYYY-MM-DD')}`
  }
  return result
}

const generateFilter = (values: any):string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'serviceProvider') {
      return `${el}=${values[el]}`
    }
    if (el === 'customer') {
      return `${el}=${values[el]}`
    }

    if (el === 'licensePlate') {
      return `${el}~${values[el]}`
    }
    return el
  }).join(',')

export const getTasksByType = (userType: string, customParams: FilterModel):any => {
  if (userType === Roles.BRANCH_OFFICE || userType === Roles.HEAD_OFFICE) {
    return makeRemoteTask().load(customParams)
  }
  if (userType === Roles.CUSTOMER_HEAD_OFFICE || userType === Roles.CUSTOMER_BRANCH_OFFICE) {
    return makeRemoteTask().customerList(customParams)
  }
  return makeRemoteTask
}
function MakeTaskList(): JSX.Element {
  const [list, setList] = useState<TaskReponseModel>()
  const [params, setParams] = useState<FilterModel>()
  const [loading, setLoading] = useState<boolean>()
  const { profile, user } = useAuth()
  console.log(params)

  const loadData = async (): Promise<void> => {
    setLoading(true)
    const data = await getTasksByType(user?.roles[0]?.authority, {
      ...params,
      size: settings.perPage,
      sort: 'serviceDate,desc',
      dates: generateDatesQuery(
        params?.dates,
      ),
      filters: generateFilter({
        ...params?.filters,
        ...(!checkIsCustomer()
         && { serviceProvider: profile?.branch?.uuid || user.uuid }),
        ...(checkIsCustomer()
         && { customer: profile?.branch?.uuid || user.uuid }),
      }),
    })
    setList(data)
    setLoading(false)
  }
  console.log(user?.branch, profile)
  const exportList = (): Promise<void> => makeRemoteTask().exportCSV({
    filters: generateFilter({
      ...params?.filters,
      ...(profile?.branch?.uuid && {
        serviceProvider: !checkIsCustomer()
          ? profile?.branch?.uuid : profile?.branch?.serviceProvider?.uuid,
      }),
    }),
  })

  useEffect(() => {
    if (profile?.branch?.uuid || user) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, profile?.branch?.uuid])

  return (
    <TaskListPage list={list} setParams={setParams} loading={loading} exportList={exportList} />
  )
}

export default MakeTaskList
