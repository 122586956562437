import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../contexts/auth-context/auth-context'
import { DocumentsResponseModel } from '../../../../domain/models/documents'
import { selectRequestByType } from '../../../../main/factories/pages/upload-documents/list'
import { checkIsCustomer } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import { LinkStyled } from '../tasks/styles'
import {
  BoxOperator,
  Card, Content, Info, ListStyled, Name, ProgressBar, QuantityStyled, Title,
} from './styles'

type ComponentProps = {
  period: string
  isHeadOffice: boolean
  uuid: string | undefined
}

const UploadDocumentsCard = ({
  period,
  isHeadOffice,
  uuid,
}:ComponentProps):JSX.Element => {
  const [data, setData] = useState <DocumentsResponseModel >()
  const { user } = useAuth()
  useEffect(() => {
    if (uuid) {
      (async () => {
        const response = await selectRequestByType(user?.roles[0]?.authority, {
          uuid,
          isHeadOffice: isHeadOffice || false,
          size: 6,
          period,
        })
        setData(response)
      })()
    }
  }, [period, isHeadOffice, uuid, user])

  return (
    <Card>
      <Content>
        <Title>
          <div>
            <Icons name="folder-inline" width={18} height={18} color={theme.colors.middleGray} />
            {checkIsCustomer() ? 'Documentos' : 'Upload documentos'}
          </div>
          <LinkStyled to="/upload-documents">
            Ver tudo
            <Icons name="arrow-right" width={13} height={13} color={theme.colors.primary} />
          </LinkStyled>
        </Title>
        <ListStyled>
          {data?.content?.map((el, i) => (
            <BoxOperator to="/" key={el?.customer?.name + el?.sendProgress} index={i}>
              <Name>
                {el?.customer?.name || el?.serviceProvider?.name}
              </Name>
              <QuantityStyled>
                {`${el?.count < 10 ? String(el?.count).padStart(2, '0') : el?.count} ${el?.count > 1 ? 'documentos' : 'documento'}`}
              </QuantityStyled>
              {checkIsCustomer()
                ? (
                  <Info>
                    <li
                      style={{
                        color: el?.sendProgress === 100
                          ? theme.colors.green : theme.colors.red,
                      }}
                    >
                      {el?.sendProgress === 100 ? 'Recebido' : 'Em Aguardo'}
                    </li>
                  </Info>
                ) : (
                  <Info style={{ marginBottom: 0 }}>
                    <ProgressBar percentage={el?.sendProgress} />
                    {el?.sendProgress}
                    %
                  </Info>

                )}

            </BoxOperator>
          ))}
        </ListStyled>

      </Content>
    </Card>
  )
}
export default UploadDocumentsCard
