import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../../domain/models/enum'
import { OperatorResponseModel } from '../../../../../domain/models/operator'
import OperatorListPage from '../../../../../presentation/pages/operators/list'
import { settings } from '../../../../configs/settings'
import { makeRemoteOperator } from '../../../usecases/operator/remote-operator-factory'

const generateFilter = (values: any):string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'startedAt' || el === 'completedAt') {
      return `${el}>=${values[el]}`
    }
    if (el === 'serviceProvider') {
      return `${el}=${values[el]}`
    }
    if (el === 'name') {
      return `${el}~${values[el]}`
    }
    return el
  }).join(',')

function MakeOperatorList(): JSX.Element {
  const { profile, user } = useAuth()
  const [list, setList] = useState<OperatorResponseModel>()
  const [params, setParams] = useState<FilterModel>({ })
  const [loading, setLoading] = useState<boolean>()

  const loadData = async (): Promise<void> => {
    setLoading(true)
    const data = await makeRemoteOperator().load({
      size: settings.perPage,
      page: params?.page,
      query: params?.query,
      filters: generateFilter({
        ...params?.filters,
        ...((profile?.branch?.uuid || user.uuid)
        && { serviceProvider: profile?.branch?.uuid || user.uuid }),
      }),
    })
    setList(data)
    setLoading(false)
  }

  const exportList = (): Promise<void> => makeRemoteOperator().exportCSV({
    filters: generateFilter({
      ...params?.filters,
      ...((profile?.branch?.uuid || user.uuid)
      && { serviceProvider: profile?.branch?.uuid || user.uuid }),
    }),
  })

  useEffect(() => {
    loadData()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, profile, user])

  return (
    <OperatorListPage
      params={params}
      list={list}
      setParams={setParams}
      loading={loading}
      exportList={exportList}
    />
  )
}

export default MakeOperatorList
