// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useAlertBox } from '../../../../../contexts/alert-box/alert_box'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { CustomerResponseModel } from '../../../../../domain/models/customer'
import { EnumTypesProps } from '../../../../../domain/models/enum'
import { OperatorResponseModel } from '../../../../../domain/models/operator'
import { OptionProps } from '../../../../../domain/models/option-prop'
import { ServiceProviderProps } from '../../../../../domain/models/service-provider'
import { TaskModelResponse } from '../../../../../domain/models/task'
import { PhotosResponseModel } from '../../../../../domain/models/task-photo'
import { t } from '../../../../../presentation/adapters/translate/translate'
import { checkIsCustomer } from '../../../../../presentation/helpers/utils'
import TaskInternalPage from '../../../../../presentation/pages/task/task-internal'
import { makeRemoteCustomer } from '../../../usecases/customer/remote-customer-factory'
import { makeVehicleTypes } from '../../../usecases/enum/remote-enum-factory'
import { makeRemoteOperator } from '../../../usecases/operator/remote-operator-factory'
import { makeRemoteServiceProvider } from '../../../usecases/service-provider/remote-service-provider-factory'
import { makeRemoteServiceType } from '../../../usecases/service-type/remote-service-type-factory'
import { makeRemoteTask } from '../../../usecases/task/remote-task-factory'

const generateFilter = (values: any):string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'serviceProvider') {
      return `${el}=${values[el]}`
    }
    return el
  }).join(',')

function MakeTaskInternal({ match }:any): JSX.Element {
  const { handleAlert } = useAlertBox()
  const [vehiclesTypes, setVehiclesTypes] = useState<OptionProps[]>([])
  const [serviceTypes, setServiceTypes] = useState<OptionProps[]>([])
  const [operatorList, serviceOperatorList] = useState<OptionProps[]>([])
  const [customerList, setCustomerList] = useState<OptionProps[]>([])
  const [serviceProvider, setServiceProvider] = useState<ServiceProviderProps[]>()
  const [photos, setImages] = useState<PhotosResponseModel>()
  const [data, setData] = useState<TaskModelResponse>()
  const [serviceProviderByCustomer, setSp] = useState<ServiceProviderProps>()
  const { profile, user } = useAuth()
  const history = useHistory()
  const loadEnumsTypes = async (): Promise<void> => {
    try {
      const serviceTypeData: EnumTypesProps[] = await makeRemoteServiceType().load()
      setServiceTypes(serviceTypeData.map((el) => ({ value: el.id, label: el.name })))
      const vehiclesData: EnumTypesProps[] = await makeVehicleTypes().load()
      setVehiclesTypes(vehiclesData.map((el) => ({ value: el.id, label: el.name })))

      if (!checkIsCustomer()) {
        const op:OperatorResponseModel = await makeRemoteOperator().load({
          size: 100,
          filters: generateFilter({
            ...({ serviceProvider: profile?.branch?.uuid || user?.uuid }),
          }),
        })
        serviceOperatorList(op.content.map((el: any) => ({ value: el.id || 0, label: el.name || '' })))
        const customerData: CustomerResponseModel = await makeRemoteCustomer()
          .loadCustomersByHeadOffice({
            filters: generateFilter({
              ...({ serviceProvider: profile?.branch?.uuid || user?.uuid }),
            }),
          })
        setCustomerList(customerData.content.map((el) => ({ value: el.id || 0, label: el.name })))
      } else if (checkIsCustomer() && profile.isHeadOffice) {
        const sp = await makeRemoteServiceProvider().branchs({ serviceProvider: user?.sub })
        setServiceProvider(sp)
      }
    } catch (error) {
      console.error(error)
    }
  }

  const loadData = async (): Promise<void> => {
    const content = await makeRemoteTask().loadById(match?.params?.id)
    setData(content)
    const images = await makeRemoteTask().loadPhotos(match?.params?.id)
    setImages(images)
  }

  useEffect(() => {
    (async () => {
      const res = await makeRemoteCustomer().getServiceProviderByCustomer({
        customerUUID: profile?.branch?.uuid || user?.uuid,
      })
      setSp(res[0])
    })()
  }, [profile?.branch?.uuid, user?.uuid])
  useEffect(() => {
    (async () => {
      if (match?.params?.id && match?.params?.id !== 'create') {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  useEffect(() => {
    (async () => {
      if (user) {
        await loadEnumsTypes()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  const handleSubmit = async (values: any, actions: any): Promise<any> => {
    try {
      if (data?.id) {
        const updatedData = await makeRemoteTask().update(data?.id, {
          ...data,
          ...(!checkIsCustomer()
          && { assigneeOperator: { id: values?.assigneeOperator?.value } }),
          customer: { id: values?.customer?.value || values?.customer?.id },
          licensePlate: values?.licensePlate,
          vehicleType: { id: values?.vehicleType?.value },
          serviceType: { id: values?.serviceType?.value },
          bayNumber: values?.bayNumber?.value,
          ...(checkIsCustomer()
          && {
            serviceProvider:
             {
               id: profile?.branch?.id || values?.customer?.serviceProvider?.id
               || serviceProviderByCustomer?.id || user?.id,
             },
          }),
        })
        setData(updatedData)
      } else {
        const createData = await makeRemoteTask().create({
          ...(!checkIsCustomer()
          && { assigneeOperator: { id: values?.assigneeOperator?.value } }),
          customer: {
            id: values?.customer?.value || values?.customer?.id
             || profile?.branch?.id || user?.id,
          },
          licensePlate: values?.licensePlate,
          vehicleType: { id: values?.vehicleType?.value },
          serviceType: { id: values?.serviceType?.value },
          bayNumber: values?.bayNumber?.value,
          serviceProvider: {
            id:
            values?.customer?.serviceProvider?.id
            || profile?.branch?.serviceProvider?.id || profile?.branch?.id
            || serviceProviderByCustomer?.id || user?.id,
          },
        })
        history.push(`/tasks/${createData.id}`)
      }

      handleAlert({
        show: true,
        type: 'success',
        message: 'Registro Salvo Com sucesso',
      })
      actions.setSubmitting(false)
    } catch (error: any) {
      console.error('>>>', error)
      handleAlert({
        show: true,
        type: 'danger',
        message: t(error.code, error.message),
      })
      actions.setSubmitting(false)
    }
  }
  return (
    <TaskInternalPage
      data={data}
      assigneeOperatorList={operatorList}
      vehicleTypeList={vehiclesTypes}
      customerList={customerList}
      handleSubmit={handleSubmit}
      serviceTypes={serviceTypes}
      photos={photos}
      serviceProviderList={serviceProvider}
    />
  )
}

export default MakeTaskInternal
