/* eslint-disable react/destructuring-assignment */
import React, {
  useContext, useState, createContext,
} from 'react'
import { Alert } from 'react-bootstrap'

export const AlertContext: any = createContext(null)

type AlertProps = {
  show: boolean
  message?: string
  type?: 'success' | 'danger' | 'warning'
  time?: number
}

const AlertBoxProvider = (props: any): JSX.Element => {
  const [options, setOptions] = useState<AlertProps>()

  const closeAlert = (): void => {
    setOptions(() => ({ show: false }))
  }

  const closeAfter = (time: number):void => {
    setTimeout(() => {
      closeAlert()
    }, time)
  }

  const handleAlert = (values: any): void => {
    closeAlert()
    setOptions(() => ({ ...values }))
    closeAfter(values.time || 2000)
  }
  return (
    <AlertContext.Provider
      value={{
        options,
        handleAlert,
        closeAlert,
      }}
    >
      {props.children}
      {options?.show && (
      <Alert key={options?.type} variant={options?.type}>
        {options?.message}
      </Alert>
      )}
    </AlertContext.Provider>
  )
}

export const useAlertBox = (): any => {
  const context: any = useContext(AlertContext)
  if (!context) {
    throw new Error('useModal must be used within a provider AlertBoxProvider')
  }

  return { ...context }
}

export default AlertBoxProvider
