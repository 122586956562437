import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const Card = styled.div`
  box-shadow:  0px 7px 50px rgba(210, 214, 227, 0.4);
  background-color: ${theme.colors.white};
  border-radius: 24px;
  width: 100%;
  height: 100%;
`

export const SubtitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-bottom: 15px;
  p{
    font-family: 'palanquin';
    font-weight: 500;
    font-size: 1.2rem;
    margin-bottom: 0;
  }
  span{
    margin-left: 10px;
    font-family: 'poppins';
    font-weight: 400;
    font-size: .6rem;
    color: ${theme.colors.green};
  }
`
export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 30px;
`
export const ContentTitle = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;
`

export const BoxButton = styled.button`
  width: 70%;
  background-color: ${theme.colors.green};
  border-radius: 24px;
  padding: 10px 15px;
  justify-content: center;
  display: flex;
  font-size: 12px;
  align-items: center;
  color: ${theme.colors.white};
  text-decoration: none;
  transition:  ease all 400ms;
  &:hover{
    opacity: .7;
  }
`
export const ImageStyled = styled.img`
  width: 75%;
  align-self: flex-end;
`
export const LabelMeta = styled.span`
   color: ${theme.colors.darkGray};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: .6rem;
  display: flex;
  align-items: center;
  line-height: 1;
  margin-right: 10px;
  &:before{
    content:'';
    width: 8px;
    border-radius: 8px;
    height: 8px;
    margin-right: 5px;
    display: flex;
    background-color: ${theme.colors.lightBlue};
  }
`
export const LabelTask = styled.span`
   color: ${theme.colors.darkGray};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: .6rem;
  display: flex;
  align-items: center;
  line-height: 1;
  &:before{
    content:'';
    width: 8px;
    border-radius: 8px;
    height: 8px;
    margin-right: 5px;
    display: flex;
    background-color: ${theme.colors.primary};
  }
`

export const Title = styled.h2`
  color: ${theme.colors.darkGray};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: .8rem;
  letter-spacing: 1px;
`
