import { Col, Row } from 'react-bootstrap'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

export const WrapperModal = styled.div`
  width: 100%;
  padding: 15px 30px;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`
export const CustomerBox = styled.div`
  width: 100%;
  background-color: ${theme.colors.lightBlue};
  border-radius: 20px;
  padding: 20px 15px;
`
export const TextStyled = styled.p`
  font-family: 'poppins';
  color: ${theme.colors.black};
  font-weight: 400;
  display: flex;
  align-items: center;
  /* max-width: 30%; */
  font-size: .7rem;
  margin-bottom: 0;
  svg{
    margin-right: 10px;
    min-width: 20px;
  }
`
export const ColStyled = styled(Col)`
  padding:0;
  margin-top: 5px;
`
export const RowStyled = styled(Row)`
  margin: 5px 0;
  `
export const CloseButton = styled.button`
  cursor: pointer;
  background-color: transparent;
`
export const LabelStyled = styled.div`
 font-family: 'poppins';
  font-weight: 300;
  color: ${theme.colors.darkGray};
  font-size: .7rem;
  margin-bottom: 5px;
  `
export const NameCustomerStyled = styled.h3`
  font-size: 1.4rem;
  font-family: 'palanquin';
  font-weight: 600;
  color: ${theme.colors.black};
`
export const TitleStyled = styled.h4`
  font-family: 'palanquin';
  font-weight: 600;
  font-size: 1rem;
  color: ${theme.colors.black};
  svg{
    margin-right: 5px;
  }
`
export const ProgressBarStyled = styled.progress`
  height: 30px;
  width: 75%;
  margin-right: 5px;
`
export const DocumentsContainer = styled.div`
  margin-top: 30px;
`
export const RowTitle = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
`
export const DownloadButtonAll = styled.button`
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.lightGray};
  color: ${theme.colors.darkGray};
  border: 1px solid ${theme.colors.darkGray};
  border-radius: 100px;
  padding: 10px 15px;
  font-family: 'poppins';
  font-weight: 400;
  font-size: .8rem;
  svg{
    margin-right: 5px;
  }
`
export const DocumentsList = styled.div`
    height: 220px;
    overflow: auto;
    margin-top: 15px;
    background-color: #fff;
    ::-webkit-scrollbar {
        width: 2px;
        border-radius: 5px;
      }

      /* Track */
      ::-webkit-scrollbar-track {
        background: #f1f1f1;
        border-radius: 5px;
      }

      /* Handle */
      ::-webkit-scrollbar-thumb {
        background: #888;
        border-radius: 5px;
      }

      /* Handle on hover */
      ::-webkit-scrollbar-thumb:hover {
        background: #555;
        border-radius: 5px;
      }
`
export const SaveButton = styled.button`
  border: 1px solid ${theme.colors.primary};
  background-color: #fff;
  color: ${theme.colors.primary};
  padding: 15px;
  width: 47%;
  border-radius: 50px;
  transition: ease all 400ms;
  &:hover{
    opacity:.6;
  }
`
export const HeadModal = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
  padding: 0 30px;
  margin-bottom: 10px;
`
export const ActionButton = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 15px;
`
export const SaveSendButton = styled.button`
  background-color: ${theme.colors.primary};
  color: #fff;
  padding: 15px;
  width: 100%;
  border-radius: 50px;
  transition: ease all 400ms;
  &:hover{
    opacity:.6;
  }
  &:disabled{
    background-color: ${theme.colors.lightGray};
    opacity:.6;
    color: ${theme.colors.black};
  }
`
export const DownloadButtonStyled = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.lightBlue};
  color: ${theme.colors.darkGray};
  border-radius: 100px;
  padding: 10px 15px;
  font-family: 'poppins';
  font-weight: 400;
  font-size: .8rem;
  width: 45px;
  height: 45px;
`
export const UploadButtonStyled = styled.label`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${theme.colors.lightBlue};
  color: ${theme.colors.primary};
  border:dashed 2px #0658c2;
  border-radius: 100px;
  padding: 10px 15px;
  font-family: 'poppins';
  /* max-width: 45%; */
  font-weight: 300;
  font-size: .8rem;
  span{
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    overflow: hidden;
    width: 17ch;
  }
  svg{
    margin-right: 5px;
  }
`
export const DocumentsItem = styled.div`
  margin-bottom: 7px;
  box-shadow: 0px -1px 5px rgb(210 214 227 / 40%);
  border: 1px solid #EFEFEF;
  background: #FFFFFF;
  border-radius: 20px;
  padding: 10px;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  p{
    margin: 0;
  }
`
