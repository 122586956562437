import axios from 'axios'
import fileDownload from 'react-file-download'
import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { Customer, CustomerProps, CustomerResponseModel } from '../../../domain/models/customer'
import { FilterModel } from '../../../domain/models/enum'
import { ServiceProviderProps } from '../../../domain/models/service-provider'
import Authentication from '../../../infra/cognito/authentication'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteCustomer implements Customer {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
    private readonly baseUrl: string | undefined,
  ) { }

  async load(): Promise<CustomerResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadById(id: number): Promise<CustomerProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async getServiceProviderByCustomer(params?: FilterModel): Promise<ServiceProviderProps[]> {
    console.log(params)
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/service-providers`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async create(body: CustomerProps): Promise<CustomerProps> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'post',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      case HttpStatusCode.serverError: throw httpResponse.body
      case HttpStatusCode.badRequest: throw httpResponse.body
      case HttpStatusCode.conflict: throw httpResponse.body
      default: throw httpResponse.body
    }
  }

  async update(id:number, body: CustomerProps): Promise<CustomerProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async delete(id:number): Promise<CustomerProps> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'delete',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadCustomersByHeadOffice(params: FilterModel): Promise<CustomerResponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async all(): Promise<CustomerProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/all`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async branchs(params?: FilterModel): Promise<CustomerProps[]> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/branch-offices`,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async exportCSV(params: FilterModel): Promise<void> {
    const token = await Authentication.getToken()
    const resp = await axios.get(`${this.baseUrl}${this.url}/export`, {
      responseType: 'blob',
      params,
      headers: {
        Authorization: token,
        accept: '*/*',
      },

    })
    fileDownload(resp.data, `Operadores - ${new Date().getTime()}.xlsx`)
  }
}
