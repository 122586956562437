/* eslint-disable react/destructuring-assignment */
import {
  Auth,
} from 'aws-amplify'
import React, {
  createContext, useContext, useEffect, useState,
} from 'react'
import { useHistory } from 'react-router-dom'
import { EnumTypes } from '../../domain/models/enum'
import { UserProps } from '../../domain/models/user'
import Authentication from '../../infra/cognito/authentication'
import LocalStorage from '../../infra/localStorage'
import { makeRemoteCustomer } from '../../main/factories/usecases/customer/remote-customer-factory'
import { makeRemoteServiceProvider } from '../../main/factories/usecases/service-provider/remote-service-provider-factory'
import { makeRemoteUser } from '../../main/factories/usecases/user/remote-user-factory'
// eslint-disable-next-line import/no-cycle
import { checkNotHeadOffice } from '../../presentation/helpers/utils'

const AuthContext: any = createContext(null)

export const Roles = {
  ADMIN: 'Admin',
  HEAD_OFFICE: 'Head-Office',
  BRANCH_OFFICE: 'Branch-Office',
  CUSTOMER_HEAD_OFFICE: 'Customer-Head-Office',
  CUSTOMER_BRANCH_OFFICE: 'Customer-Branch-Office',
}

export const branchByUserType = (userType: string, user: UserProps | null | undefined):any => {
  if (checkNotHeadOffice(userType)) {
    return []
  }
  if (userType === Roles.BRANCH_OFFICE || userType === Roles.HEAD_OFFICE) {
    return makeRemoteServiceProvider().branchs({
      ...(user?.uuid && { uuid: user?.uuid }),
    })
  }
  if (userType === Roles.CUSTOMER_HEAD_OFFICE || userType === Roles.CUSTOMER_BRANCH_OFFICE) {
    return makeRemoteCustomer().branchs({
      ...(user?.uuid && { customerUUID: user?.uuid }),
      // isHeadOffice: true,
    })
  }
  return null
}
export const checkHeadOffice = (userType: string):boolean => {
  if (userType === Roles.HEAD_OFFICE || userType === Roles.CUSTOMER_HEAD_OFFICE) {
    return true
  }
  return false
}
export type ProfileProps = { isHeadOffice: boolean, branch: EnumTypes | undefined } | null

export const AuthProvider = (props: any): JSX.Element => {
  const [isLogged, setIsLogged] = useState(false)
  const [user, setUser] = useState<UserProps | null>()
  const [profile, setUserProfile] = useState<ProfileProps>()
  const [reloaduser, setReloadUser] = useState<any>(false)
  const history = useHistory()
  const [loading, setLoading] = useState(false)

  const setIsheadeOffice = async (isHeadOffice: boolean): Promise<void> => {
    await LocalStorage.set('isHeadOffice', isHeadOffice)
    const profileData = await Authentication.getProfileInfo()
    setUserProfile(profileData)
  }
  const setUserLocal = async (userParam: UserProps): Promise<void> => {
    await LocalStorage.set('user', userParam)
    const userData = await Authentication.getUserInfo()
    setUser(userData)
  }
  const setAdminLocal = async (userParam: UserProps): Promise<void> => {
    await LocalStorage.set('admin', userParam)
    const userData = await Authentication.getUserInfo()
    setUser(userData)
  }
  const getAdminLocal = (): Promise<void> => {
    const e = LocalStorage.get('admin')
    return e
  }

  const setBranch = async (branch: EnumTypes | undefined): Promise<void> => {
    await LocalStorage.set('branch', branch || undefined)
    const profileData = await Authentication.getProfileInfo()
    setUserProfile(profileData)
  }

  useEffect(() => {
    (async () => {
      const userData = await Authentication.getUserInfo()
      setUser(userData)
    })()
  }, [])

  useEffect(() => {
    (async () => {
      const profileData = await Authentication.getProfileInfo()
      setUserProfile(profileData)
      try {
        const isAuth = await Authentication.isAuth()

        const userDatabase = await Auth.currentAuthenticatedUser()
        const me = await makeRemoteUser().load()

        const branchs = await branchByUserType(me?.roles[0]?.authority, me)
        if ((!user && isAuth && userDatabase) || (history?.location?.pathname === '/login' && isAuth && userDatabase)) {
          setUserLocal({
            name: userDatabase.attributes.name || me?.name,
            email: userDatabase.attributes.email,
            'custom:service_provider_id': userDatabase.attributes['custom:service_provider_id'],
            sub: userDatabase.attributes.sub,
            roles: me.roles,
            type: me.type,
            uuid: me.uuid,
            id: me.id,
          })
          if (me?.roles[0]?.authority === 'Admin') {
            history.push('/select-env')
          }
          if (!!me && checkHeadOffice(me?.roles[0]?.authority) && !profileData) {
            history.push('/select-env')
          } else if (me && branchs?.length && !profileData) {
            setIsheadeOffice(false)
            history.push('/select-branch')
          } else {
            history.push('/')
          }
        }
      } catch (error) {
        await Authentication.logout()
        console.error('AUTH ERROR', error)
        history.push('/login')
      }
    })()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reloaduser, history])

  useEffect(() => {
    const alertUser = (e:any):any => {
      e.preventDefault()
      e.returnValue = ''
      console.log(e); (async () => {
        console.log('AQUI')
        const adm = getAdminLocal()
        if (adm) {
          await setUserLocal(adm as any)
          await setBranch(null as any)
          await setIsheadeOffice(true)
          history.push('/')
        } else {
          await setBranch(null as any)
          await setIsheadeOffice(true)
          history.push('/')
        }
      })()
    }
    window.addEventListener('beforeunload', alertUser)
    return () => {
      window.removeEventListener('beforeunload', alertUser)
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const auth = (): any => null

  const reloadUser = (): any => setReloadUser((prev: boolean) => !prev)

  return (
    <AuthContext.Provider
      value={{
        isLogged,
        setIsLogged,
        userLoaded: !!user,
        loading,
        user,
        setUser,
        setLoading,
        auth,
        reloadUser,
        profile,
        setIsheadeOffice,
        setBranch,
        setUserLocal,
        setAdminLocal,
        getAdminLocal,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  )
}

export const useAuth = (): any => {
  const context: any = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a provider AuthProvider')
  }
  return { ...context }
}
