/* eslint-disable no-return-await */
/* eslint-disable no-nested-ternary */
import moment from 'moment'
import React, { useState } from 'react'
import { Rating } from 'react-simple-star-rating'
import { FilterModel } from '../../../../domain/models/enum'
import { TaskReponseModel } from '../../../../domain/models/task'
import DataGrid, { ItemField } from '../../../components/datagrid'
import FilterBar from '../../../components/filter-bar'
import Icons from '../../../components/icons-helper'
import { checkIsCustomer, getColorByStatus, parseStatus } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'
import {
  ButtonComponentStyled,
  ButtonStyled,
  CheckboxStyled,
  ContainerButton,
  DateContainer,
  DateStyled,
  ExportButtonComponentStyled,
  FieldCheckbox,
  FilterStyled,
  LabelStyled,
  RatingStyled, SperatorStyled,
  StatusStyled, TitleContainer,
  TitleStyled, TitleStyledFilter, Wrapper,
} from './styles'

type PageProps = {
  list: TaskReponseModel | undefined
  setParams: any
  loading?:boolean
  exportList?: ()=> Promise<void>
}

type FilterComponentProps = {
  submit:(value:string, dates: string)=>void
}

const FilterComponent = ({ submit }:FilterComponentProps):JSX.Element => {
  const [values, setValues] = useState<any>({})
  const [valuesDate, setValuesDate] = useState<any>({})
  const handleChange = (e:any):void => {
    setValues(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }
  const handleChangeDate = (e:any):void => {
    setValuesDate(
      (prev: any) => ({
        ...prev,
        [e.target.name]: e.target.checked === true ? e.target.value : e.target.type === 'date' ? e.target.value : null,
      }
      ),
    )
  }
  console.log(values)

  const applyFilter = (): void => {
    submit(values, valuesDate)
  }

  return (
    <FilterStyled>
      <SperatorStyled />
      <TitleStyledFilter>
        Status
      </TitleStyledFilter>
      <FieldCheckbox>
        <CheckboxStyled
          name="status=COMPLETED"
          value="COMPLETED"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Concluída
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="IN_PROGRESS"
          name="status=IN_PROGRESS"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        Em andamento
      </FieldCheckbox>
      <FieldCheckbox>
        <CheckboxStyled
          value="TODO"
          name="status=TODO"
          type="checkbox"
          onChange={handleChange}
        />
        {' '}
        A fazer
      </FieldCheckbox>
      <SperatorStyled />
      <TitleStyledFilter>
        Data
      </TitleStyledFilter>
      <DateContainer>

        <LabelStyled>
          De
          <DateStyled type="date" name="start" onChange={handleChangeDate} />
        </LabelStyled>
        <LabelStyled>
          Até
          <DateStyled type="date" name="end" onChange={handleChangeDate} />
        </LabelStyled>
      </DateContainer>
      <ButtonStyled onClick={() => applyFilter()}>Aplicar</ButtonStyled>
    </FilterStyled>
  )
}
const TaskListPage = ({
  list, setParams, loading, exportList,
}:PageProps):JSX.Element => {
  const fields :ItemField[] = [
    { title: 'Data do Serviço', key: 'serviceDate', renderItem: (data) => moment(data.serviceDate.toString()).format('DD/MM/YYYY') },
    {
      title: 'Responsável', key: 'assigneeOperator', renderItem: (data) => data?.assigneeOperator?.name || 'Não definido', hide: checkIsCustomer(),
    },
    {
      title: 'Avaliação do cliente',
      key: 'rating',
      hide: checkIsCustomer(),
      renderItem: (data) => (
        <RatingStyled>
          <Rating
            ratingValue={0}
            initialValue={data.rating}
            readonly
            size={15}
            allowHalfIcon
          />
          <p>{Number(0).toFixed(1)}</p>
        </RatingStyled>
      ),
    },
    { title: checkIsCustomer() ? 'Prestador de serviço' : 'Cliente', key: 'customer', renderItem: (data) => (checkIsCustomer() ? data.serviceProvider.name : data.customer.name) },
    { title: 'Automóvel', key: 'vehicleType', renderItem: (data) => data.vehicleType.name },
    { title: 'Placa', key: 'licensePlate', renderItem: (data) => data.licensePlate },
    {
      title: 'Status',
      key: 'status',
      renderItem: (data) => (
        <StatusStyled
          color={!data?.assigneeOperator?.name ? theme.colors.red : getColorByStatus(data.status)}
        >
          {!data?.assigneeOperator?.name ? 'Solicitado' : parseStatus(data.status)}
        </StatusStyled>
      ),
    },
    {
      title: 'Avaliar',
      key: 'status-review',
      hide: !checkIsCustomer(),
      renderItem: (data) => (
        <>
          {data?.status === 'COMPLETED' && (
          <div className="d-flex justify-content-center ms-2">
            <Icons
              name="star-action"
              width={25}
              height={25}
              color={theme.colors.yellow}
            />
          </div>
          )}

        </>

      ),
    },
  ]

  const handlePage = (e:any):void => {
    setParams((prev: FilterModel) => ({
      ...prev,
      page: e,
    }))
  }
  const handleSearch = (callback:any):void => {
    const val = callback()

    setParams(
      (prev: any) => ({
        ...prev,
        filters: { ...prev?.filters, licensePlate: val.query },
      }
      ),
    )
  }

  return (
    <Wrapper>
      <TitleContainer>
        <TitleStyled>Tarefas</TitleStyled>
      </TitleContainer>
      <FilterBar
        handleFilter={handleSearch}
        filterComponent={(
          <FilterComponent
            submit={(values: any, dates: any) => setParams((prev: any) => ({
              ...prev,
              filters: { ...prev?.filters, ...values },
              dates: { ...dates },
            }))}
          />
)}
      >
        <ContainerButton>
          <ButtonComponentStyled to="/tasks/create">
            <Icons
              name="plus"
              width={15}
              height={15}
              color={theme.colors.white}
            />
            Novas tarefas
          </ButtonComponentStyled>

          <ExportButtonComponentStyled onClick={async () => {
            if (exportList) {
              await exportList()
            }
          }}
          >
            Exportar
          </ExportButtonComponentStyled>
        </ContainerButton>
      </FilterBar>
      <DataGrid
        loading={!!loading}
        fields={fields}
        data={list?.content || []}
        routeToEdit="/tasks/"
        totalElements={list?.totalElements || 0}
        currentPage={list?.number || 0}
        totalPages={list?.totalPages || 0}
        changePage={handlePage}
      />
    </Wrapper>
  )
}
export default TaskListPage
