import React, { useEffect, useState } from 'react'
import {
  Card, Content, IconStyled, Title,
} from './styles'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import { makeRemoteTask } from '../../../../main/factories/usecases/task/remote-task-factory'

type CardProps = {
  period: string
  isHeadOffice: boolean
  serviceProvider?: string | undefined
  customer?: string | undefined
}

const CountTasksCustomer = ({
  period, isHeadOffice, serviceProvider, customer,
}:CardProps):JSX.Element => {
  const [data, setData] = useState<number>(0)

  useEffect(() => {
    if (customer || serviceProvider) {
      (async () => {
        const response = await makeRemoteTask().countTasksCustomer({
          period,
          isHeadOffice,
          serviceProvider,
          customer,
        })
        setData(response)
      })()
    }
  }, [period, isHeadOffice, serviceProvider, customer])

  return (
    <Card>
      <Content>
        <IconStyled>
          <Icons name="car" width={26} height={26} color={theme.colors.white} />
        </IconStyled>
        <Title>
          Tarefas realizadas
        </Title>
        {data}
      </Content>
    </Card>
  )
}
export default CountTasksCustomer
