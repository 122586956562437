import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { FilterModel } from '../../../domain/models/enum'
import { ProductStock, ProductStockReponseModel, StockProps } from '../../../domain/models/stock'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteProducStock implements ProductStock {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
  ) { }

  async load(params?: FilterModel): Promise<ProductStockReponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async loadById(id: number): Promise<StockProps.Model> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async update(id: number, body: StockProps.Model): Promise<StockProps.Model> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async create(body: StockProps.Model): Promise<StockProps.Model> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}`,
      method: 'post',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
