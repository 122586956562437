// import moment from 'moment'
import React, { useEffect, useState } from 'react'
import { useAuth } from '../../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../../domain/models/enum'
import { ServiceProviderProps, ServiceProviderResponseModel } from '../../../../../domain/models/service-provider'
import ServiceProviderBranchsListPage from '../../../../../presentation/pages/service-provider/internal/branchs'
import { settings } from '../../../../configs/settings'
import { makeRemoteServiceProvider } from '../../../usecases/service-provider/remote-service-provider-factory'

const generateFilter = (values: any):string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'headOfficeServiceProvider') {
      return `${el}=${values[el]}`
    }
    return el
  }).join(',')

type MakeProps = {
  serviceProvider:ServiceProviderProps | undefined
}

function MakeServiceProviderBranchs({ serviceProvider }:MakeProps): JSX.Element {
  const [list, setList] = useState<ServiceProviderResponseModel>()
  const [params, setParams] = useState<FilterModel>({ })
  const [loading, setLoading] = useState<boolean>()
  const { user } = useAuth()

  const loadData = async (): Promise<void> => {
    try {
      setLoading(true)
      const content = await makeRemoteServiceProvider().load({
        size: settings.perPage,
        page: params?.page,
        query: params?.query,
        filters: generateFilter({
          ...params?.filters,
          headOfficeServiceProvider: serviceProvider?.uuid,
        }),
      })
      setList(content)
      setLoading(false)
    } catch (error) {
      console.error(error)
    }
  }

  useEffect(() => {
    (async () => {
      if (serviceProvider?.id) {
        await loadData()
      }
    })()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serviceProvider?.id, user, params])

  return (
    <ServiceProviderBranchsListPage
      list={list}
      loading={loading}
      setParams={setParams}
      params={params}
    />
  )
}

export default MakeServiceProviderBranchs
