import React from 'react'
import FormFactory from '../../../components/form-factory'
import { Title } from '../styles'
import { Card, Wrapper } from './styles'

type PageProps ={
  handleSubmit: (values: any, actions: any) => void
}
const ChangePasswordPage = ({ handleSubmit }:PageProps):JSX.Element => {
  const fields = [
    {
      groupName: 'Login',
      items: [
        {
          name: 'currentPassword',
          type: 'password',
          placeholder: 'Digite sua senha atual',
          validations: 'passwordMinRequired',
          col: {
            md: 12,
            lg: 12,
          },
        },
        {
          name: 'password',
          type: 'password',
          placeholder: 'Digite sua nova senha',
          validations: 'passwordMinRequired',
          col: {
            md: 12,
            lg: 12,
          },
        },
        {
          name: 'confirmPassword',
          type: 'password',
          placeholder: 'Confirme sua nova senha',
          validations: 'passwordMinRequired',
          col: {
            md: 12,
            lg: 12,
          },
        },
      ],
    },
  ]
  return (
    <Wrapper>
      <Title>Alterar Senha</Title>
      <div className="mt-2">
        <Card>
          <FormFactory
            className="internal-form mb-3"
            groups={fields}
            onSubmit={async (values, actions) => {
              try {
                await handleSubmit(values, actions)
                actions.setSubmitting(false)
              } catch (error) {
                actions.setSubmitting(false)
              }
            }}
            button={{ submit: 'Salvar', submitting: 'Salvando...' }}
          />
        </Card>

      </div>
    </Wrapper>
  )
}
export default ChangePasswordPage
