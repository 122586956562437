/* eslint-disable no-mixed-operators */
/* eslint-disable radix */
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

const adjust = (color: any, amount: any): any => `#${color.replace(/^#/, '').replace(/../g, (col: any) => (`0${Math.min(255, Math.max(0, parseInt(col, 16) + amount)).toString(16)}`).substr(-2))}`

export const Card = styled.div`
  box-shadow:  0px 7px 50px rgba(210, 214, 227, 0.4);
  background-color: ${theme.colors.white};
  border-radius: 24px;
  width: 100%;
  font-family: ${theme.font.poppins};
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 20px;
`

export const Title = styled.h2`
  color: ${theme.colors.black};
  font-family: ${theme.font.palanquin};
  font-weight: 600;
  font-size: .9rem;
  margin-top: 12px;
  margin-bottom: 5px;
`
export const RatingStyled = styled.div`
  color: ${theme.colors.middleGray};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: .6rem;
  p{
    margin-top: 3px;
    margin-bottom: 0;
    margin-left: 5px;
  }
  line-height: 1;
  align-items: center;
  display: flex;
`
export const Name = styled.div`
  color: ${theme.colors.black};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: .8rem;
  margin-bottom: 0px;
`
export const Info = styled.div`
  display: flex;
`
type AvatarProps = {
  color: string
}

export const Avatar = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-family: ${theme.font.palanquin};
  align-items: center;
  background-color: ${(p: AvatarProps) => p.color};
  color: ${(p: AvatarProps) => adjust(p.color, -110)};
  margin-right: 10px;
  font-weight: 700;
  aspect-ratio: 1;
`
export const BoxOperator = styled(Link)`
display: flex;
align-items: center;
font-family: ${theme.font.poppins};
font-weight: 300;
font-size: 12px;
line-height: 1.6;
margin-top: 15px;
justify-content: space-between;
  svg {
  margin-top: 2px;
}
`
