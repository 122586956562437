import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import {
  Card, Content, IconStyled, Title,
} from './styles'
import { theme } from '../../../styles/theme'
import Icons from '../../icons-helper'
import { makeRemoteAdmin } from '../../../../main/factories/usecases/admin/remote-admin-factory'

type CardProps = {
  period: string
}

const CustomerCount = ({
  period,
}:CardProps):JSX.Element => {
  const [data, setData] = useState<number>(0)

  useEffect(() => {
    (async () => {
      const response = await makeRemoteAdmin().customerCount({
        period,
      })
      setData(response)
    })()
  }, [period])

  return (
    <Card>
      <Link to="/customer">
        <Content>
          <IconStyled>
            <Icons name="car" width={26} height={26} color={theme.colors.white} />
          </IconStyled>
          <Title>
            Clientes
          </Title>
          {data}
        </Content>
      </Link>
    </Card>
  )
}
export default CustomerCount
