import React, { useEffect, useState } from 'react'
import { Roles, useAuth } from '../../../../../contexts/auth-context/auth-context'
import { FilterModel } from '../../../../../domain/models/enum'
import { ServiceProviderResponseModel } from '../../../../../domain/models/service-provider'
import { checkIsCustomer, isAdmin } from '../../../../../presentation/helpers/utils'
import ServiceProviderListPage from '../../../../../presentation/pages/service-provider/list'
import { settings } from '../../../../configs/settings'
import { makeRemoteAdmin } from '../../../usecases/admin/remote-admin-factory'
import { makeRemoteCustomer } from '../../../usecases/customer/remote-customer-factory'
import { makeRemoteServiceProvider } from '../../../usecases/service-provider/remote-service-provider-factory'

const generateFilter = (values: any):string => Object.keys(values)
  .filter((el) => !!values[el]).map((el) => {
    if (el === 'startedAt' || el === 'completedAt') {
      return `${el}>=${values[el]}`
    }
    if (el === 'serviceProvider') {
      return `${el}=${values[el]}`
    }
    if (el === 'uuid') {
      return `${el}=${values[el]}`
    }
    if (el === 'headOfficeCustomer') {
      return `${el}=${values[el]}`
    }
    if (el === 'headOfficeServiceProvider') {
      return `${el}=${values[el]}`
    }
    if (el === 'name') {
      return `${el}~${values[el]}`
    }
    return el
  }).join(',')

function MakeServiceProviderList(): JSX.Element {
  const { profile, user } = useAuth()
  const [list, setList] = useState<ServiceProviderResponseModel>()
  const [params, setParams] = useState<FilterModel>({ })
  const [loading, setLoading] = useState<boolean>()

  const getRequestBytypeUser = (userType: string, customParams: FilterModel):any => {
    if (userType === Roles.ADMIN) {
      return makeRemoteAdmin().loadServiceProviders(customParams)
    }
    if (userType === Roles.BRANCH_OFFICE || userType === Roles.HEAD_OFFICE) {
      return makeRemoteServiceProvider().load(customParams)
    }
    if (userType === Roles.CUSTOMER_HEAD_OFFICE || userType === Roles.CUSTOMER_BRANCH_OFFICE) {
      return makeRemoteCustomer().loadCustomersByHeadOffice(customParams)
    }
    return makeRemoteCustomer
  }

  const loadData = async (): Promise<void> => {
    setLoading(true)

    const data = await getRequestBytypeUser(user?.roles[0]?.authority, {
      size: settings.perPage,
      page: params?.page,
      query: params?.query,
      filters: generateFilter({
        ...params?.filters,
        ...(!checkIsCustomer()
           && user?.roles[0]?.authority !== Roles.ADMIN
          && { headOfficeServiceProvider: profile?.branch?.uuid || user?.uuid }),
        ...(checkIsCustomer()
           && { headOfficeCustomer: user?.uuid }),
      }),
    })
    setList(data)
    setLoading(false)
  }

  const exportList = (): void => {
    if (isAdmin()) {
      makeRemoteAdmin().exportCSVServiceProvider({
        filters: generateFilter({
          ...params?.filters,
          ...(profile?.branch?.uuid && { serviceProvider: profile?.branch?.uuid }),
        }),
      })
    } else if (checkIsCustomer()) {
      makeRemoteCustomer().exportCSV({
        filters: generateFilter({
          ...params?.filters,
          ...(profile?.branch?.uuid && { serviceProvider: profile?.branch?.uuid }),
        }),
      })
    } else {
      makeRemoteServiceProvider().exportCSV({
        filters: generateFilter({
          ...params?.filters,
          ...(profile?.branch?.uuid && { serviceProvider: profile?.branch?.uuid }),
        }),
      })
    }
  }

  useEffect(() => {
    if (user) {
      loadData()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params, profile])

  return (
    <ServiceProviderListPage
      params={params}
      list={list}
      setParams={setParams}
      loading={loading}
      exportList={exportList}
    />
  )
}

export default MakeServiceProviderList
