import { InvalidCredentialsError } from '../../../domain/errors/invalid-credentials-error'
import { UnexpectedError } from '../../../domain/errors/unexpected-error'
import { FilterModel } from '../../../domain/models/enum'
import { Product, ProductModel, ProductReponseModel } from '../../../domain/models/product'
import { HttpClient, HttpStatusCode } from '../../protocols/http/http-client'

export class RemoteProduct implements Product {
  constructor(
    private readonly url: string,
    private readonly httpPostClient: HttpClient,
  ) { }

  async loadProductById(id: number): Promise<ProductModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'get',
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async load(params?: FilterModel): Promise<ProductReponseModel> {
    const httpResponse = await this.httpPostClient.request({
      url: this.url,
      method: 'get',
      params,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async update(id: number, body: ProductModel): Promise<ProductModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}/${id}`,
      method: 'put',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.ok: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }

  async create(body: ProductModel): Promise<ProductModel> {
    const httpResponse = await this.httpPostClient.request({
      url: `${this.url}`,
      method: 'post',
      body,
    })

    switch (httpResponse.statusCode) {
      case HttpStatusCode.created: return httpResponse.body
      case HttpStatusCode.unauthorized: throw new InvalidCredentialsError()
      default: throw new UnexpectedError()
    }
  }
}
