/* eslint-disable react/no-children-prop */
import React, { useState } from 'react'
import { FileUploader } from 'react-drag-drop-files'
import { useAlertBox } from '../../../../contexts/alert-box/alert_box'
import { DocRequestProp } from '../../../../domain/models/documents'
import { makeRemoteDocuments } from '../../../../main/factories/usecases/documents/remote-documents-factory'
import Icons from '../../../components/icons-helper'
import { checkIsCustomer } from '../../../helpers/utils'
import { theme } from '../../../styles/theme'

import {
  DocumentsItem, DownloadButtonStyled, TextStyled, UploadButtonStyled,
} from './styled'

type ComponentProps ={
  doc: DocRequestProp,
  documentRequest: number | undefined
  refetch: ()=> void
}

export const DocumentItem = ({ doc, documentRequest, refetch }:ComponentProps):JSX.Element => {
  const [loading, setLoading] = useState<any>()
  const { handleAlert } = useAlertBox()
  const handleChange = async (e: any): Promise<void> => {
    if (doc?.id) {
      try {
        setLoading(true)
        const reader = new FileReader()
        reader.readAsDataURL(e)
        reader.onload = async () => {
          await makeRemoteDocuments().updateDocument(doc.id, {
            document: `${reader.result}`,
            documentRequest: {
              id: documentRequest,
            },
            documentType: doc.documentType,
          })
          refetch()
          setLoading(false)
        }
        reader.onerror = (error) => {
          console.error('Error: ', error)
          setLoading(false)
          handleAlert({
            show: true,
            type: 'error',
            message: 'Erro ao Carregar Arquivo',
          })
        }
      } catch (error) {
        console.error(error)
        setLoading(false)
        handleAlert({
          show: true,
          type: 'error',
          message: 'Erro ao Carregar Arquivo',
        })
      }
    }
  }

  return (
    <DocumentsItem>
      <TextStyled style={{ maxWidth: '30%' }}>
        <Icons
          name="notes"
          width={20}
          height={20}
          color={theme.colors.black}
        />
        {doc?.documentType}
      </TextStyled>
      {doc?.documentUrl && (
      <DownloadButtonStyled download={doc?.documentType} target="_blank" href={doc.documentUrl}>
        <Icons
          name="download"
          width={20}
          height={20}
          color={theme.colors.primary}
        />
      </DownloadButtonStyled>
      )}
      {!checkIsCustomer()
                      && (
                      <div className="w-60">

                        <FileUploader
                          multiple={false}
                          handleChange={handleChange}
                          name="file"
                          types={['PDF']}
                          children={(
                            <UploadButtonStyled>
                              <Icons
                                name="upload"
                                width={20}
                                height={20}
                                color={theme.colors.primary}
                              />
                              {!loading && <span>{!doc.documentUrl ? 'Solte o Arquivo aqui' : 'Substituir Arquivo'}</span>}
                              {loading && <span>Carregando...</span>}
                            </UploadButtonStyled>
        )}
                        />
                      </div>
                      )}

    </DocumentsItem>
  )
}
