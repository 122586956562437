import React from 'react'
import { Col } from 'react-bootstrap'
import { useAuth } from '../../../contexts/auth-context/auth-context'
import { ServiceProviderProps } from '../../../domain/models/service-provider'
import FormFactory from '../../components/form-factory'
import {
  CardForm,
  SubTitle,
  Title,
} from './styles'

type PageProps = {
  branchs: ServiceProviderProps[]
  handleSubmit: (values: any, actions: any) => void
}

const SelectBranch = ({ branchs, handleSubmit }: PageProps):JSX.Element => {
  const { user } = useAuth()

  const stepTwo = [
    {
      groupName: 'Login',
      items: [
        {
          name: 'branch',
          type: 'select',
          placeholder: 'Selecione um opção',
          validations: 'objectRequired',
          options: branchs,
          labelKey: 'name',
          valueKey: 'uuid',
        },
      ],
    },
  ]

  return (

    <Col xs={12} className="p-0 align-items-center d-flex">
      <CardForm>
        {user?.name && (
        <SubTitle>
          Bem vindo,
          {` ${user?.name}`}
        </SubTitle>
        )}
        <Title>
          Selecione uma filial para avançar!
        </Title>
        <FormFactory groups={stepTwo} onSubmit={handleSubmit} button={{ submit: 'Prosseguir', submitting: 'Prosseguindo...' }} />
      </CardForm>
    </Col>

  )
}
export default SelectBranch
