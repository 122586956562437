/* eslint-disable no-mixed-operators */
/* eslint-disable radix */
import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { theme } from '../../../styles/theme'

const adjust = (color: any, amount: any): any => `#${color.replace(/^#/, '').replace(/../g, (col: any) => (`0${Math.min(255, Math.max(0, parseInt(col, 16) + amount)).toString(16)}`).substr(-2))}`

export const Card = styled.div`
  box-shadow:  0px 7px 50px rgba(210, 214, 227, 0.4);
  background-color: ${theme.colors.white};
  border-radius: 24px;
  width: 100%;
  font-family: ${theme.font.poppins};
  height: 100%;
`

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 15px 20px;
`

export const Title = styled.h2`
  color: ${theme.colors.black};
  font-family: ${theme.font.palanquin};
  font-weight: 600;
  font-size: .9rem;
  margin-top: 12px;
  margin-bottom: 5px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  svg{
    margin-right: 5px;
  }
`
type ProgressProps = {
  percentage: number
}
type ItemProps = {
  index: number
}

export const ProgressBar = styled.div`
  border: 1px solid ${theme.colors.middleGray};
  width: 110px;
  height: 15px;
  border-radius: 8px;
  position: relative;
  overflow: hidden;
  margin-right: 10px;
  &:before {
    content: '';
    width: ${(p: ProgressProps) => p.percentage}%;
    position: absolute;
    background-color: ${theme.colors.primary};
    height: 13px;
    left: 0;
    border-radius: 8px;
    top: 0;
  }
`
export const QuantityStyled = styled.div`
  color: ${theme.colors.black};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: .8rem;
  margin-bottom: 0px;
  max-width: 30%;
  width: 100%;
`
export const Name = styled.div`
  color: ${theme.colors.black};
  font-family: ${theme.font.poppins};
  font-weight: 400;
  font-size: .8rem;
  margin-bottom: 0px;
  width: 100%;
  margin-right: 10px;
`
export const ListStyled = styled.div`
  max-height: 170px;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 5px;
    border-radius: 5px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    border-radius: 5px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #888;
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    border-radius: 5px;
  }
`
export const Info = styled.div`
  display: flex;
  max-width: 35%;
  width: 100%;
  justify-content: flex-end;
  align-items: center;
  color: ${theme.colors.darkGray};
  font-family: ${theme.font.poppins};
`
type AvatarProps = {
  color: string
}

export const Avatar = styled.div`
  width: 35px;
  height: 35px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  font-family: ${theme.font.palanquin};
  align-items: center;
  background-color: ${(p: AvatarProps) => p.color};
  color: ${(p: AvatarProps) => adjust(p.color, -110)};
  margin-right: 10px;
  font-weight: 700;
`
export const BoxOperator = styled(Link)`
  display: grid;
  grid-template-columns: auto auto auto auto;
  padding: 12px 15px;
  align-items: center;
  font-family: ${theme.font.poppins};
  font-weight: 300;
  font-size: 12px;
  line-height: 1.6;
  margin-top: 15px;
  justify-content: space-between;
    svg {
    margin-top: 2px;
  }
  background-color: ${(p: ItemProps) => (p.index % 2 === 0 ? '#efefef7a' : '#EFEFEF;')};
  border-radius: 16px;

`
